import ColumnsDropdown from "components/CustomDropdowns/ColumnsDropdown";
import { ReactComponent as VerticalMore } from "assets/images/verticalmore.svg";

import Dropdown, { POSITION } from "components/Dropdown";
import { useState } from "react";

interface Props {
  table: any;
  name: string;
}

const ChooseColumns = ({ table, name }: Props) => {
  const [closeDropdown, setCloseDropdown] = useState(false);

  return (
    <Dropdown
      name="selectColumns"
      position={POSITION.BOTTOM_LEFT}
      overlay={<ColumnsDropdown table={table} setCloseDropdown={setCloseDropdown} name={name} />}
      closeDropdown={closeDropdown}
      setCloseDropdown={setCloseDropdown}
      className={"!bg-border-internal flex justify-center items-center !w-full"}
      plusIcon
      custom
    >
      <button title="choose columns" className="h-full w-full">
        <VerticalMore className="w-4 h-4" />
      </button>
    </Dropdown>
  );
};

export default ChooseColumns;
