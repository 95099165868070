import React, { useState } from "react";
import Dropdown, { POSITION } from "components/Dropdown";
import { OrganizeByOptions, FrequencyOptionKeys, IDateOption, AsyncStatus } from "types";
import { DATE_FREQUENCY, ORGANIZE_BY_DEMAND, DATE_OPTIONS_FORECAST } from "utils/constants";
import DateFrequencyDropdown from "components/CustomDropdowns/DateFrequencyDropdown";
import DateRangeDropdown from "components/DropdownDateRange";
import { saveState } from "utils/helper-ts";

export const TableOptions = ({
  frequency,
  handleChangeFrequency,
  dateRange,
  setDateRange,
  openDateRangeDropdown,
  loading,
  guardRail,
  allowFuture,
  defaultFrequency,
  dateRangeOptions,
}: {
  frequency: FrequencyOptionKeys;
  handleChangeFrequency: (frequency: FrequencyOptionKeys) => void;
  dateRange: IDateOption;
  setDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;
  openDateRangeDropdown: boolean;
  loading: AsyncStatus;
  guardRail?: boolean;
  allowFuture?: boolean;
  defaultFrequency?: FrequencyOptionKeys;
  dateRangeOptions?: IDateOption[];
}) => {
  return (
    <div className="flex gap-3 justify-center items-center">
      <p>Time Range:</p>
      <DateRangeDropdown
        options={dateRangeOptions || DATE_OPTIONS_FORECAST}
        option={dateRange}
        onSelect={(selection: IDateOption) => {
          setDateRange(selection as IDateOption);
          saveState("top_perf_page_time", selection);
        }}
        allowFuture={allowFuture}
        frequency={guardRail ? frequency : undefined}
        openDropdown={openDateRangeDropdown}
      />
      <p>Frequency:</p>
      <Frequency
        defaultOption={defaultFrequency || "month"}
        frequency={frequency}
        setFrequency={handleChangeFrequency}
        loading={loading}
      />
    </div>
  );
};

export const OrganizeByDropdown = ({
  organizeBy,
  handleChange,
}: {
  organizeBy: OrganizeByOptions;
  handleChange: (organizeBy: OrganizeByOptions) => void;
}) => {
  const handleOrganizeByChange = (key: string, value: Object) => {
    handleChange(value as OrganizeByOptions);
  };

  return (
    <Dropdown
      name="organize-by"
      position={POSITION.BOTTOM_LEFT}
      showArrow
      overlay={ORGANIZE_BY_DEMAND}
      onChange={handleOrganizeByChange}
    >
      <span className="pl-2.5 cursor-pointer">{organizeBy.value}</span>
    </Dropdown>
  );
};

const Frequency = ({
  frequency,
  setFrequency,
  loading,
  defaultOption,
}: {
  frequency: FrequencyOptionKeys;
  setFrequency: (frequency: FrequencyOptionKeys) => void;
  loading: AsyncStatus;
  defaultOption: FrequencyOptionKeys;
}) => {
  const [closeDropdown, setCloseDropdown] = useState(false);

  return (
    <Dropdown
      name="time-range"
      position={POSITION.BOTTOM_LEFT}
      showArrow
      closeDropdown={closeDropdown}
      setCloseDropdown={setCloseDropdown}
      overlay={
        <DateFrequencyDropdown
          setFrequency={setFrequency}
          setCloseDropdown={setCloseDropdown}
          loading={loading}
          defaultOption={defaultOption}
        />
      }
      size="dynamic"
      style={{ width: "auto" }}
    >
      <div className="px-3 cursor-pointer flex gap-3">
        {DATE_FREQUENCY.find((option) => option.key === frequency)?.value}
      </div>
    </Dropdown>
  );
};
