import React, { useState, useEffect, useContext } from "react";
import { AsyncStatus, OrganizeBy, IObject, ITimeRange } from "types";
import { ReactComponent as Search } from "assets/images/search.svg";
import { Resizable } from "re-resizable";
import Modal from "../BasicModal/index";
import { ModalLeftButton, ModalRightButton } from "components/Button";
import DemandTimeseries from "components/DemandTimeseries";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import {
  DATE_OPTIONS,
  DATE_OPTIONS_FORECAST,
  OVER_OPTIONS,
  DASHBOARD_TOP_CRITERIA_OPTIONS,
  TIME_SERIES_OPTIONS,
  DATE_FREQUENCY_TIME_SERIES,
} from "utils/constants";
import DateRangeDropdown from "components/DropdownDateRange";
import { InfoModalContext, ModalContext } from "context";
import { IDateOption } from "types";
import { ChartBackground } from "components/ChartWrapper";
import client from "api";
import { Dropdown } from "../../Dashboard/Dropdown";
import { MetricColumn } from "../../Dashboard/MetricHelpers";
import { formatNumber } from "utils/helper-ts";
import { IOption, ITopPerformers, ITopPerfCriteria } from "types";
import PerformanceChart from "components/PerformanceBarChart";
import { Card } from "components/Dashboard/Card";
import { UnitLoadingWrapper } from "components/Loading";
import { LinkLookalike } from "components/Link/index";

// crap from old inventory chart
import { IThreshold, IDays } from "types";
import { TVariant, IVariantDetail } from "types/ModalTypes";
import InventoryChart from "components/Inventory/InventoryChart";
import InventoryTimeseries from "components/InventoryTimeseries";

export const DetailModalWrapper = ({
  title,
  visible,
  organizeBy,
  resetId,
  handleGoToParent,
  handleGoToChild,
  numberOfChildren,
  loading,
  close,
  children,
}: {
  title: React.ReactNode;
  visible: boolean;
  organizeBy: OrganizeBy;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
  handleGoToParent?: () => void;
  handleGoToChild?: (id?: string) => void;
  numberOfChildren?: number;
  loading: AsyncStatus;
  close: () => void;
  children: React.ReactNode;
}) => {
  const organizationText = lookupLevel(organizeBy);
  return (
    <Modal
      showSideBar={false}
      title={title}
      visible={visible}
      onCancel={() => {
        resetId?.("");
        close();
      }}
      size={"full"}
      icon={
        handleGoToParent ? (
          <ModalLeftButton
            onClick={handleGoToParent}
            label={`Parent ${organizationText.parentText}`}
            disabled={false}
          />
        ) : null
      }
      rightIcon={
        handleGoToChild ? (
          <ModalRightButton
            label={`Explore ${numberOfChildren} ${organizationText.childText} `}
            onClick={handleGoToChild}
            disabled={false}
          />
        ) : null
      }
    >
      <div className="flex w-full">{children}</div>
    </Modal>
  );
};

function lookupLevel(organizationLevel: OrganizeBy) {
  switch (organizationLevel) {
    case "category":
      return {
        parent: "shop",
        parentText: "Shop",
        child: "product",
        childText: "Product(s)",
      };
    case "vendor":
      return {
        parent: "shop",
        parentText: "Shop",
        child: "product",
        childText: "Product(s)",
      };
    case "product":
      return {
        parent: "category",
        parentText: "Category",
        child: "variant",
        childText: "Variant(s)",
      };
    case "variant":
      return {
        parent: "product",
        parentText: "Product",
        child: "variant",
        childText: "Variant(s)",
      };
    default:
      return {
        parent: "product",
        parentText: "Product",
        child: "variant",
        childText: "Variant(s)",
      };
  }
}

export const DetailModalContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <section id="right" className="flex-1 h-full overflow-scroll">
      {children}
    </section>
  );
};

export const DetailModalSidebar = ({
  itemCount,
  children,
  keyword,
  type,
  setKeyword,
}: {
  itemCount: number;
  children: React.ReactNode;
  keyword: string;
  type: OrganizeBy;
  setKeyword: (value: string) => void;
}) => {
  const [expanded, setExpanded] = useState(true);
  const handleToggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Resizable
      defaultSize={{
        width: 200,
        height: "100%",
      }}
      maxWidth={500}
      minWidth={100}
      style={{
        position: "sticky",
        top: 0,
      }}
      handleClasses={{
        right:
          "hover:border-l-pink hover:bg-pink/10 active:bg-pink/10 active:border-l-pink border-transparent border-l-2 transition-all",
      }}
    >
      <section
        id="left"
        className={`flex h-[calc(100vh-120px)] py-3 bg-highlight-gentle w-full border-r`}
      >
        <div className={`w-full flex flex-col gap-3 transition-all`}>
          <p className="px-2 text-xs text-base-text/50 w-full text-center">
            Explore {itemCount} Siblings
          </p>
          <SideBarSearch
            expanded={expanded}
            keyword={keyword}
            type={type}
            setKeyword={setKeyword}
          />
          {children}
        </div>
      </section>
    </Resizable>
  );
};

const SideBarSearch = ({
  keyword,
  setKeyword,
  expanded,
  type,
}: {
  keyword: string;
  setKeyword: (value: string) => void;
  expanded: boolean;
  type: OrganizeBy;
}) => {
  return (
    <div className={`${expanded ? "mx-3" : "mx-0"} relative transition-all`}>
      <Search
        className={`absolute ${
          expanded ? "w-10" : "w-0"
        } h-10 m-[2.5px] color-base-text top-0 bottom-0 mt-auto mb-auto`}
      />
      <input
        type="text"
        className={`${
          expanded ? "w-full pl-12 pr-4" : "w-0 pl-0 pr-0"
        } text-sm bg-black/5 rounded-3xl border-none bg-transparent trasition-all placeholder:italic placeholder:text-black/60 focus:outline-none focus:ring-base-text`}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={`${type} name`}
      />
    </div>
  );
};

// defunct, I believe
const ToggleSidebarView = ({
  handleToggleExpanded,
  expanded,
  numberOfItems,
}: {
  handleToggleExpanded: () => void;
  expanded: boolean;
  numberOfItems: number;
}) => {
  return (
    <p
      className="bg-red-300 rounded-md p-2 font-mono text-base-text text-xs font-base-text uppercase text-right select-none origin-center cursor-pointer absolute right-[-60px] top-1 z-[1001]"
      // style={{ writingMode: "vertical-lr" }}
      onClick={handleToggleExpanded}
    >
      {expanded ? "hide" : "show"} {numberOfItems} siblings
    </p>
  );
};

// defunct, I believe
const ToggleSidebarView2 = ({
  handleToggleExpanded,
  expanded,
  numberOfItems,
}: {
  handleToggleExpanded: () => void;
  expanded: boolean;
  numberOfItems: number;
}) => {
  return (
    <p
      className="px-6 font-mono text-base-text/40 text-xs font-black uppercase text-right select-none origin-center rotate-180 cursor-pointer"
      style={{ writingMode: "vertical-lr" }}
      onClick={handleToggleExpanded}
    >
      {expanded ? "hide" : "show"} {numberOfItems} siblings
    </p>
  );
};

export const DetailModalDemandTimeseries = ({
  id,
  organizeBy,
}: {
  id: string;
  organizeBy: OrganizeBy;
}) => {
  const {
    timeSeriesTime,
    updatTimeSeriesTime,
    timeSeriesFrequency,
    updateTimeSeriesFrequency,
    timeSeriesType,
    updateTimeSeriesType,
  } = useContext(InfoModalContext);

  return (
    <>
      <AreaTopBar areaName="Demand Comparison and Forecast">
        <DateRangeDropdown
          options={DATE_OPTIONS_FORECAST}
          option={timeSeriesTime}
          onSelect={(selection: IDateOption) => {
            updatTimeSeriesTime(selection as IDateOption);
          }}
          allowFuture
        />
        <Dropdown
          options={TIME_SERIES_OPTIONS}
          option={timeSeriesType}
          onSelect={(item: IOption) => {
            updateTimeSeriesType(item);
          }}
        />
        <Dropdown
          options={DATE_FREQUENCY_TIME_SERIES}
          option={timeSeriesFrequency}
          onSelect={(item: IOption) => {
            updateTimeSeriesFrequency(item);
          }}
        />
      </AreaTopBar>
      <ChartBackground height={400}>
        <DemandTimeseries
          id={id}
          type={organizeBy}
          context={InfoModalContext}
          horizontalLegend={true}
        />
      </ChartBackground>
    </>
  );
};

export const VariantInventory = ({
  handleChangeDropdown,
  inventoryDays,
  variantData,
  variantId,
  threshold,
  id,
  organizeBy,
}: {
  handleChangeDropdown: (key: string, value: IObject) => void;
  inventoryDays: IDays;
  variantData: IVariantDetail;
  variantId: string;
  threshold: IThreshold;
  id: string;
  organizeBy: OrganizeBy;
}) => {
  const { inventoryTimeSeriesTime, updateInventoryTimeSeriesTime } = useContext(InfoModalContext);

  return (
    <>
      <AreaTopBar areaName="Historical Inventory and Demand">
        <DateRangeDropdown
          options={DATE_OPTIONS_FORECAST}
          option={inventoryTimeSeriesTime}
          onSelect={(selection: IDateOption) => {
            updateInventoryTimeSeriesTime(selection as IDateOption);
          }}
          allowFuture={true}
        />
      </AreaTopBar>
      <ChartBackground height={400}>
        <InventoryTimeseries
          variantId={variantId}
          productId={variantData.pid}
          type={organizeBy}
          context={InfoModalContext}
          horizontalLegend={true}
        />
      </ChartBackground>
    </>
  );
};

export interface IData {
  grossRevenueRank: string;
  periodToPeriodGrowth: string;
  shareOfTotalSales: string;
  unitsSold: string;
}

export const DetailModalPerformanceKPI = ({ id, type }: { id: string; type: string }) => {
  const { onlyPastDateRange } = useContext(ModalContext);
  const [KPITime, setKPITime] = useState(onlyPastDateRange);
  const [data, setData] = useState<IData>({
    grossRevenueRank: "",
    periodToPeriodGrowth: "",
    shareOfTotalSales: "",
    unitsSold: "",
  });

  const [loading, setLoading] = useState(AsyncStatus.Loading);

  useEffect(() => {
    async function fetchKPI(time: string) {
      try {
        setLoading(AsyncStatus.Loading);
        let propertyName;

        switch (type) {
          case "product":
            propertyName = "productId";
            break;
          case "category":
            propertyName = "categoryTitle";
            break;
          case "vendor":
            propertyName = "vendor";
            break;
          default:
            propertyName = "variantId";
        }

        const url = `/get-${type}-modal-performance-kpi`;
        const params = {
          [propertyName]: id,
          time,
        };
        const res = await client.get(url, { params: params });
        setLoading(AsyncStatus.Loaded);
        setData(res.data.data);
      } catch (error) {
        console.error(error);
        setLoading(AsyncStatus.Failed);
      }
    }
    if (id) {
      fetchKPI(KPITime.key);
    }
  }, [id, KPITime.key, setData]);

  return (
    <div>
      <AreaTopBar areaName="Performance">
        <DateRangeDropdown
          options={DATE_OPTIONS.slice(0, -1)}
          option={KPITime}
          onSelect={(selection: IDateOption) => {
            setKPITime(selection as IDateOption);
          }}
          allowPast
        />
      </AreaTopBar>
      <MetricColumn>
        <Card
          name={"Revenue Contribution"}
          value={data.shareOfTotalSales === "-" ? "-" : data.shareOfTotalSales + "%"}
          loading={loading}
        />
        <Card
          name="Gross Revenue Rank"
          value={formatNumber(parseFloat(data.grossRevenueRank))}
          loading={loading}
        />
        <Card
          name={"Units Sold in Period"}
          value={data.unitsSold === "-" ? "0" : formatNumber(parseInt(data.unitsSold))}
          loading={loading}
        />
        <Card
          name={"Period/Period Growth"}
          value={
            data.periodToPeriodGrowth === "-" ||
            data.periodToPeriodGrowth === "Infinity" ||
            data.periodToPeriodGrowth === "NaN"
              ? "-"
              : data.periodToPeriodGrowth.startsWith("-")
              ? "-" + data.periodToPeriodGrowth.slice(-1) + "%"
              : data.periodToPeriodGrowth + "%"
          }
          loading={loading}
        />
      </MetricColumn>
    </div>
  );
};

export interface VariantQuery {
  variantId: string;
}
interface ProductQuery {
  productId: string;
}
interface CategoryQuery {
  categoryTitle: string;
}
interface VendorQuery {
  vendor: string;
}

type DemandChartQuery = VariantQuery | ProductQuery | CategoryQuery | VendorQuery;

interface DemandChartQuerySelector {
  [key: string]: DemandChartQuery;
}

export const DetailModalChildPerformanceChart = ({
  id,
  title,
  parentType,
  organizeBy,
  childType,
  goToChild,
}: {
  id: string;
  title: string;
  parentType: OrganizeBy;
  organizeBy: OrganizeBy;
  childType: OrganizeBy;
  goToChild?: (id: string) => void;
}) => {
  const [topPerformers, setTopPerformers] = useState<ITopPerformers[]>([]);

  const { topPerfTime, updateTopPerfTime, topPerfCriteria, updateTopPerfCriteria } =
    useContext(InfoModalContext);
  const [loading, setLoading] = useState(AsyncStatus.Loading);

  useEffect(() => {
    const routesByType: { [key: string]: string } = {
      variant: "/get-variant-modal-demand-performance",
      product: "/get-product-modal-demand-performance",
      category: "/get-category-modal-demand-performance",
      vendor: "/get-vendor-modal-demand-performance",
    };

    const queryByType: DemandChartQuerySelector = {
      variant: { variantId: id }, // this is varaintId
      product: { productId: id }, // this is productId
      category: { categoryTitle: id }, // this is categoryTitle
      vendor: { vendor: id }, // this is vendor
    };

    const selectQueryByType = (type: string, id: string): DemandChartQuery => {
      return queryByType[type];
    };

    async function fetchTopPerformers(type: string, time: string, criteria: string) {
      try {
        setLoading(AsyncStatus.Loading);
        const url = routesByType[type];
        const param = selectQueryByType(type, id);

        const res = await client.get(url, {
          params: {
            ...param,
            time,
            criteria,
          },
        });

        setLoading(AsyncStatus.Loaded);
        setTopPerformers(res.data.data);
      } catch (err: any) {
        console.error(err);
      }
    }
    if (id && topPerfTime.key && topPerfCriteria.key) {
      fetchTopPerformers(parentType, topPerfTime.key, topPerfCriteria.key);
    }
  }, [topPerfTime.key, topPerfCriteria.key, setTopPerformers, id]);

  return (
    <>
      <AreaTopBar areaName={`Demand by ${childType}`}>
        <Dropdown
          options={DASHBOARD_TOP_CRITERIA_OPTIONS}
          option={topPerfCriteria}
          onSelect={(item: IOption) => {
            updateTopPerfCriteria(item as ITopPerfCriteria);
          }}
        />
        <DateRangeDropdown
          options={DATE_OPTIONS.slice(0, -1)}
          option={topPerfTime}
          onSelect={(selection: IDateOption) => {
            updateTopPerfTime(selection as IDateOption);
          }}
          allowPast
        />
      </AreaTopBar>
      <PerformanceChart
        loading={loading}
        data={topPerformers}
        criteria={topPerfCriteria}
        organizeBy={organizeBy}
        option={{ key: childType, value: "By Product" }} // same as the dashboard
        barColor={"#C7E3B1"}
        isVertical={true}
        dynamicHeight={true}
        type={"demandChart"}
        goToItem={goToChild}
      />
    </>
  );
};

import type {
  ItemPillListDisplayProps,
  IModalPillLabelProps,
  IModalToggleItemsButtonProps,
} from "types/ModalTypes";

const PillLabel: React.FC<IModalPillLabelProps> = ({ pillLabel }) => {
  return <div className="inline-block bg-base-gentle rounded-md px-2.5 py-1 m-1">{pillLabel}</div>;
};

const ToggleItemsButton: React.FC<IModalToggleItemsButtonProps> = ({
  expandItems,
  onClick,
  itemsLength,
}) => {
  const getButtonLabel = () => {
    if (expandItems) {
      return "Show Less";
    }

    if (itemsLength - 1 > 0) {
      return `Show ${itemsLength - 1} More`;
    }

    return "";
  };

  return <LinkLookalike onClick={onClick}>{getButtonLabel()}</LinkLookalike>;
};

export const DetailModalItemPillListDisplay: React.FC<ItemPillListDisplayProps> = ({
  items,
  label,
  loading,
  startExpanded,
}) => {
  const [expandItemPillList, setExpandItemPillList] = React.useState(startExpanded || false);

  const toggleItemPillList = () => {
    setExpandItemPillList(!expandItemPillList);
  };

  return (
    <>
      <div className="text-sm">
        <UnitLoadingWrapper loading={loading}>
          {expandItemPillList ? (
            items.map((item: string) => <PillLabel key={item} pillLabel={item} />)
          ) : (
            <PillLabel pillLabel={items[0]} />
          )}
          {items.length > 1 && (
            <ToggleItemsButton
              expandItems={expandItemPillList}
              onClick={toggleItemPillList}
              itemsLength={items.length}
            />
          )}
        </UnitLoadingWrapper>
      </div>
    </>
  );
};
