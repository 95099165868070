import { DEFAULT_OPTIONS } from "pages/top-performers";
import React, { useContext, useEffect, useState } from "react";
import { FrequencyOptionKeys, IDateOption, OrganizeByOptions } from "types";
import { DATE_OPTIONS } from "utils/constants";
import { StatesContext } from "./States";
import { processAndSet, processAndSetTime, processTime } from "utils/helper-ts";

interface ITopPerformersContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  organizeBy: OrganizeByOptions;
  updateOrganizeBy: React.Dispatch<React.SetStateAction<OrganizeByOptions>>;
  dateRange: IDateOption;
  updateDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;

  frequency: FrequencyOptionKeys;
  updateFrequency: React.Dispatch<React.SetStateAction<FrequencyOptionKeys>>;
  showChart: boolean;
  updateShowChart: React.Dispatch<React.SetStateAction<boolean>>;
  showOther: boolean;
  updateShowOther: React.Dispatch<React.SetStateAction<boolean>>;
  metric: "revenue" | "shareOfRevenue";
  updateMetric: React.Dispatch<React.SetStateAction<"revenue" | "shareOfRevenue">>;
}

export const TopPerformersContext = React.createContext<ITopPerformersContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  organizeBy: { key: "product", value: "Product" },
  updateOrganizeBy: () => {},
  dateRange: DATE_OPTIONS[3],
  updateDateRange: () => {},
  frequency: "month",
  updateFrequency: () => {},
  showChart: true,
  updateShowChart: () => {},
  showOther: true,
  updateShowOther: () => {},
  metric: "shareOfRevenue",
  updateMetric: () => {},
});

interface ITopPerformersContextProps {
  children: React.ReactNode;
}

export const TopPerformersProvider = (props: ITopPerformersContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);

  const [organizeBy, updateOrganizeBy] = useState<OrganizeByOptions>({
    key: "product",
    value: "Product",
  });
  const [dateRange, setDateRange] = useState(DATE_OPTIONS[3]);
  const [frequency, setFrequency] = useState<FrequencyOptionKeys>(DEFAULT_OPTIONS.frequency);
  const [showChart, setShowChart] = useState(true);
  const [showOther, setShowOther] = useState(true);
  const [metric, setMetric] = useState<"revenue" | "shareOfRevenue">("shareOfRevenue");

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        processAndSetTime(parsed, "top_perf_page_time", setDateRange, DATE_OPTIONS);
        processAndSet(parsed, "top_perf_page_freq", setFrequency);
        processAndSet(parsed, "top_perf_page_chart", setShowChart, true);
        processAndSet(parsed, "top_perf_page_other", setShowOther, true);
        processAndSet(parsed, "top_perf_page_type", setMetric);
        processAndSet(parsed, "top_perf_page_org", updateOrganizeBy);
      }
      setStatesFetched(true);
    }
  }, [rawState]);

  return (
    <TopPerformersContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        organizeBy,
        updateOrganizeBy,
        dateRange,
        updateDateRange: setDateRange,
        frequency,
        updateFrequency: setFrequency,
        showChart,
        updateShowChart: setShowChart,
        showOther,
        updateShowOther: setShowOther,
        metric,
        updateMetric: setMetric,
      }}
    >
      {props.children}
    </TopPerformersContext.Provider>
  );
};
