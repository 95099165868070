import { ApplyButton } from "components/Button";
import { useState, ChangeEvent } from "react";
import { FrequencyOptionKeys, IFilterPlus, IObject } from "types";

interface Props {
  options: IObject[];
  defaultOption: string;
  onApply: (key?: string) => void;
  disableApply: boolean;
  type: string;
  updatePageFilter?: (items: any) => void;
  setFrequency?: (frequency: FrequencyOptionKeys) => void;
}

const FilterName: { [key: string]: string } = {
  sale: "Sales: ",
  noSale: "No Sales: ",
  hasImage: "",
  hasDescription: "",
};

export const RadioDropdown = ({
  options,
  defaultOption,
  onApply,
  disableApply,
  type,
  updatePageFilter,
  setFrequency,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption);

  function findKeyByValue(valueToFind: string): string {
    for (const item of options) {
      if (item.value === valueToFind) {
        return item.key;
      }
    }
    return "";
  }

  const handleApply = () => {
    //it means its used in filters
    if (updatePageFilter) {
      updatePageFilter((old: IFilterPlus[]) => {
        const updatedFilters = old.map((item: IFilterPlus) =>
          item.key === type
            ? {
                key: type,
                value:
                  type === "sale" || type === "noSale"
                    ? [findKeyByValue(selectedOption)]
                    : (selectedOption === defaultOption).toString(),
                name: FilterName[type] + selectedOption,
              }
            : item
        );
        return updatedFilters;
      });

      onApply?.();
    }
    //for demand freq
    else {
      onApply?.(findKeyByValue(selectedOption));
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setSelectedOption(name);
  };

  return (
    <div className="font-medium max-h-96 overflow-y-auto">
      <ul>
        {options.map((option, index) => (
          <div className="flex items-center p-2.5" key={index}>
            <input
              id={`${option.value}`}
              type="radio"
              name={`${option.value}`}
              onChange={handleChange}
              checked={selectedOption === option.value}
              className="border border-solid border-border-external rounded-full w-3 h-3 outline-none appearance-none focus:ring-0 focus:ring-transparent focus:ring-offset-transparent checked:border checked:border-solid checked:border-border-external checked:bg-dot checked:hover:border checked:hover:border-solid checked:hover:border-border-external checked:focus:border checked:focus:border-solid checked:focus:border-border-external"
            />
            <label htmlFor={`${option.value}`} className="pl-2.5 font-medium">
              {option.value}
            </label>
          </div>
        ))}
      </ul>
      <ApplyButton onClick={handleApply} disabled={disableApply} />
    </div>
  );
};

export default RadioDropdown;
