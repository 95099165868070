import { useContext, useEffect, useState } from "react";
import { handleScrollTo } from "utils/helper-ts";
import { DemandContext } from "context";
import Button from "components/Button";

const TableScrollTop = () => {
  const { top } = useContext(DemandContext);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      },
      { threshold: 0 }
    );

    if (top.current) {
      observer.observe(top.current);
    }

    return () => {
      if (top.current) {
        observer.unobserve(top.current);
      }
    };
  }, [top]);

  return isVisible ? (
    <Button
      label={"Scroll to Top Left"}
      onClick={() => {
        handleScrollTo(top);
      }}
      buttonType={"primary"}
    />
  ) : null;
};

export default TableScrollTop;
