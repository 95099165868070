import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { errorHandler } from "utils/helper-ts";

const client = axios.create({
  timeout: 30000,
  headers: {},
});

const requestInterceptor = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  const shop = localStorage.getItem("shop");
  const shopId = localStorage.getItem("shopId");

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  if (shop && shopId) {
    config.headers["Shop"] = shop;
    config.headers["ShopId"] = shopId;
  }

  return config;
};

const resInterceptor = (response: any) => {
  return response;
};

const errInterceptor = (error: AxiosError) => {
  errorHandler(error);

  return Promise.reject(error);
};

client.interceptors.request.use(requestInterceptor);
client.interceptors.response.use(resInterceptor, errInterceptor);

export default client;
