import React, { useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Line,
  Legend,
  Tooltip,
} from "recharts";
import { HEX_COLORS, TW_COLORS } from "utils/chart";
import client from "api";
import {
  BaseTooltip,
  TooltipSubItem,
  TooltipSubHeader,
  TooltipItem,
} from "components/ChartTooltip";
import { formatCurrency, formatNumber, errorHandler } from "utils/helper-ts";
import { AppStateContext, DashboardContext, InfoModalContext } from "context";
import { ChartLegend, LegendItem } from "components/ChartLegend";
import { ChangePill } from "components/Pill";
import { ChartLoadingWrapper } from "components/Loading";
import { AsyncStatus } from "types";
import { capitalCase } from "change-case";

interface ITooltip {
  active?: boolean;
  payload?: Array<any>;
  currency: string;
  currentDate: string;
  label?: string;
  currentPeriodData?: ChartPoint;
}

interface ChartPoint {
  name: string; // HISTORICAL, CURRENT, FORECAST
  date: string;
  revenue?: number;
  revenuePrev: number;
  quantityPrev: number;
  quantity: string; // should be number
  revenueProgress: number;
  weightedProgress: number;
  yearOverYearChange: number; // percentage
  forecastedRevenue?: number; // needed this to render the line correctly
  quantityForecast: {
    expected: string; // should be number
    upper: string; // should be number
    lower: string; // should be number
  };
  revenueForecast: {
    expected?: number;
    upper: number;
    lower: number;
  };
}

const CustomizedTooltip = ({
  active,
  payload,
  currency,
  currentPeriodData,
  currentDate,
}: ITooltip) => {
  if (active && payload?.length) {
    const data: ChartPoint = payload[0].payload;

    if (data.date === currentDate) {
      let revenueForecastExpected = data.revenueForecast.expected,
        revenueForecastLower = data.revenueForecast.lower,
        revenueForecastUpper = data.revenueForecast.upper,
        quantityForecast = data.quantityForecast.expected,
        revenuePrev = data.revenuePrev,
        quantityPrev = data.quantityPrev,
        change = data.yearOverYearChange,
        title = `${data.date} (${data.name})`;

      return (
        <BaseTooltip title={title}>
          <>
            <TooltipSubHeader title="RevenueProgress" legendColor={TW_COLORS.forecastBg} dashed />
            <TooltipSubHeader title="Forecasted" legendColor={TW_COLORS.forecastBg} dashed />
            <TooltipSubItem
              metric="Revenue Upper"
              value={formatCurrency(revenueForecastUpper, currency)}
            />
            <TooltipSubItem
              metric="Revenue Expected"
              value={formatCurrency(revenueForecastExpected, currency)}
            />
            <TooltipSubItem
              metric="Revenue Lower"
              value={formatCurrency(revenueForecastLower, currency)}
            />
            <TooltipSubItem metric="Quantity" value={quantityForecast} />
            <TooltipSubHeader title="Previous Year" legendColor={TW_COLORS.previousYearBg} line />
            <TooltipSubItem metric="Revenue" value={formatCurrency(revenuePrev, currency)} />
            <TooltipSubItem metric="Quantity" value={quantityPrev.toString()} />
            <TooltipSubHeader
              title="Forecasted Revenue Increase"
              legendColor={"transparent"}
              line
            />
            <ChangePill change={change} />
          </>
        </BaseTooltip>
      );
    } else if (data.name === "historical") {
      let revenue = data.revenue,
        quantity = data.quantity,
        revenuePrev = data.revenuePrev,
        quantityPrev = data.quantityPrev,
        change = data.yearOverYearChange,
        title = `${data.date} (${data.name})`;

      return (
        <BaseTooltip title={title}>
          <>
            <TooltipSubHeader title="Current Year" legendColor={TW_COLORS.currentYearBg} line />
            <TooltipItem metric="Revenue" value={formatCurrency(revenue, currency)} />
            <TooltipItem metric="Units Sold" value={quantity} />
            <TooltipSubHeader title="Previous Year" legendColor={TW_COLORS.previousYearBg} line />
            <TooltipItem metric="Revenue" value={formatCurrency(revenuePrev, currency)} />
            <TooltipItem metric="Quantity" value={quantityPrev.toString()} />
            <TooltipSubHeader title="Revenue Increase" legendColor={"transparent"} line />
            <ChangePill change={change} />
          </>
        </BaseTooltip>
      );
    } else if (data.name === "forecast") {
      let revenueForecastExpected = data.revenueForecast.expected,
        revenueForecastLower = data.revenueForecast.lower,
        revenueForecastUpper = data.revenueForecast.upper,
        quantityForecast = data.quantityForecast.expected,
        revenuePrev = data.revenuePrev,
        quantityPrev = data.quantityPrev,
        change = data.yearOverYearChange,
        title = `${data.date} (${data.name})`;

      return (
        <BaseTooltip title={title}>
          <>
            <TooltipSubHeader title="Forecasted" legendColor={TW_COLORS.forecastBg} dashed />
            <TooltipSubItem
              metric="Revenue Upper"
              value={formatCurrency(revenueForecastUpper, currency)}
            />
            <TooltipSubItem
              metric="Revenue Expected"
              value={formatCurrency(revenueForecastExpected, currency)}
            />
            <TooltipSubItem
              metric="Revenue Lower"
              value={formatCurrency(revenueForecastLower, currency)}
            />
            <TooltipSubItem metric="Quantity" value={quantityForecast} />
            <TooltipSubHeader title="Previous Year" legendColor={TW_COLORS.previousYearBg} line />
            <TooltipSubItem metric="Revenue" value={formatCurrency(revenuePrev, currency)} />
            <TooltipSubItem metric="Quantity" value={quantityPrev.toString()} />
            <TooltipSubHeader
              title="Forecasted Revenue Increase"
              legendColor={"transparent"}
              line
            />
            <ChangePill change={change} />
          </>
        </BaseTooltip>
      );
    }
  }
  return null;
};

interface VariantQuery {
  variantId: string;
}
interface ProductQuery {
  productId: string;
}
interface CategoryQuery {
  categoryTitle: string;
}

interface shopQuery {
  startDate: Date;
  endDate: Date;
  frequency: string;
}

type DemandChartQuery = VariantQuery | ProductQuery | CategoryQuery | shopQuery;

interface DemandChartQuerySelector {
  [key: string]: DemandChartQuery;
}

export const DemandTimeseries = ({
  id,
  type,
  context,
  chartData,
  noBorder,
  loadingParent,
  horizontalLegend,
  update,
}: {
  id: string;
  type: string;
  context?: React.Context<any>;
  chartData?: any;
  noBorder?: boolean;
  loadingParent?: AsyncStatus;
  horizontalLegend?: boolean;
  update?: boolean;
}) => {
  const { user } = useContext(AppStateContext);
  const [data, setData] = useState(chartData || []);
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [currentDate, setCurrentDate] = useState("");
  const [currentPeriodData, setCurrentPeriodData] = useState();
  const currency = user.currency;
  const { timeSeriesTime, timeSeriesFrequency, timeSeriesType, statesFetched } = useContext(
    context || DashboardContext
  );

  //const [timeSeriesType.key, settimeSeriesType.key] = useState("quantity");
  // why are these Effects both here?
  useEffect(() => {
    if (!chartData && id) {
      if (type === "shop" && !statesFetched) {
        return;
      }
      getModalDemand(id);
    }
  }, [
    id,
    JSON.stringify(timeSeriesTime),
    JSON.stringify(timeSeriesFrequency),
    JSON.stringify(chartData),
    update,
    statesFetched,
  ]);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [JSON.stringify(chartData)]);
  useEffect(() => {
    if (typeof loadingParent !== "undefined") {
      setLoading(loadingParent);
    }
  }, [loadingParent]);

  const getModalDemand = async (id: string) => {
    // there are other options than these
    const routesByType: { [key: string]: string } = {
      variant: `/get-variant-modal-demand-chart`,
      product: `/get-product-modal-demand-chart`,
      category: `/get-category-modal-demand-chart`,
      vendor: `/get-vendor-modal-demand-chart`,
      shop: `/get-shop-modal-demand-chart`,
    };

    const queryByType: DemandChartQuerySelector = {
      variant: { ...timeSeriesTime, frequency: timeSeriesFrequency.key, variantId: id }, // this is varaintId
      product: { ...timeSeriesTime, frequency: timeSeriesFrequency.key, productId: id }, // this is productId
      category: { ...timeSeriesTime, frequency: timeSeriesFrequency.key, categoryTitle: id }, // this is categoryTitle
      vendor: { ...timeSeriesTime, frequency: timeSeriesFrequency.key, vendor: id }, // this is vendor
      shop: {
        ...timeSeriesTime,
        frequency: timeSeriesFrequency.key,
      },
    };

    const selectQueryByType = (type: string, id: string): DemandChartQuery => {
      return queryByType[type];
    };

    try {
      setLoading(AsyncStatus.Loading);
      const url = routesByType[type];
      const param = selectQueryByType(type, id);
      const res = await client.get(url, { params: { ...param, showPreviousYear: true } });

      // console.log({
      //   url,
      //   param: { ...param, showPreviousYear: true },
      //   original: res.data.data,
      // });

      let points = res.data.data;

      setLoading(AsyncStatus.Loaded);
      setData(points);
    } catch (err: any) {
      setLoading(AsyncStatus.Failed);
      console.error(err);
    }
  };

  return (
    <ChartLoadingWrapper data={data} loading={loading}>
      <ResponsiveContainer>
        <ComposedChart data={data} margin={{ top: 16, right: 32, bottom: 8, left: 0 }}>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            style={{
              fontSize: 10,
              fontFamily: "SF Mono",
              fill: HEX_COLORS.currentYear,
            }}
            height={60}
            interval={data.length < 20 ? 0 : Math.floor(data.length / 20)}
            angle={-45}
            textAnchor="end"
          />
          <YAxis
            tickLine={false}
            style={{
              fontSize: 10,
              fontFamily: "SF Mono",
              fill: HEX_COLORS.currentYear,
            }}
            allowDecimals={false}
            padding={{ top: 10, bottom: 10 }}
            tickFormatter={
              timeSeriesType.key === "revenue"
                ? (value) => formatCurrency(value, currency, true)
                : undefined
            }
          />
          <Tooltip
            wrapperStyle={{ zIndex: 50 }}
            content={
              <CustomizedTooltip
                currentDate={currentDate}
                currency={currency}
                currentPeriodData={currentPeriodData}
              />
            }
          />
          {horizontalLegend ? (
            <Legend
              content={
                <ChartLegend layout="horizontal">
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Historical)`}
                    legendColor={TW_COLORS.currentYearBg}
                    line
                  />
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Forecast)`}
                    legendColor={TW_COLORS.forecastBg}
                    dashed
                  />
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Prev. Year)`}
                    legendColor={TW_COLORS.previousYearBg}
                    line
                  />
                </ChartLegend>
              }
            />
          ) : (
            <Legend
              layout="vertical"
              align="right"
              //@ts-ignore
              verticalAlign="center"
              content={
                <ChartLegend>
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Historical)`}
                    legendColor={TW_COLORS.currentYearBg}
                    line
                  />
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Forecast)`}
                    legendColor={TW_COLORS.forecastBg}
                    dashed
                  />
                  <LegendItem
                    title={`${capitalCase(timeSeriesType.key)} (Prev. Year)`}
                    legendColor={TW_COLORS.previousYearBg}
                    line
                  />
                </ChartLegend>
              }
            />
          )}
          <ReferenceLine x={currentDate} stroke={HEX_COLORS.forecast} />

          <Area
            type="bumpX"
            dataKey={
              timeSeriesType.key === "quantity" ? "quantityForecast.upper" : "revenueForecast.upper"
            }
            stroke="transparent"
            fill={"transparent"}
            activeDot={{ fill: "gray", strokeWidth: 0, r: 2 }}
            stackId={1}
          />
          <Area
            type="bumpX"
            dataKey={(data) => {
              return timeSeriesType.key === "quantity"
                ? data.quantityForecast.lower - data.quantityForecast.upper
                : data.revenueForecast.lower - data.revenueForecast.upper;
            }}
            stroke={"transparent"}
            fill={HEX_COLORS.forecastConfidenceInterval}
            activeDot={{ fill: "gray", strokeWidth: 0, r: 2 }}
            stackId={1}
          />
          {timeSeriesType.key === "quantity" && (
            <Line
              type="bumpX"
              dataKey="quantityPrev"
              stroke={HEX_COLORS.previousYear}
              strokeWidth={3}
              dot={false}
            />
          )}

          {timeSeriesType.key === "revenue" && (
            <Line
              type="bumpX"
              dataKey="revenuePrev"
              stroke={HEX_COLORS.previousYear}
              strokeWidth={3}
              dot={false}
            />
          )}
          <Line
            type="bumpX"
            dataKey={timeSeriesType.key}
            stroke={HEX_COLORS.currentYear}
            strokeWidth={3}
            dot={false}
          />
          <Line
            type="bumpX"
            dataKey={
              timeSeriesType.key === "quantity"
                ? "quantityForecast.expected"
                : "revenueForecast.expected"
            }
            stroke={HEX_COLORS.forecast}
            strokeWidth={3}
            dot={false}
            strokeDasharray="5 4"
            activeDot={{ fill: HEX_COLORS.forecast, strokeWidth: 0, r: 4 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartLoadingWrapper>
  );
};

export default DemandTimeseries;
