import { cancelAllPendingRequests, getAllPendingRequests } from "context/AxiosInterceptor";
import { Transition } from "@headlessui/react";
import Button from "components/Button";
import { LoadingProgressBar } from "components/Others/ProgressBar";
import { RequestContext } from "context";
import { useContext, useEffect, useState, Fragment } from "react";
import { sentenceCase } from "change-case";

export const ProgressModal = () => {
  const { pendingRequests } = useContext(RequestContext);
  const [progress, setProgress] = useState(0);
  const [pendingPaths, setPendingPaths] = useState<string[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setProgress((prev) => Math.max(prev - 1, 0));
    const pendingUrls = getAllPendingRequests()
      .map((req) => sentenceCase(req.config.url || ""))
      .filter((url): url is string => typeof url === "string");
    setPendingPaths(pendingUrls);
  }, [pendingRequests]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 4) {
        setProgress((prev) => prev + 1);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [pendingRequests]);

  useEffect(() => {
    if (pendingRequests < 1) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [pendingRequests, progress]);

  const cancelRequest = () => {
    cancelAllPendingRequests();
  };

  const transitionClasses = {
    enter: "transform transition duration-[500ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[500ms] delay-[400ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  return (
    <Transition show={show} as={Fragment} {...transitionClasses}>
      <div className="relative z-[1001]">
        <div className="fixed text-2xs font-mono bottom-20 right-10 bg-widget w-96 shadow-wrapper border border-solid border-border-internal shadow-lg rounded-xl text-base-text p-4 gap-3 flex flex-col">
          {pendingRequests < 1 ? (
            <p className="font-mono-bold">Done! 🎉</p>
          ) : (
            <p className="font-mono-bold loading-ellipsis">Pending requests: {pendingRequests}</p>
          )}
          <LoadingProgressBar initStatus={progress} isDone={pendingRequests < 1} />
          <div className="flex gap-3 w-full">
            <div className="flex gap-1 truncate flex-grow">
              {pendingPaths.map((path, i) => (
                <Path key={path + "_" + i} text={path} />
              ))}
            </div>
            <Button
              buttonType="primary"
              label={`Cancel`}
              hoverColor={"#4F369B"}
              className="text-2xs font-mono justify-self-end"
              onClick={cancelRequest}
            />
          </div>
        </div>
      </div>
    </Transition>
  );
};

const Path = ({ text }: { text: string }) => (
  <p className="bg-black/5 p-1 text-black/60 rounded-md flex flex-col justify-center">{text}</p>
);
