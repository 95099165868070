import { AppStateContext } from "context";
import React, { useContext, useEffect, useState } from "react";
import { Dot } from "./Dot";
import { UnitLoadingWrapper } from "components/Loading";
import { AsyncStatus, IOption } from "types";
import ReadMore from "components/Others/readMore";
import TextInput from "components/FormInputs/TextInput";
import { Controller, FieldErrors, SubmitHandler, useForm, UseFormSetValue } from "react-hook-form";
import Button from "components/Button";
import Dropdown from "./Dropdown";
import { ReactComponent as Close } from "assets/images/Close-red.svg";

interface ICard {
  name: string;
  value: string;
  loading: AsyncStatus;
  dotColor?: string;
  inventoryStatus?: string;
  onClick?: () => void;
}
interface IEditableCard {
  name: string;
  value: string;
  loading: AsyncStatus;
  dotColor?: string;
  inventoryStatus?: string;
  onSubmit: any;
  validator: (value: string) => string | true;
  onClick?: () => void;
  unit?: string;
  isVariantLevel?: boolean;
}
interface IInputCard {
  name: string;
  value: string;
  loading: AsyncStatus;
  inputName: string;
  control: any;
  setValue: UseFormSetValue<any>;
  validator: any;
  originalValue: string;
  errors: any;
  dotColor?: string;
  inventoryStatus?: string;
  onClick?: () => void;
  options?: IOption[];
  selected?: IOption;
  setSelected?: React.Dispatch<React.SetStateAction<IOption>>;
  hideClear?: boolean;
}

export const Card = ({ name, value, loading, dotColor, inventoryStatus, onClick }: ICard) => {
  const { showAddCost } = useContext(AppStateContext);

  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""}
      py-2 px-3`}
    >
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{name}</div>
        {(showAddCost || inventoryStatus) && dotColor && <Dot backgroundColor={dotColor} />}
      </div>
      <div className="flex justify-between items-center">
        {inventoryStatus ? (
          <>
            <div className="text-base-text text-2xl font-semibold flex items-center">
              <UnitLoadingWrapper loading={loading}>
                <>{value}</>
              </UnitLoadingWrapper>
              <div className="text-base opacity-20 pl-2">
                <UnitLoadingWrapper loading={loading}>
                  <>{"/ " + inventoryStatus}</>
                </UnitLoadingWrapper>
              </div>
            </div>
          </>
        ) : (
          <CardRow>
            <UnitLoadingWrapper loading={loading}>
              <>{value}</>
            </UnitLoadingWrapper>
          </CardRow>
        )}
      </div>
    </div>
  );
};

export const EditableCard = ({
  name,
  value,
  loading,
  dotColor,
  inventoryStatus,
  onClick,
  onSubmit,
  validator,
  unit,
  isVariantLevel,
}: IEditableCard) => {
  const initialData = {
    editedValue: value === "-" ? "" : parseInt(value),
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    watch,
    setValue,
  } = useForm<any>({
    defaultValues: initialData,
    shouldFocusError: true,
  });
  const editedValue = watch("editedValue");
  const { showAddCost } = useContext(AppStateContext);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setValue("editedValue", value === "-" ? "" : parseInt(value));
  }, [value]);

  const handleFormSubmit = async (data: any, overrideNullOnly: boolean) => {
    try {
      await onSubmit(data, overrideNullOnly);
      setEditing(false);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="flex items-center py-2 px-3 w-full">
        <div
          onClick={onClick}
          className={`${
            onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""
          }`}
        >
          <div className="flex items-center">
            <div className="text-base-text-lighten text-md w-fit ">{name}</div>
            {(showAddCost || inventoryStatus) && dotColor && <Dot backgroundColor={dotColor} />}
          </div>
          <div className="flex justify-between items-center">
            {inventoryStatus ? (
              <>
                <div className="text-base-text text-2xl font-semibold flex items-center">
                  <UnitLoadingWrapper loading={loading}>
                    <>{value}</>
                  </UnitLoadingWrapper>
                  <div className="text-base opacity-20 pl-2">
                    <UnitLoadingWrapper loading={loading}>
                      <>{"/ " + inventoryStatus}</>
                    </UnitLoadingWrapper>
                  </div>
                </div>
              </>
            ) : (
              <CardRow>
                <UnitLoadingWrapper loading={loading}>
                  <>
                    {editing ? (
                      <Controller
                        name={"editedValue"}
                        control={control}
                        rules={{ validate: validator }}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            type="text"
                            value={editedValue}
                            className="w-10/12 h-6 mr-4"
                            errorMessage={errors.editedValue?.message as string}
                            autoFocus
                          />
                        )}
                      />
                    ) : (
                      <>
                        {value} {unit}
                      </>
                    )}
                  </>
                </UnitLoadingWrapper>
              </CardRow>
            )}
          </div>
        </div>

        <Button
          label={editing ? "Cancel" : "Edit"}
          onClick={() => setEditing((prev) => !prev)}
          buttonType="primary"
          className="ml-auto"
        ></Button>
        {editing && isVariantLevel && (
          <Button
            label={"Save"}
            onClick={async () => {
              try {
                await handleSubmit((data) => handleFormSubmit(data, true))();
              } catch (error) {
                console.error("Error occurred:", error);
              }
            }}
            buttonType="primary"
            className="ml-2"
          ></Button>
        )}
      </div>
      {editing && !isVariantLevel && (
        <div className="flex justify-center items-center">
          <Button
            label={"Change Undefined"}
            onClick={async () => {
              try {
                await handleSubmit((data) => handleFormSubmit(data, true))();
              } catch (error) {
                console.error("Failed to submit:", error);
              }
            }}
            buttonType="primary"
            className="ml-2"
          ></Button>
          <Button
            label={"Change All"}
            onClick={async () => {
              try {
                await handleSubmit((data) => handleFormSubmit(data, false))();
              } catch (error) {
                console.error("Failed to submit:", error);
              }
            }}
            buttonType="primary"
            className="ml-2"
          ></Button>
        </div>
      )}
    </form>
  );
};
export const InputCard = ({
  name,
  value,
  loading,
  onClick,
  inputName,
  options,
  control,
  selected,
  setSelected,
  hideClear,
  errors,
  setValue,
  validator,
  originalValue,
}: IInputCard) => {
  const handleSelect = (item: IOption) => {
    if (setSelected) {
      setSelected(item);
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setValue(inputName, originalValue);
  };
  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""}
      py-2 px-3`}
    >
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{name}</div>
      </div>
      <div className="flex justify-between items-center">
        {
          <CardRow>
            <UnitLoadingWrapper loading={loading}>
              <div className="flex w-full">
                <div className="text-black w-full">
                  <Controller
                    name={inputName}
                    control={control}
                    rules={{ validate: validator }}
                    render={({ field }) => (
                      <input
                        className="w-full h-[30px] px-[5px] py-[3px] bg-white rounded-[5px] justify-start items-center inline-flex font-mono font-normal text-violet-900"
                        {...field}
                      />
                    )}
                  />
                </div>
                {options && selected && (
                  <div className="text-white">
                    <Dropdown options={options} option={selected} onSelect={handleSelect} />
                  </div>
                )}
                {!hideClear && (
                  <button onClick={handleClear} className="ml-2">
                    <Close />
                  </button>
                )}
              </div>

              {/* @ts-ignore */}
              <div>{errors[inputName]?.message}</div>
            </UnitLoadingWrapper>
          </CardRow>
        }
      </div>
    </div>
  );
};

export const CardDescription = ({
  name,
  value,
  loading,
  dotColor,
  inventoryStatus,
  onClick,
}: ICard) => {
  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""}
        py-2 px-3`}
    >
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{name}</div>
      </div>
      <div className="flex justify-between items-center text-sm">
        <CardDescriptionRow>
          <UnitLoadingWrapper loading={loading}>
            <ReadMore text={value} maxLength={160} loading={loading} />
          </UnitLoadingWrapper>
        </CardDescriptionRow>
      </div>
    </div>
  );
};

export const SimpleCard = ({
  title,
  children,
  loading,
}: {
  title: string;
  children: React.ReactNode;
  loading: AsyncStatus;
}) => {
  return (
    <div className={`transition-all py-2 px-3`}>
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{title}</div>
      </div>
      <div className="flex justify-between items-center">
        <CardRow>
          <UnitLoadingWrapper loading={loading}>
            <>{children}</>
          </UnitLoadingWrapper>
        </CardRow>
      </div>
    </div>
  );
};

const CardRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-base-text text-xl font-semibold">{children}</div>;
};

const CardDescriptionRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-base-text rounded-md">{children}</div>;
};

export default Card;
