import { AsyncStatus, FrequencyOptionKeys } from "types";
import { DATE_FREQUENCY } from "utils/constants";
import { RadioDropdown } from "./Filters";
import { IObject } from "types";

export type SingleSelectTypes = "created" | "modified" | "sale" | "noSale";

interface Props {
  setFrequency: (frequency: FrequencyOptionKeys) => void;
  setCloseDropdown: (closeDropdown: boolean) => void;
  loading: AsyncStatus;
  defaultOption?: FrequencyOptionKeys;
}

export const DateFrequencyDropdown = ({
  setFrequency,
  setCloseDropdown,
  loading,
  defaultOption,
}: Props) => {
  const handleApply = (key?: string) => {
    setFrequency(key as FrequencyOptionKeys);
    setCloseDropdown(true);
  };

  function getDefaultOption(defaultOption: FrequencyOptionKeys = "month"): string {
    return DATE_FREQUENCY.filter((item: IObject, i: number) => item.key === defaultOption)[0].value;
  }

  return (
    <RadioDropdown
      onApply={handleApply}
      options={DATE_FREQUENCY}
      defaultOption={getDefaultOption(defaultOption)}
      disableApply={loading !== AsyncStatus.Loaded}
      type={"frequency"}
    />
  );
};

export default DateFrequencyDropdown;
