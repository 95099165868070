const msInDay = 24 * 60 * 60 * 1000;

// Calculates the total amount of days between two dates
const daysBetween = (startDate: Date, endDate: Date): number => {
  return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInDay);
};

// Convert date to format "MMM DD" where
// the month is a 3 letter abbreviation.
// 2022-01-01 => Jan 01
// 2022-07-31 => Jul 31
const formatMMMDD = (stringDate: string): string => {
  const date = new Date(stringDate + "T00:00");
  const month: string = date.toLocaleString("en-US", { month: "long" });
  const formattedDate: string = `${month.substring(0, 3)} ${
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
  }`;
  return formattedDate;
};

// Adds the label (today) if the ticker date is today
const currentDay = (stringDate: string): string => {
  const date = new Date(stringDate + "T00:00");
  const month: string = date.toLocaleString("en-US", { month: "long" });
  const today: Date = new Date();
  return today.toDateString() === date.toDateString() ? "(today)" : "";
};

// Fast forward input date by number of days
// forwardDays(2022-01-01, 3) => 2022-01-04
const forwardDays = (date: Date, days: number): Date => {
  const currentDate: Date = new Date(date.toISOString().slice(0, 10));
  const currentDateInMs: number = currentDate.getTime();
  return new Date(currentDateInMs + msInDay * days);
};

const formatDate = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().split("T")[0];
};

const toLocalDateString = (date: Date) => {
  return date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

export function minDate(date1: Date, date2: Date): Date {
  if (date1 < date2) {
    return date1;
  } else if (date1 > date2) {
    return date2;
  }
  return date1;
}

export function rewindDays(date: Date, days: number): Date {
  const currentDate: Date = new Date(date.toISOString().slice(0, 10));
  const currentDateInMs: number = currentDate.getTime();
  return new Date(currentDateInMs - msInDay * days);
}

export function getDefaultMonthRange() {
  const currentDate = new Date();

  const startDate = new Date(currentDate);
  startDate.setMonth(startDate.getMonth() - 2);
  startDate.setDate(0);

  const endDate = new Date(currentDate);
  endDate.setMonth(endDate.getMonth());
  endDate.setDate(0);

  const isoStartDate = startDate.toISOString().slice(0, 10);
  const isoEndDate = endDate.toISOString().slice(0, 10);

  return {
    startDate: new Date(isoStartDate),
    endDate: new Date(isoEndDate),
  };
}

export function getWeekNumber(date: Date): number {
  const simple = new Date(date.getFullYear(), 0, 1).getDay() === 0 ? -6 : 1;
  const dayOfYear =
    Math.floor((date.valueOf() - new Date(date.getFullYear(), 0, 1).valueOf()) / 86400000) + simple;
  return Math.floor((dayOfYear - simple) / 7) + 1;
}

export function calculateDayCount(startDate: Date, endDate: Date): number {
  const timeDifferenceMs = endDate.getTime() - startDate.getTime();
  const dayCount = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  return dayCount;
}

export function calculateWeekCount(startDate: Date, endDate: Date): number {
  const dayCount = calculateDayCount(startDate, endDate);
  const weekCount = Math.ceil(dayCount / 7); // Assuming a week has 7 days
  return weekCount;
}

export const compareDatesWithoutTime = (date1: Date, date2: Date): number => {
  const truncatedDate1 = new Date(date1);
  truncatedDate1.setHours(0, 0, 0, 0);

  const truncatedDate2 = new Date(date2);
  truncatedDate2.setHours(0, 0, 0, 0);

  if (truncatedDate1 < truncatedDate2) {
    return -1; // date1 is earlier
  } else if (truncatedDate1 > truncatedDate2) {
    return 1; // date1 is later
  } else {
    return 0; // dates are equal
  }
};

export {
  msInDay,
  daysBetween,
  formatMMMDD,
  currentDay,
  forwardDays,
  formatDate,
  toLocalDateString,
};
