import { useContext, useEffect, useState } from "react";
import StackedAreaChart from "components/StackedAreaChart";
import Button from "components/Button";
import { ChartWrapper } from "layouts/BaseLayout";
import { saveState, tableHeightSelector8 } from "utils/helper-ts";
import client from "api";
import { AppStateContext, DemandContext, TopPerformersContext } from "context";
import { FrequencyOptionKeys, DemandMetricOptions, AsyncStatus } from "types";
import {
  DATE_OPTIONS,
  DEMAND_METRICS,
  PLACEHOLDER_ROW_TOP_PERFORMERS,
  PLACEHOLDER_TOP_PERFORMERS,
} from "utils/constants";
import { PaginationState } from "@tanstack/react-table";
import React from "react";
import TopBar from "components/TopBar";
import BaseTable from "components/Table/BaseTable";
import { DefaultHeaderCellMap } from "components/Table";
import { TopPerformersCellMap } from "./column-cell-mapper";
import { TableOptions } from "components/Table/TableOptions";
import { getDefaultMonthRange } from "utils/date";

interface TopPerformersQuery {
  frequency: string;
  startDate: string;
  endDate: string;
  numberOfTopPerformers: number;
  metric: "revenue" | "shareOfRevenue";
  showOther: boolean;
}

const currentDate = new Date();
const yearStart = new Date(currentDate.getFullYear(), 0, 1);

// default table options
export const DEFAULT_OPTIONS: {
  frequency: FrequencyOptionKeys;
  startDate: Date;
  endDate: Date;
  metrics: DemandMetricOptions[];
} = {
  frequency: "day",
  startDate: getDefaultMonthRange().startDate,
  endDate: getDefaultMonthRange().endDate,
  metrics: [DEMAND_METRICS[0], DEMAND_METRICS[1]],
};

function TopPerformersIndex() {
  const { updateSelectedRow } = useContext(AppStateContext);

  const {
    organizeBy,
    dateRange,
    updateDateRange,
    frequency,
    updateFrequency,
    showChart,
    updateShowChart,
    showOther,
    updateShowOther,
    metric,
    updateMetric,
    statesFetched,
  } = useContext(TopPerformersContext);
  const {
    // demandSuiteFilter,
    sorting,
    updateSorting,
    // metrics,
    // updateMetrics,
    // organizeBy,
  } = useContext(DemandContext);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 200, // this shouldnt be effective
  });

  const [pageCount, setPageCount] = React.useState(0);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // data
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [columns, setColumns] = useState<any>(PLACEHOLDER_TOP_PERFORMERS);
  // move to global later

  const [numberOfTopPerformers, setNumberOfTopPerformers] = useState(
    organizeBy.key == "vendor" ? 100 : 10
  );
  const [rows, setRows] = useState<any>(
    Array(numberOfTopPerformers).fill({ ...PLACEHOLDER_ROW_TOP_PERFORMERS })
  );
  const [openDateRangeDropdown, setOpenDateRangeDropdown] = useState(false);

  async function getTopPerformerData({
    numberOfTopPerformers,
    startDate,
    endDate,
  }: {
    numberOfTopPerformers: number;
    startDate: string;
    endDate: string;
  }) {
    try {
      updateSelectedRow("");
      setLoading(AsyncStatus.Loading);
      numberOfTopPerformers = organizeBy.key == "vendor" ? 100 : 10;
      const res = await client.get("/get-top-performers-hist-sales", {
        params: {
          numberOfTopPerformers,
          startDate,
          endDate,
          showOther,
          organizeBy: organizeBy.key,
        },
      });

      // console.log({
      //   numberOfTopPerformers,
      //   startDate,
      //   endDate,
      //   showOther,
      //   organizeBy: organizeBy.key,
      // })

      // setPageCount(Math.ceil ((res.data.data.totalRowCount / PAGE_COUNT) * metrics.length));
      if (organizeBy.key === "vendor" && showOther) {
        res.data.data.rows.shift();
        setRows(res.data.data.rows);
      } else {
        setRows(res.data.data.rows);
      }
      setColumns(res.data.data.columns);

      console.log({
        rows: res.data.data.rows,
        columns: res.data.data.columns,
        data: res.data.data,
      });

      if (res.data.data.rows.length > 0) {
        setLoading(AsyncStatus.Loaded);
      } else {
        setLoading(AsyncStatus.Empty);
      }
    } catch (err: any) {
      console.log(err);
      setLoading(AsyncStatus.Failed);
    }
  }

  function handleChangeFrequency(incomingFrequency: FrequencyOptionKeys) {
    updateFrequency(incomingFrequency);
    saveState("top_perf_page_freq", incomingFrequency);
  }

  const [prevDependencies, setPrevDependencies] = useState<any>({
    frequency: null,
    organizeBy: null,
    dateRange: null,
    demandSuiteFilter: null,
    sorting: null,
    metrics: null,
  });

  useEffect(() => {
    if (statesFetched) {
      getTopPerformerData({
        numberOfTopPerformers,
        startDate: dateRange.startDate.toISOString().slice(0, 10),
        endDate: dateRange.endDate.toISOString().slice(0, 10),
      });
    }
  }, [dateRange, showOther, numberOfTopPerformers, organizeBy, statesFetched]);

  return (
    <div className="relative overflow-hidden">
      <TopBar collapsed={true}>
        <TableOptions
          openDateRangeDropdown={openDateRangeDropdown}
          dateRangeOptions={DATE_OPTIONS}
          frequency={frequency}
          handleChangeFrequency={handleChangeFrequency}
          dateRange={dateRange}
          setDateRange={updateDateRange}
          loading={loading}
          defaultFrequency={frequency}
        />
        <Button
          buttonType="primary"
          label={`${showOther ? "Hide" : "Show"} Other ${organizeBy.value}s`}
          onClick={() => {
            saveState("top_perf_page_other", !showOther);
            updateShowOther(!showOther);
          }}
          hoverColor={"#4F369B"}
          className="jusitfy-self-end"
        />
        <Button
          buttonType="primary"
          label={metric === "shareOfRevenue" ? "Revenue" : "Revenue Contribution"}
          onClick={() => {
            updateMetric(metric === "shareOfRevenue" ? "revenue" : "shareOfRevenue");
            saveState(
              "top_perf_page_type",
              metric === "shareOfRevenue" ? "revenue" : "shareOfRevenue"
            );
          }}
          hoverColor={"#4F369B"}
          className="jusitfy-self-end"
        />
        <Button
          buttonType="primary"
          label={`${showChart ? "Hide" : "Show"} Chart`}
          onClick={() => {
            saveState("top_perf_page_chart", !showChart);
            updateShowChart(!showChart);
          }}
          hoverColor={"#4F369B"}
          className="jusitfy-self-end"
        />
        {organizeBy.key === "product" && (
          <Button
            buttonType="primary"
            label={"Save"}
            onClick={async () => {
              try {
                await client.post("/save-top-performers-product-id", {
                  productIds: rows.map((row: any) => row.productId),
                  nameOfSelection: "Top Performers",
                });
              } catch (error) {
                console.error(error);
              }
            }}
            hoverColor={"#4F369B"}
            className="jusitfy-self-end"
          />
        )}
      </TopBar>
      <ChartWrapper showChart={showChart} loading={loading}>
        <StackedAreaChart
          frequency={frequency}
          startDate={dateRange.startDate.toISOString().slice(0, 10)}
          endDate={dateRange.endDate.toISOString().slice(0, 10)}
          metric={metric}
          numberOfTopPerformers={numberOfTopPerformers}
          showOther={showOther}
        />
      </ChartWrapper>
      <div
        className={`${tableHeightSelector8(showChart)} overflow-auto w-full`}
        id="scrollableTable"
      >
        <BaseTable
          name={"topPerformers"}
          rows={rows}
          columns={columns}
          fullWidth={true}
          sorting={sorting}
          updateSorting={updateSorting}
          pagination={pagination}
          setPagination={setPagination}
          pageCount={pageCount}
          loading={loading}
          HeaderMapperComponent={DefaultHeaderCellMap}
          CellMapperComponent={TopPerformersCellMap}
          minColSize={100}
        />
      </div>
    </div>
  );
}

export default TopPerformersIndex;
