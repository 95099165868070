import { Button } from "../Buttons";
import { GlassModal } from "components/Modal/glassMorphModal";
import { openInNewTab, brandGradient } from "utils/helper-ts";

const Mobile = () => (
  <div
    style={brandGradient}
    className="md:hidden items-center justify-center h-screen flex fixed w-screen h-screen bg-black overflow-hidden z-[100]"
  >
    <GlassModal>
      <h2 className="text-white text-xl">Mobile orientation coming soon</h2>
      <h3 className="text-white text-xs">
        We are focused on providing the best desktop experience we can. In the meantime, increase
        your screen width. 🙏
      </h3>
      <div className="flex flex-col px-8 gap-2 items-center">
        <Button
          className="w-auto whitespace-nowrap"
          text="Chat with us"
          action={() => openInNewTab("https://calendly.com/bucephalus/chat-with-a-ds")}
        />
      </div>
    </GlassModal>
  </div>
);

export default Mobile;
