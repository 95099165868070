import React, { useState } from "react";
import { IObject } from "types";
import { viewAlertColumns } from "components/ChooseColumns/ViewAlertsChooseColumns";
import { SortingState } from "@tanstack/react-table";

interface IAlertsContext {
  alertsProduct: IObject;
  updateAlertsProduct: (product: IObject) => void;
  alertsSelectedVariants: string[];
  updateAlertsSelectedVariants: (selectedVariants: string[]) => void;
  fetchAlertsVariants: boolean;
  updateFetchAlertsVariants: (fetchVariants: boolean) => void;
  alertsInventoryStatus: IObject;
  updateAlertsInventoryStatus: (inventoryStatus: IObject) => void;
  selColAlerts: any[];
  updateSelColAlerts: (columns: any[]) => void;

  selColViewAlerts: any[];
  updateSelColViewAlerts: (columns: any[]) => void;
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  selVarSorting: SortingState;
  updateSelVarSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

export const AlertsContext = React.createContext<IAlertsContext>({
  alertsProduct: { key: "", value: "All Products" },
  updateAlertsProduct: () => {},
  alertsSelectedVariants: [],
  updateAlertsSelectedVariants: () => {},
  fetchAlertsVariants: true,
  updateFetchAlertsVariants: () => {},
  alertsInventoryStatus: { key: "", value: "" },
  updateAlertsInventoryStatus: () => {},
  selColAlerts: [],
  updateSelColAlerts: () => {},

  selColViewAlerts: [],
  updateSelColViewAlerts: () => {},
  sorting: [],
  updateSorting: () => {},
  selVarSorting: [],
  updateSelVarSorting: () => {},
});

interface IAlertsContextProps {
  children: React.ReactNode;
}

export const AlertsProvider = (props: IAlertsContextProps) => {
  const [alertsProduct, setAlertsProduct] = useState<IObject>({ key: "", value: "All Products" });
  const [alertsSelectedVariants, setAlertsSelectedVariants] = useState<string[]>([]);
  const [fetchAlertsVariants, setFetchAlertsVariants] = useState(true);
  const [alertsInventoryStatus, setAlertsInventoryStatus] = useState<IObject>({
    key: "none",
    value: "All Inventory Status",
  });
  const [selColAlerts, setSelColAlerts] = useState<any[]>([]);
  const [selColViewAlerts, setSelColViewAlerts] = useState<any[]>(viewAlertColumns);
  const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);
  const [selVarSorting, setSelVarSorting] = useState<SortingState>([{ id: "units", desc: false }]);

  return (
    <AlertsContext.Provider
      value={{
        //alerts
        alertsProduct,
        updateAlertsProduct: setAlertsProduct,
        alertsSelectedVariants,
        updateAlertsSelectedVariants: setAlertsSelectedVariants,
        fetchAlertsVariants,
        updateFetchAlertsVariants: setFetchAlertsVariants,
        alertsInventoryStatus,
        updateAlertsInventoryStatus: setAlertsInventoryStatus,
        selColAlerts,
        updateSelColAlerts: setSelColAlerts,
        selColViewAlerts,
        updateSelColViewAlerts: setSelColViewAlerts,
        sorting,
        updateSorting: setSorting,
        selVarSorting,
        updateSelVarSorting: setSelVarSorting,
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};
