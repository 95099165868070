import { IFilterPlus, IObject } from "types";

export type SingleSelectTypes = "created" | "modified" | "sale" | "noSale";

interface Props {
  onApply: () => void;
  updatePageFilter: (items: any) => void;
  type: SingleSelectTypes;
}

const SALES_OPTIONS: IObject[] = [
  { key: "MTD", value: "Month to date" },
  { key: "QTD", value: "Quarter to date" },
  { key: "YTD", value: "Year to date" },
  { key: "All", value: "All" },
];

const FilterName: { [key: string]: string } = {
  sale: "Sales",
  noSale: "No Sales",
  created: "Created",
  modified: "Modified",
};

export const SingleSelectDropdown = ({ onApply, updatePageFilter, type }: Props) => {
  return (
    <div>
      {SALES_OPTIONS.map(({ key, value }) => (
        <div
          className="flex items-center h-8 px-2 hover:rounded-full hover:bg-border-hover/40 hover:font-bold"
          onClick={() => {
            updatePageFilter((old: IFilterPlus[]) => {
              const updatedFilters = old.map((item: IFilterPlus) =>
                item.key === type
                  ? { key: type, value: [key], name: FilterName[type] + ": " + value }
                  : item
              );
              return updatedFilters;
            });
            onApply();
          }}
          key={key}
        >
          {value}
        </div>
      ))}
    </div>
  );
};

export default SingleSelectDropdown;
