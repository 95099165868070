import Modal from "./BasicModal/index";

export const OnboardingFailedModal = ({
  visible,
  handleOk,
  handleCancel,
}: {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) => {
  const modalCustomFooter = [
    {
      label: "",
      action: () => false,
    },
  ];
  return (
    <Modal
      title="Onboarding Failed"
      visible={visible}
      showCancel={true}
      onCancel={handleOk}
      customFooter={modalCustomFooter}
    >
      <div className="prose max-w-prose text-base-text py-4">
        <p className="text-sm">
          Something unexpected happened and your onboarding process failed. We got the notification
          and will reach out to you shortly.
        </p>

        <p className="text-sm">
          If you have any questions, please reach out through Live Chat in the bottom right corner
          of the screen.
        </p>
      </div>
    </Modal>
  );
};

export default OnboardingFailedModal;
