import { forwardRef, useRef, useEffect } from "react";

// eslint-disable-next-line react/display-name
export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        className="
        text-widget border border-solid border-border-external rounded-xs w-3 h-3 m-0.5 outline-none appearance-none
        focus:ring-0 focus:ring-transparent focus:ring-offset-transparent
        indeterminate:border indeterminate:border-solid indeterminate:border-border-external indeterminate:bg-dot indeterminate:bg-50%
        indeterminate:hover:border indeterminate:hover:border-solid indeterminate:hover:border-border-external
        checked:border checked:border-solid checked:border-border-external checked:bg-check
        checked:hover:border checked:hover:border-solid checked:hover:border-border-external
        checked:focus:border checked:focus:border-solid checked:focus:border-border-external
        disabled:opacity-50
      "
        {...rest}
      />
    </>
  );
});

export default IndeterminateCheckbox;
