import { sentenceCase } from "change-case";
import { ApplyButton } from "components/Button";
import React, { useContext, useEffect, useState } from "react";
import { StatesContext } from "context";
import { saveState } from "utils/helper-ts";

const columnMap: { [key: string]: any } = {
  inventory: "inventory_columns",
  planning: "planning_columns",
  topPerformers: "top_performers_columns",
  walkthrough: "walkthrough_columns",
  products: "products_columns",
  alerts: "alerts_columns",
  viewAlert: "view_alert_columns",
  selectAlertVariant: "select_alert_variant_columns",
};

interface Props {
  table: any;
  setCloseDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

const getColumnHeader = (column: any) => {
  const header = column?.parent?.columnDef?.header || column?.columnDef?.header;

  if (typeof header !== "function") {
    return sentenceCase(header);
  } else {
    return sentenceCase(column?.columnDef?.accessorKey);
  }
};

const ColumnsDropdown = ({ table, setCloseDropdown, name }: Props) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);

  const [checkboxState, setCheckboxState] = useState<{ [key: string]: boolean }>(() =>
    table.getAllLeafColumns().reduce((state: any, column: any) => {
      state[column.id] = column.getIsVisible();
      return state;
    }, {})
  );
  const [applyChanges, setApplyChanges] = useState(false);

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        if (parsed[columnMap[name]]) {
          const parsedColumns = JSON.parse(parsed[columnMap[name]]);
          if (parsedColumns) {
            setCheckboxState(parsedColumns);
            table.getAllLeafColumns().forEach((column: any) => {
              column.toggleVisibility(parsedColumns[column.id]);
            });
          }
        }
      }
    }
    setStatesFetched(true);
  }, [rawState]);

  const handleCheckboxChange = (columnId: string) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [columnId]: !prevState[columnId],
    }));
    setApplyChanges(true);
  };

  const handleApplyButtonClick = () => {
    table.getAllLeafColumns().forEach((column: any) => {
      column.toggleVisibility(checkboxState[column.id]);
    });
    setApplyChanges(false);
    setCloseDropdown(true);
    if (statesFetched) {
      saveState(columnMap[name], checkboxState);
    }
  };

  return (
    <div className="font-medium">
      <ul className="overflow-y-auto max-h-[calc(100vh-400px)] z-50">
        {table.getAllLeafColumns().map((column: any) => {
          return (
            <li
              title={column.id}
              key={column.id}
              className={`
              flex items-center h-8 px-1
              ${"hover:rounded-full hover:bg-border-hover/40 hover:font-bold"}
            `}
            >
              <input
                {...{
                  type: "checkbox",
                  checked: checkboxState[column.id],
                  onChange: () => handleCheckboxChange(column.id),
                  id: column.id,
                }}
                className="
                  text-widget border border-solid border-border-external rounded-xs w-3 h-3 m-0.5 outline-none appearance-none
                  focus:ring-0 focus:ring-transparent focus:ring-offset-transparent
                  indeterminate:border indeterminate:border-solid indeterminate:border-border-external indeterminate:bg-dot indeterminate:bg-50%
                  indeterminate:hover:border indeterminate:hover:border-solid indeterminate:hover:border-border-external
                  checked:border checked:border-solid checked:border-border-external checked:bg-check
                  checked:hover:border checked:hover:border-solid checked:hover:border-border-external
                  checked:focus:border checked:focus:border-solid checked:focus:border-border-external
                  disabled:opacity-50 cursor-pointer
                  "
              />
              <label
                htmlFor={column.id}
                className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
              >
                {getColumnHeader(column)}
              </label>
            </li>
          );
        })}
      </ul>
      <ApplyButton disabled={!applyChanges} onClick={handleApplyButtonClick} />
    </div>
  );
};

export default ColumnsDropdown;
