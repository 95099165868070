import { AddCostButton, DataQualityButton } from "components/Button";
import {
  IObject,
  IOption,
  DateRangeOption,
  ITimeRange,
  TimeRangeKey,
  IOrgByOption,
  OrgByOptionKey,
  TopPerfCriteriaKey,
  ITopPerfCriteria,
  OrganizeByOptions,
  IDateOption,
  TimeRangeKeyForecast,
} from "types";
import { DemandMetricOptions } from "types";
import { forwardDays, msInDay, rewindDays } from "./date";
import { addMonths, subMonths } from "date-fns";
import { getISODate } from "./helper-ts";
import { SortingState } from "@tanstack/react-table";

export const OVER_OPTIONS: ITimeRange[] = [
  { key: TimeRangeKey.Last24Hours, value: "Last 24 hours" },
  { key: TimeRangeKey.Last7Days, value: "Last 7 days" },
  { key: TimeRangeKey.Last30Days, value: "Last 30 days" },
  { key: TimeRangeKey.Last90Days, value: "Last 90 days" },
  { key: TimeRangeKey.Last365Days, value: "Last 365 days" },
  { key: TimeRangeKey.AllTime, value: "All time" },
];

export const DATE_OPTIONS: IDateOption[] = [
  {
    key: TimeRangeKey.Last24Hours,
    name: "Last 24 hours",
    startDate: getISODate(new Date(new Date().getTime() - msInDay)),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.Last7Days,
    name: "Last 7 days",
    startDate: rewindDays(new Date(), 7),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.Last30Days,
    name: "Last 30 days",
    startDate: rewindDays(new Date(), 30),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.Last90Days,
    name: "Last 90 days",
    startDate: rewindDays(new Date(), 90),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.Last365Days,
    name: "Last 365 days",
    startDate: rewindDays(new Date(), 365),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.AllTime,
    name: "All time",
    startDate: rewindDays(new Date(), 365),
    endDate: getISODate(new Date()),
  },
  {
    key: TimeRangeKey.Custom,
    name: "Custom",
    startDate: getISODate(subMonths(new Date(), 1)),
    endDate: getISODate(addMonths(new Date(), 1)),
  },
];

export const DATE_OPTIONS_FORECAST: IDateOption[] = [
  {
    key: TimeRangeKeyForecast.Next7Days,
    name: "Last 7 to next 7 days",
    startDate: rewindDays(new Date(), 7),
    endDate: forwardDays(new Date(), 7),
  },
  {
    key: TimeRangeKeyForecast.Next30Days,
    name: "Last 30 to next 30 days",
    startDate: rewindDays(new Date(), 30),
    endDate: forwardDays(new Date(), 30),
  },
  {
    key: TimeRangeKeyForecast.Next90Days,
    name: "Last 90 to next 90 days",
    startDate: rewindDays(new Date(), 90),
    endDate: forwardDays(new Date(), 90),
  },
  {
    key: TimeRangeKeyForecast.Last365Days,
    name: "Last 365 to next 365 days",
    startDate: rewindDays(new Date(), 365),
    endDate: forwardDays(new Date(), 365),
  },
  {
    key: TimeRangeKey.Custom,
    name: "Custom",
    startDate: getISODate(subMonths(new Date(), 1)),
    endDate: getISODate(addMonths(new Date(), 1)),
  },
];

export const PLANNING_DATE_OPTIONS: IDateOption[] = [
  {
    key: TimeRangeKey.Last24Hours,
    name: "Next 24 hours",
    startDate: getISODate(new Date()),

    endDate: new Date(new Date().getTime() + msInDay),
  },
  {
    key: TimeRangeKey.Last7Days,
    name: "Next 7 days",
    startDate: getISODate(new Date()),

    endDate: rewindDays(new Date(), -7),
  },
  {
    key: TimeRangeKey.Last30Days,
    name: "Next 30 days",
    startDate: getISODate(new Date()),

    endDate: rewindDays(new Date(), -30),
  },
  {
    key: TimeRangeKey.Last90Days,
    name: "Next 90 days",
    startDate: getISODate(new Date()),

    endDate: rewindDays(new Date(), -90),
  },
  {
    key: TimeRangeKey.Last365Days,
    name: "Next 365 days",
    startDate: getISODate(new Date()),

    endDate: rewindDays(new Date(), -365),
  },
  {
    key: TimeRangeKey.Custom,
    name: "Custom",
    startDate: getISODate(new Date()),
    endDate: rewindDays(new Date(), -365),
  },
];

export const FORECAST_OPTIONS: IObject[] = [
  { key: "1", value: "Next 24 hours" },
  { key: "7", value: "Next 7 days" },
  { key: "30", value: "Next 30 days" },
  { key: "90", value: "Next 90 days" },
  { key: "365", value: "Next 365 days" },
  { key: "0", value: "None" },
];

export const DOWNLOAD_OPTIONS: IObject[] = [
  { key: "csv", value: ".csv" },
  { key: "xls", value: ".xls" },
  { key: "sql", value: ".sql" },
];

export const SHOPIFY_PRODUCT_COLUMNS: any[] = [
  { accessor: "product", Header: "Product" },
  { accessor: "variant", Header: "Variant" },
  { accessor: "image", Header: "Image" },
  { accessor: "description", Header: "Description" },
  { accessor: "supplier", Header: "Supplier or Vendor" },
  { accessor: "status", Header: "Status" },
  { accessor: "tags", Header: "Tags" },
  { accessor: "price", Header: "Price" },
  {
    accessor: "cost",
    Header: "Cost",
    Cell: (props: any) => {
      const {
        row: {
          original: { id, pid },
        },
      } = props;
      return <AddCostButton page="products" product={pid} variant={id} />;
    },
  },
  { accessor: "inventoryQuantity", Header: "Units on Hand" },
  { accessor: "inventoryStatus", Header: "Inventory Status" },
  { accessor: "lowThreshold", Header: "Low Stock Threshold" },
  // { accessor: "variantOptions", Header: "Variant Options" },
];

export const FILTER_PLACEHOLDERS = [
  "Filter & Search",
  "Inventory status",
  "Price",
  "Created",
  "Has image",
  "Brand",
  "Has sales",
  "Has description",
  "Supplier",
  "Units on hand",
  "Weight",
  "Product",
  "Variant",
  "Status",
  "Modified",
  "Location",
];

export const PASSWORD_MESSAGE =
  "Something went wrong setting the password. Please contact us so we can help you set up your account";

export const EMAIL_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const SOCKETURL =
  window.location.hostname === "localhost" ? "ws://localhost:8000" : "wss://app.bucephalus.tech";

export const DASHBOARD_INVENTORY_OPTIONS: IOrgByOption[] = [
  { key: OrgByOptionKey.Variant, value: "By Variant" },
  { key: OrgByOptionKey.Product, value: "By Product" },
  { key: OrgByOptionKey.Category, value: "By Category" },
  { key: OrgByOptionKey.Vendor, value: "By Vendor" },
  { key: OrgByOptionKey.SKU, value: "By SKU" },
  { key: OrgByOptionKey.Collection, value: "By Collection" },
  { key: OrgByOptionKey.Tag, value: "By Tag" },
];

export const DASHBOARD_TOP_CRITERIA_OPTIONS: ITopPerfCriteria[] = [
  { key: TopPerfCriteriaKey.Grossing, value: "Top Grossing", name: "Gross Revenue" },
  {
    key: TopPerfCriteriaKey.PercentRevenue,
    value: "Percent of Revenue",
    name: "Percent of Revenue",
  },
  { key: TopPerfCriteriaKey.RateSales, value: "Rate of Sales", name: "Rate of Sales" },
];

export enum BottomPerfCriteriaKey {
  DaysWithoutSales = "days-wo-sales",
  //InventoryCost = "inventory-cost",
  LowestMargins = "lowest-margins",
}

export interface IBottomPerfCriteria {
  key: BottomPerfCriteriaKey;
  value: string;
  name: string;
}

export const DASHBOARD_BOTTOM_CRITERIA_OPTIONS: IBottomPerfCriteria[] = [
  {
    key: BottomPerfCriteriaKey.DaysWithoutSales,
    value: "Days Without Sales",
    name: "Days Without Sales",
  },
  //{ key: BottomPerfCriteriaKey.InventoryCost, value: "Inventory Cost", name: "Inventory Cost" },
  { key: BottomPerfCriteriaKey.LowestMargins, value: "Lowest Margins", name: "Percent Margin" },
];

export const ORGANIZE_BY: IOption[] = [
  { key: "none", value: "None", name: "None" },
  { key: "shops", value: "Shop", name: "Shop" },
  { key: "categories", value: "Category", name: "Category" },
  { key: "products", value: "Product", name: "Product" },
  { key: "variants", value: "Variant", name: "Variant" },
  //{ key: "vendors", value: "Vendor", name: "Vendor" },
  { key: "locations", value: "Location", name: "Location" },
];

export const ORGANIZE_BY_DEMAND: OrganizeByOptions[] = [
  { key: "shop", value: "Shop" },
  { key: "category", value: "Category" },
  //{ key: "collection", value: "Collection" },
  { key: "product", value: "Product" },
  { key: "variant", value: "Variant" },
  { key: "sku", value: "Sku" },
  { key: "vendor", value: "Vendor" },
  //{ key: "location", value: "Location" },
];
export const ORGANIZE_BY_TOP_PERFORMERS: OrganizeByOptions[] = [
  { key: "product", value: "Product" },
  { key: "vendor", value: "Vendor" },
];
export const DEMAND_METRICS: DemandMetricOptions[] = [
  { key: "revenue", value: "Revenue" },
  { key: "percentOfTotalRevenue", value: "Percent Of Total Revenue" },
  { key: "previousYearSales", value: "Previous Year Sales" },
  { key: "yearToYear", value: "Year To Year" },
  { key: "quantity", value: "Quantity" },
  { key: "previousYearQuantity", value: "Previous Year Quantity" },
];

export const DEMAND_OPTIONS: IObject[] = [
  { key: "Size", value: "Size" },
  { key: "Color", value: "Color" },
];

export const SEASONS: IObject[] = [
  { key: "2023-winter", value: "2023 Winter" },
  { key: "2022-autumn", value: "2022 Autumn" },
  { key: "2022-summer", value: "2022 Summer" },
  { key: "2022-spring", value: "2022 Spring" },
  { key: "2022-winter", value: "2022 Winter" },
  { key: "2021-autumn", value: "2021 Autumn" },
  { key: "2021-summer", value: "2021 Summer" },
  { key: "2021-spring", value: "2021 Spring" },
];

export const PAGE_COUNT: number = 50;

export const INIT_MESSAGES: string[] = [
  "0/5: Fetching products",
  "1/5: Fetching orders",
  "2/5: Fetching locations",
  "3/5: Estimating thresholds",
  "4/5: Generating forecast",
  "5/5: App is ready to use!",
];

export const DATE_PRESETS: DateRangeOption[] = [
  { key: "week", active: true, name: "This Week" },
  { key: "month", active: true, name: "This Month" },
  { key: "quarter", active: true, name: "This Quarter" },
  { key: "year", active: true, name: "This Year" },
  { key: "all", active: true, name: "All" },
];

export const DATE_FREQUENCY: IObject[] = [
  { key: "day", value: "Daily (<90 days)" },
  { key: "week", value: "Weekly" },
  { key: "month", value: "Monthly" },
  { key: "quarter", value: "Quarterly" },
];
export const DATE_FREQUENCY_TIME_SERIES: IOption[] = [
  { key: "day", value: "Daily" },
  { key: "week", value: "Weekly" },
  { key: "month", value: "Monthly" },
  { key: "quarter", value: "Quarterly" },
];

export const SORT_OPTIONS = [
  {
    key: "asc",
    value: "Ascending",
  },
  {
    key: "desc",
    value: "Descending",
  },
  {
    key: "none",
    value: "No Sort",
  },
];

export const ESSENTIAL_REQUESTS: string[] = [
  "/shopify-init-status",
  "/whoami",
  "/permissions",
  "/oldest-sale-date",
];

export const PLACEHOLDER_COLUMNS_PLANNING = [
  {
    accessorKey: "vendor",
    header: "Vendor",
    enableSorting: true,
    columns: [
      {
        accessorKey: "vendor",
        header: "Totals",
      },
    ],
  },
  /*  {
    accessorKey: "vendorLeadTime",
    header: "Vendor Lead Time",
    enableSorting: false,
    columns: [
      {
        accessorKey: "vendorLeadTime",
        header: "",
      },
    ],
  }, */
  {
    accessorKey: "productTitle",
    header: "Product",
    enableSorting: true,
    columns: [
      {
        accessorKey: "productTitle",
        header: "",
      },
    ],
  },
  {
    accessorKey: "variantTitle",
    header: "Variant",
    enableSorting: true,
    columns: [
      {
        accessorKey: "variantTitle",
        header: "",
      },
    ],
  },
  {
    accessorKey: "sku",
    header: "Sku",
    enableSorting: true,
    columns: [
      {
        accessorKey: "sku",
        header: "",
      },
    ],
  },
  {
    accessorKey: "costPerItem",
    header: "Cost Per Item",
    enableSorting: true,
    columns: [
      {
        accessorKey: "costPerItem",
        header: "",
      },
    ],
  },
  {
    accessorKey: "demandOverSellingHorizon",
    header: "Demand Over Selling Horizon",
    enableSorting: true,
    columns: [
      {
        accessorKey: "demandOverSellingHorizon",
        header: 0,
        size: 200,
      },
    ],
    size: 200,
  },
  {
    accessorKey: "onHandInventoryQuantity",
    header: "On Hand Inventory Quantity",
    enableSorting: true,
    columns: [
      {
        accessorKey: "onHandInventoryQuantity",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "lastOrderByDate",
    header: "Last Order By Date",
    enableSorting: true,
    columns: [
      {
        accessorKey: "lastOrderByDate",
        header: "",
      },
    ],
  },
  {
    accessorKey: "recommendedQuantity",
    header: "Recommended Quantity",
    enableSorting: true,
    columns: [
      {
        accessorKey: "recommendedQuantity",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "forecastOrigin",
    header: "Forecast Origin",
    enableSorting: true,
    columns: [
      {
        accessorKey: "forecastOrigin",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "costOfItemsToPurchase",
    header: "Cost of Items to Purchase",
    enableSorting: true,
    columns: [
      {
        accessorKey: "costOfItemsToPurchase",
        header: 0,
      },
    ],
  },
];

// this shouldnt be visible to user (unless there is a bug)
export const PLACEHOLDER_ROW_PLANNING = {
  vendor: "Placeholder Vendor",
  vendorLeadTime: 15,
  productTitle: "Placeholder Product",
  variantTitle: "Placeholder Variant / M",
  sku: "1234567-XYZ-M",
  productId: "9876543210",
  variantId: "55555555555",
  costPerItem: 10.5,
  demandOverSellingHorizon: 20,
  onHandInventoryQuantity: 50,
  lastOrderByDate: "2023-10-10",
  recommendedQuantity: "30",
  costOfItemsToPurchase: 525.0,
};

export const PLACEHOLDER_COLUMNS_DEMAND = [
  {
    accessorKey: "grouping",
    header: "product",
    enableSorting: true,
    columns: [
      {
        accessorKey: "grouping",
        header: "",
      },
    ],
  },
  {
    accessorKey: "metric",
    header: "Period",
    enableSorting: false,
    columns: [
      {
        accessorKey: "metric",
        header: "Metrics",
      },
    ],
  },
  {
    accessorKey: "2023-8-m-historical",
    header: "-",
    enableSorting: true,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-8-m-historical",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2023-9-m-historical",
    header: "-",
    enableSorting: true,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-9-m-historical",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2023-10-m-current",
    header: "-",
    enableSorting: true,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-10-m-current",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2023-10-m-current_forecast",
    header: "-",
    enableSorting: false,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-10-m-current_forecast",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2023-11-m-forecast",
    header: "-",
    enableSorting: false,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-11-m-forecast",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2023-12-m-forecast",
    header: "-",
    enableSorting: false,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2023-12-m-forecast",
        header: 0,
      },
    ],
  },
  {
    accessorKey: "2024-1-m-forecast",
    header: "-",
    enableSorting: false,
    weekRange: [null, null],
    columns: [
      {
        accessorKey: "2024-1-m-forecast",
        header: 0,
      },
    ],
  },
];

//this shouldnt be visible to user (unless there is a bug)
export const PLACEHOLDER_ROW_DEMAND = {
  grouping: "Placeholder Grouping",
  productTitle: "Placeholder Product Title",
  variantTitle: "Placeholder Variant Title",
  sku: "Placeholder SKU",
  productId: "1234567890",
  variantId: "Placeholder Variant ID",
  organizeBy: "Placeholder Organize By",
  metric: "Placeholder Metric",
  "2023-8-m-historical": 1000,
  "2023-9-m-historical": 1500,
  "2023-10-m-current": 500,
  "2023-10-m-current_forecast": 500,
  "2023-11-m-forecast": 1000,
  "2023-12-m-forecast": 250,
  "2024-1-m-forecast": 0,
  preservedGrouping: "Placeholder Preserved Grouping",
};

export const PLACEHOLDER_COLUMNS_INVENTORY = [
  {
    accessorKey: "plot",
    header: "Plot",
    enableSorting: false,
  },
  {
    accessorKey: "productTitle",
    header: "Product",
    enableSorting: true,
    size: 250,
  },
  {
    accessorKey: "variantTitle",
    header: "Variant",
    enableSorting: true,
    size: 250,
  },
  {
    accessorKey: "vendor",
    header: "Vendor",
    enableSorting: true,
  },
  {
    accessorKey: "unitsOnHand",
    header: "Units On Hand",
    enableSorting: true,
  },
  {
    accessorKey: "coverageRate",
    header: "Coverage Rate",
    enableSorting: true,
  },
  {
    accessorKey: "trend",
    header: "Demand Over Trailing Year (Quantity/Day)",
    enableSorting: false,
  },
  {
    accessorKey: "turnOverRatio",
    header: "Turn Over Ratio",
    enableSorting: true,
  },
  {
    accessorKey: "dtoos",
    header: "Days To OOS",
    enableSorting: true,
  },
  {
    accessorKey: "low",
    header: "Safety Stock",
    enableSorting: true,
  },
  {
    accessorKey: "vendorLeadTime",
    header: "Lead Time",
    enableSorting: false,
  },
  {
    accessorKey: "alert",
    header: "Alert",
    enableSorting: true,
  },
];
export const PLACEHOLDER_TOP_PERFORMERS = [
  {
    accessorKey: "rank",
    header: "Rank",
    enableSorting: false,
  },
  {
    accessorKey: "productTitle",
    header: "Product",
    enableSorting: false,
  },

  {
    accessorKey: "revenue",
    header: "Revenue",
    enableSorting: false,
  },
  {
    accessorKey: "shareOfTotalRevenue",
    header: "Revenue Contribution",
    enableSorting: false,
  },

  {
    accessorKey: "slope",
    header: "Trend Over Period (rev/day)",
    enableSorting: false,
  },
  {
    accessorKey: "currentInventory",
    header: "Current Inventory Level",
    enableSorting: false,
  },
  {
    accessorKey: "lastOrderDate",
    header: "Order Restock By",
    enableSorting: false,
  },
];

export const PLACEHOLDER_ROWS_INVENTORY = {
  vendor: "Placeholder",
  pTitle: "Placeholder",
  vTitle: "Placeholder",
  units: "Placeholder",
  low: "1234567890",
  alert: "Placeholder Variant ID",
};
export const PLACEHOLDER_ROW_TOP_PERFORMERS = {
  rank: 1,
  productTitle: "Airotec® Athletic Fit Cotton/Nylon 5-Pocket Pants",
  productId: "7044608032938",
  lastOrderDate: "2023-09-26",
  revenue: 6500.58,
  shareOfTotalRevenue: 6.4,
  currentInventory: "840",
  slope: -1.7,
  increasing: false,
  reChart: "blank",
};

export const PLACEHOLDER_ROW_ALERTS = Array(5).fill({
  shop_id: "example-shop-id",
  name: "Example Alert",
  type: "stock",
  all_selected: true,
  day_of_month: "1",
  days: ["Monday", "Wednesday", "Friday"],
  email_subject: "Low Stock Alert",
  frequency: "daily",
  low_stock_threshold: 10,
  recipients: "example@example.com",
  schedule: "10:00 AM",
  sorting: "asc",
  time_of_day: "morning",
  variant_ids: ["variant-1", "variant-2"],
  active: true,
  created_at: "2023-10-24T10:00:00Z",
  updated_at: "2023-10-24T10:00:00Z",
  variants_Affected: 2,
  timezone: "UTC",
});

export const PLACEHOLDER_COLUMNS_ALERTS = [
  {
    accessorKey: "name",
    header: "Name",
    enableSorting: true,
  },
  {
    accessorKey: "created_at",
    header: "Created",
    enableSorting: true,
  },
  {
    accessorKey: "schedule",
    header: "Schedule",
    enableSorting: true,
  },
  {
    accessorKey: "low_stock_threshold",
    header: "Low Stock Threshold",
    enableSorting: true,
  },
  {
    accessorKey: "variants_Affected",
    header: "Variants Affected",
    enableSorting: false,
  },
  {
    accessorKey: "actions",
    header: "Actions",
    enableSorting: false,
    width: 390,
  },
];
export const PLACEHOLDER_PRODUCTS_COLUMNS = [
  {
    accessorKey: "product",
    header: "PRODUCT",
    enableSorting: false,
  },
  {
    accessorKey: "variant",
    header: "VARIANT",
    enableSorting: false,
  },
  {
    accessorKey: "image",
    header: "IMAGE",
    enableSorting: false,
  },
  {
    accessorKey: "description",
    header: "DESCRIPTION",
    enableSorting: false,
  },
  {
    accessorKey: "supplier",
    header: "VENDOR",
    enableSorting: false,
  },
  {
    accessorKey: "price",
    header: "PRICE",
    enableSorting: false,
  },
  {
    accessorKey: "cost",
    header: "COST",
    enableSorting: false,
  },
  {
    accessorKey: "inventoryStatus",
    header: "INVENTORY STATUS",
    enableSorting: false,
  },
];

export const PLACEHOLDER_ROW_PRODUCTS = Array(15).fill({
  product: "Placeholder Snowboard",
  variant: "Placeholder Variant",
  image: "placeholder_image_url",
  description: "This is a placeholder description for the snowboard.",
  supplier: "placeholder_supplier",
  price: 99.99,
  cost: "placeholder_cost",
  inventoryStatus: "In Stock",
  pid: "1234567890",
  id: "9876543210",
});

export const PLACEHOLDER_ROW_ADD_ALERTS = Array(5).fill({
  select: "Placeholder Data",
  product: "Placeholder Data",
  title: "Placeholder Data",
  vendor: "Placeholder Data",
  units: "Placeholder Data",
  threshold: "Placeholder Data",
  newThreshold: "Placeholder Data",
  dtoos: "Placeholder Data",
  alertName: "Placeholder Data",
});

export const PLACEHOLDER_COLUMNS_ADD_ALERTS = [
  {
    id: "select",
    accessorKey: "select",
    enableSorting: false,
  },
  {
    accessorKey: "product",
    header: "PRODUCT TITLE",
  },
  {
    accessorKey: "title",
    header: "VARIANT TITLE",
  },
  {
    accessorKey: "vendor",
    header: "VENDOR",
  },
  {
    accessorKey: "units",
    header: "ON HAND",
  },
  {
    accessorKey: "threshold",
    header: "CURRENT THRESHOLD",
  },
  {
    accessorKey: "newThreshold",
    header: "NEW THRESHOLD",
  },
  {
    accessorKey: "dtoos",
    header: "DAYS TO OOS",
  },
  {
    accessorKey: "alertName",
    header: "ALERT NAME",
  },
];

export const PLACEHOLDER_COLUMNS_VIEW_ALERT = [
  {
    accessorKey: "p_title",
    header: "Product",
  },
  {
    accessorKey: "v_title",
    header: "Variant",
  },
  {
    accessorKey: "vendor",
    header: "Vendor",
  },
  {
    accessorKey: "inventory_quantity",
    header: "Units On Hand",
  },
  {
    accessorKey: "low_threshold",
    header: "Low Stock Threshold",
  },
];

export const TIME_SERIES_OPTIONS: IObject[] = [
  { key: "revenue", value: "Revenue" },
  { key: "quantity", value: "Quantity" },
];
export const FORECAST_OVERRIDE_OPTIONS: IObject[] = [
  { key: "totalQuantity", value: "Total Quantity" },
  { key: "quantityPerDay", value: "Quantity / Day" },
  { key: "percentage", value: "Percent Change" },
];

export const FORECAST_OVERRIDE_REFERENCE_OPTIONS: IOption[] = [
  { key: "sum", value: "Sum" },
  { key: "average", value: "Average" },
];
export const FORECAST_OVERRIDE_BASE_DATA_OPTIONS: IOption[] = [
  { key: "systemForecast", value: "System Forecast" },
  { key: "ownHistSales", value: "Historical Sales" },
  { key: "manualForecast", value: "Manual Forecast" },
];

export const FORECAST_OVERRIDE_SELECTION_OPTIONS: IOption[] = [
  { key: "same", value: "Same Variant" },
  { key: "other", value: "Other" },
];

export const INVENTORY_VARIANT_DEFAULT_SORT: SortingState = [{ id: "coverageRate", desc: false }];
