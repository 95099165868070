import React, { Suspense, Fragment } from "react";

import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import {
  AppStateProvider,
  AxiosInterceptor,
  DashboardProvider,
  InventoryProvider,
  DemandProvider,
  ProductProvider,
  AlertsProvider,
  InfoModalProvider,
  ModalProvider,
  PlanningProvider,
  RequestProvider,
  TopPerformersProvider,
  ChatProvider,
  StatesProvider,
} from "context";
import LoadingScreen from "../LoadingScreen";
import Toast from "../Toast/toast";
import { allRoutes } from "../../routes";

const logError = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  console.log("LOG ERROR", { error, info });
};

const App = () => {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onError={logError}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <RequestProvider>
        <StatesProvider>
          <AppStateProvider>
            <AxiosInterceptor>
              <ChatProvider>
                <DashboardProvider>
                  <InventoryProvider>
                    <DemandProvider>
                      <ProductProvider>
                        <AlertsProvider>
                          <PlanningProvider>
                            <TopPerformersProvider>
                              <InfoModalProvider>
                                <ModalProvider>
                                  <Suspense fallback={<LoadingScreen />}>
                                    <Routes>
                                      {allRoutes.map((route) => {
                                        const Layout = route.layout || Fragment;
                                        const Component = route.component as React.ElementType;

                                        return (
                                          <Route
                                            key={route.path}
                                            path={route.path}
                                            element={
                                              <Layout>
                                                <Component />
                                              </Layout>
                                            }
                                          />
                                        );
                                      })}
                                    </Routes>
                                    <Toast />
                                  </Suspense>
                                </ModalProvider>
                              </InfoModalProvider>
                            </TopPerformersProvider>
                          </PlanningProvider>
                        </AlertsProvider>
                      </ProductProvider>
                    </DemandProvider>
                  </InventoryProvider>
                </DashboardProvider>
              </ChatProvider>
            </AxiosInterceptor>
          </AppStateProvider>
        </StatesProvider>
      </RequestProvider>
    </ErrorBoundary>
  );
};

export default App;

// looks like the error will still be reported as uncaught, in the console buth that's not true!
// https://stackoverflow.com/questions/71938248/how-to-catch-uncaught-errors-in-react-errorboundary

const fallbackRender = ({ error, resetErrorBoundary }: any) => {
  if (error?.response?.status === 401) {
    window.location.replace("/login");
    return null;
  } else {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <div
        style={{
          background: "linear-gradient(93.5deg, #391672 -64.74%, #8D6BD7 163.91%)",
        }}
        className="items-center justify-center h-screen flex fixed w-screen h-screen"
      >
        <div role="alert" className="flex flex-col items-center justify-center max-w-xl h-full">
          <div className="rounded-[10px] p-8 flex flex-col justify-center shadow-dashboard border border-solid  bg-base-inverted">
            <div className="flex items-center gap-3">
              <p className="font-mono text-2xl">🤔</p>
              <p className="font-mono-bold text-md">Uh oh, there was an error.</p>
            </div>
            <div className="flex flex-col gap-3 mt-6">
              <p className="font-mono text-2xl">{error?.response?.status || error}</p>
              <pre>{errorMessages(error)}</pre>
              <p className="font-mono">
                If this seems wrong, please let us know in the chat, or if you can&apos;t see the
                chat
                <a
                  className="underline cursor-pointer transition-all hover:text-purple-base"
                  onClick={() => {
                    window.open(
                      "https://calendly.com/bucephalus/30min",
                      "_blank",
                      "noopener, noreferrer"
                    );
                  }}
                >
                  schedule a call
                </a>
                .
              </p>
              <div className="flex gap-3 my-3">
                <button
                  className=" p-2.5 bg-base-text rounded-xl text-base-inverted w-fit"
                  onClick={() => {
                    resetErrorBoundary();
                    window.location.replace("/");
                  }}
                >
                  Return Home
                </button>
                <button
                  className=" p-2.5 bg-base-text rounded-xl text-base-inverted w-fit"
                  onClick={() => {
                    resetErrorBoundary();
                    window.location.replace("/login");
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// I'm getting error.error = status code
// OR
// error?.response?.status = status code
// why

const errorMessages = (error: any) => {
  if (error?.response?.status === 403) {
    return "Sorry, you don't have permissions to go here.";
  } else if (error?.response?.status === 404 || error === 404) {
    return "This page doesn't exist!";
  } else {
    // 500 ish
    return "Sorry, we are having technical difficulties, check back in a few minutes";
  }
};
