import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IObject,
  IDays,
  IFilterPlus,
  IDemandFilter2,
  DemandMetricOptions,
  OrganizeByOptions,
  FrequencyOptionKeys,
  IDateOption,
  AsyncStatus,
  IOption,
} from "types";
import {
  SEASONS,
  DEMAND_OPTIONS,
  DATE_OPTIONS_FORECAST,
  DEMAND_METRICS,
  DATE_OPTIONS,
  TIME_SERIES_OPTIONS,
} from "utils/constants";
import { SortingState } from "@tanstack/react-table";
import { StatesContext } from "./States";
import { processAndSet, processAndSetTime, processTime, saveState } from "utils/helper-ts";

interface IDemandContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  top: React.RefObject<HTMLDivElement>;
  current: React.RefObject<HTMLDivElement>;
  metrics: DemandMetricOptions[];
  updateMetrics: React.Dispatch<React.SetStateAction<DemandMetricOptions[]>>;
  organizeBy: OrganizeByOptions;
  updateOrganizeBy: React.Dispatch<React.SetStateAction<OrganizeByOptions>>;
  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
  frequency: FrequencyOptionKeys;
  updateFrequency: React.Dispatch<React.SetStateAction<FrequencyOptionKeys>>;
  dateRange: IDateOption;
  updateDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;
  showChart: boolean;
  updateShowChart: React.Dispatch<React.SetStateAction<boolean>>;
  showClearStates: boolean;
  clearStates: () => void;
  timeSeriesType: IOption;
  updateTimeSeriesType: React.Dispatch<React.SetStateAction<IOption>>;
  editedCells: any;
  updateEditedCells: (editedCells: any) => void;
  revertCells: boolean;
  updateRevertCells: React.Dispatch<React.SetStateAction<boolean>>;
  //demand suite
  selColDSuite: any[];
  updateSelColDSuite: (columns: any[]) => void;
  organizeByDSuite: IObject;
  updateOrganizeByDSuite: (organizeBy: IObject) => void;
  demandSuiteFilter: IFilterPlus[];
  updateDemandSuiteFilter: (items: any) => void;
  fDemandSuiteProducts: string[];
  updateFDemandSuiteProducts: (products: string[]) => void;
  fDemandSuiteSKUs: string[];
  updateFDemandSuiteSKUs: (SKUs: string[]) => void;
  fDemandSuiteSelectedVariants: string[];
  updateFDemandSuiteSelectedVariants: (fSelectedVariants: string[]) => void;
  fDemandSuiteSelectedCategories: string[];
  updateFDemandSuiteSelectedCategories: (fSelectedCategories: string[]) => void;
  showForecastNotification: boolean;
  updateShowForecastNotification: (showForecastNotification: boolean) => void;
  isDemandSuiteInitialized: boolean;
  updateIsDemandSuiteInitialized: (isDemandSuiteInitialized: boolean) => void;
}

export const DemandContext = React.createContext<IDemandContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  sorting: [],
  updateSorting: () => {},
  top: React.createRef<HTMLDivElement>(),
  current: React.createRef<HTMLDivElement>(),
  metrics: [],
  updateMetrics: () => {},
  organizeBy: { key: "product", value: "Product" },
  updateOrganizeBy: () => {},
  loading: AsyncStatus.Loading,
  updateLoading: () => {},
  frequency: "month",
  updateFrequency: () => {},
  dateRange: DATE_OPTIONS_FORECAST[1],
  updateDateRange: () => {},
  showChart: true,
  updateShowChart: () => {},
  showClearStates: false,
  clearStates: () => {},
  timeSeriesType: TIME_SERIES_OPTIONS[0],
  updateTimeSeriesType: () => {},
  editedCells: [],
  updateEditedCells: () => {},
  revertCells: true,
  updateRevertCells: () => {},
  //demand suite
  selColDSuite: [],
  updateSelColDSuite: () => {},
  organizeByDSuite: { key: "products", value: "Product" },
  updateOrganizeByDSuite: () => {},
  demandSuiteFilter: [{ key: "more", value: "Filter & Search", name: "Filter & Search" }],
  updateDemandSuiteFilter: () => {},
  fDemandSuiteProducts: [],
  updateFDemandSuiteProducts: () => {},
  fDemandSuiteSKUs: [],
  updateFDemandSuiteSKUs: () => {},
  fDemandSuiteSelectedVariants: [],
  updateFDemandSuiteSelectedVariants: () => {},
  fDemandSuiteSelectedCategories: [],
  updateFDemandSuiteSelectedCategories: () => {},
  showForecastNotification: true,
  updateShowForecastNotification: () => {},
  isDemandSuiteInitialized: false,
  updateIsDemandSuiteInitialized: () => {},
});

interface IDemandStateContextProps {
  children: React.ReactNode;
}

export const DemandProvider = (props: IDemandStateContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);
  // default table options
  const DEFAULT_OPTIONS: {
    frequency: FrequencyOptionKeys;
    startDate: Date;
    endDate: Date;
    showChart: boolean;
  } = {
    frequency: "month",
    startDate: DATE_OPTIONS_FORECAST[2].startDate,
    endDate: DATE_OPTIONS_FORECAST[2].endDate,
    showChart: true,
  };

  const currentDate = new Date();
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-m-current`,
      desc: true,
    },
  ]);
  const top = useRef<HTMLDivElement>(null);
  const current = useRef<HTMLDivElement>(null);
  const [metrics, setMetrics] = useState<DemandMetricOptions[]>([
    DEMAND_METRICS[0],
    DEMAND_METRICS[1],
  ]);
  const [organizeBy, updateOrganizeBy] = useState<OrganizeByOptions>({
    key: "product",
    value: "Product",
  });
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [frequency, setFrequency] = useState<FrequencyOptionKeys>(DEFAULT_OPTIONS.frequency);
  const [dateRange, setDateRange] = useState(DATE_OPTIONS_FORECAST[2]);
  const [showChart, setShowChart] = useState(DEFAULT_OPTIONS.showChart);
  const [timeSeriesType, setTimeSeriesType] = useState(TIME_SERIES_OPTIONS[0]);
  const [showClearStates, setShowClearStates] = useState(false);
  const [editedCells, setEditedCells] = useState([]);
  const [revertCells, setRevertCells] = useState(false);

  //demand suite
  const [selColDSuite, setSelColDSuite] = useState<any[]>([]);
  const [organizeByDSuite, setOrganizeByDSuite] = useState<IObject>({
    key: "products",
    value: "Product",
  });
  const [demandSuiteFilter, setDemandSuiteFilter] = useState<IFilterPlus[]>([
    { key: "more", value: "Filter & Search", name: "Filter & Search" },
  ]);
  const [fDemandSuiteProducts, setFDemandSuiteProducts] = useState<string[]>([]);
  const [fDemandSuiteSKUs, setFDemandSuiteSKUs] = useState<string[]>([]);
  const [fDemandSuiteSelectedVariants, setFDemandSuiteSelectedVariants] = useState<string[]>([]);
  const [fDemandSuiteSelectedCategories, setFDemandSuiteSelectedCategories] = useState<string[]>(
    []
  );
  const [isDemandSuiteInitialized, setIsDemandSuiteInitialized] = useState(false);
  const [showForecastNotification, setShowForecastNotification] = useState(true);

  useEffect(() => {
    setShowClearStates(
      frequency !== DEFAULT_OPTIONS.frequency ||
        dateRange.startDate.getTime() !== DATE_OPTIONS_FORECAST[2].startDate.getTime() ||
        dateRange.endDate.getTime() !== DATE_OPTIONS_FORECAST[2].endDate.getTime() ||
        showChart !== DEFAULT_OPTIONS.showChart
    );
  }, [frequency, dateRange, showChart]);

  function clearStates() {
    setFrequency(DEFAULT_OPTIONS.frequency);
    setDateRange(DATE_OPTIONS_FORECAST[2]);
    setShowChart(DEFAULT_OPTIONS.showChart);
  }

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        processAndSetTime(parsed, "demand_time", setDateRange, DATE_OPTIONS_FORECAST);
        processAndSet(parsed, "demand_filter", setDemandSuiteFilter);
        processAndSet(parsed, "demand_frequency", setFrequency);
        processAndSet(parsed, "demand_chart", setShowChart, true);
        processAndSet(parsed, "demand_type", setTimeSeriesType);
        processAndSet(parsed, "demand_sorting", setSorting);
        processAndSet(parsed, "demand_org", updateOrganizeBy);
        processAndSet(parsed, "demand_metric", setMetrics);
      }
      setStatesFetched(true);
    }
  }, [rawState]);

  useEffect(() => {
    if (statesFetched) {
      saveState("demand_filter", demandSuiteFilter);
    }
  }, [JSON.stringify(demandSuiteFilter), statesFetched]);

  return (
    <DemandContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        sorting,
        updateSorting: setSorting,
        top,
        current,
        metrics,
        updateMetrics: setMetrics,
        organizeBy,
        updateOrganizeBy,
        loading,
        updateLoading: setLoading,
        frequency,
        updateFrequency: setFrequency,
        dateRange,
        updateDateRange: setDateRange,
        showChart,
        updateShowChart: setShowChart,
        showClearStates,
        clearStates,
        timeSeriesType,
        updateTimeSeriesType: setTimeSeriesType,
        editedCells,
        updateEditedCells: setEditedCells,
        revertCells,
        updateRevertCells: setRevertCells,
        //demand suite
        selColDSuite,
        updateSelColDSuite: setSelColDSuite,
        organizeByDSuite,
        updateOrganizeByDSuite: setOrganizeByDSuite,
        demandSuiteFilter,
        updateDemandSuiteFilter: setDemandSuiteFilter,
        fDemandSuiteProducts,
        updateFDemandSuiteProducts: setFDemandSuiteProducts,
        fDemandSuiteSKUs,
        updateFDemandSuiteSKUs: setFDemandSuiteSKUs,
        fDemandSuiteSelectedVariants,
        updateFDemandSuiteSelectedVariants: setFDemandSuiteSelectedVariants,
        fDemandSuiteSelectedCategories,
        updateFDemandSuiteSelectedCategories: setFDemandSuiteSelectedCategories,
        showForecastNotification,
        updateShowForecastNotification: setShowForecastNotification,
        isDemandSuiteInitialized,
        updateIsDemandSuiteInitialized: setIsDemandSuiteInitialized,
      }}
    >
      {props.children}
    </DemandContext.Provider>
  );
};
