import React, { useContext, useEffect, useState } from "react";
import client from "api";
import { AsyncStatus, DropdownItem, TimeRangeKey } from "types";
import { cloudfrontCacheBreak, errorHandler, getISODate } from "utils/helper-ts";
import { IUser } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { DATE_OPTIONS } from "utils/constants";
import { addDays, differenceInHours } from "date-fns";
import { StatesContext } from "./States";

interface IAppStateContext {
  currUser: string;
  updateCurrUser: (currUser: string) => void;

  //user
  user: IUser;
  updateUser: (user: IUser) => void;
  permissions: string[];
  updatePermissions: (permissions: string[]) => void;
  firstLogin: boolean;
  updateFirstLogin: (firstLogin: boolean) => void;

  products: DropdownItem[];
  updateProducts: (products: DropdownItem[]) => void;
  getProducts: () => void;
  productsLoading: AsyncStatus;
  updateProductsLoading: (loading: AsyncStatus) => void;
  SKUs: DropdownItem[];
  updateSKUs: (products: DropdownItem[]) => void;
  getSKUs: () => void;
  SKUsLoading: AsyncStatus;
  updateSKUsLoading: (loading: AsyncStatus) => void;
  fetchVariants: boolean;
  updateFetchVariants: (fetchVariants: boolean) => void;

  categories: DropdownItem[];
  updateCategories: (categories: DropdownItem[]) => void;
  getCategories: () => void;
  categoriesLoading: AsyncStatus;
  updateCategoriesLoading: (loading: AsyncStatus) => void;

  //other
  initStatus: number;
  updateInitStatus: (initStatus: number) => void;
  forecastStatus: string;
  updateForecastStatus: (initStatus: string) => void;
  showAddCost: boolean;
  updateShowAddCost: (showAddCost: boolean) => void;
  oldestSaleDate: Date;
  updateOldestSaleDate: (oldestSaleDate: Date) => void;
  collapseSidebar: boolean;
  updateCollapseSidebar: (collapse: boolean) => void;
  allTimeCalculated: boolean;
  updateAllTimeCalculated: (collapse: boolean) => void;

  selectedRow: string;
  updateSelectedRow: React.Dispatch<React.SetStateAction<string>>;
}

export const AppStateContext = React.createContext<IAppStateContext>({
  currUser: "",
  updateCurrUser: () => {},

  //user
  user: {} as IUser,
  updateUser: () => {},
  permissions: [],
  updatePermissions: () => [],
  firstLogin: false,
  updateFirstLogin: () => false,

  //products and variants used in filters
  products: [],
  getProducts: () => {},
  updateProducts: () => {},
  productsLoading: AsyncStatus.Loading,
  updateProductsLoading: () => {},
  fetchVariants: true,
  updateFetchVariants: () => {},

  categories: [],
  updateCategories: () => {},
  getCategories: () => {},
  categoriesLoading: AsyncStatus.Loading,
  updateCategoriesLoading: () => {},

  SKUs: [],
  updateSKUs: () => {},
  getSKUs: () => {},
  SKUsLoading: AsyncStatus.Loading,
  updateSKUsLoading: () => {},

  //other
  initStatus: 5,
  updateInitStatus: () => {},
  forecastStatus: "",
  updateForecastStatus: () => {},
  showAddCost: true,
  updateShowAddCost: () => {},
  oldestSaleDate: new Date(),
  updateOldestSaleDate: () => {},
  collapseSidebar: true,
  updateCollapseSidebar: () => {},
  allTimeCalculated: true,
  updateAllTimeCalculated: () => {},
  selectedRow: "",
  updateSelectedRow: () => {},
});

interface IAppStateContextProps {
  children: React.ReactNode;
}

export const AppStateProvider = (props: IAppStateContextProps) => {
  const { rawState, updateRawState } = useContext(StatesContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [currUser, setCurrUser] = useState<string>("");

  //user
  const [user, setUser] = useState<IUser>({} as IUser);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [firstLogin, setFirstLogin] = useState<boolean>(false);

  //products and variants used in filters
  const [products, setProducts] = useState<DropdownItem[]>([]);
  const [productsLoading, setProductsLoading] = useState<AsyncStatus>(AsyncStatus.Loading);

  const [SKUs, setSKUs] = useState<DropdownItem[]>([]);
  const [SKUsLoading, setSKUsLoading] = useState<AsyncStatus>(AsyncStatus.Loading);

  const [fetchVariants, setFetchVariants] = useState(false);

  const [categories, setCategories] = useState<DropdownItem[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState<AsyncStatus>(AsyncStatus.Loading);

  //other
  const [initStatus, setInitStatus] = useState(0);
  const [forecastStatus, setForecastStatus] = useState("init");
  const [oldestSaleDate, setOldestSaleDate] = useState<Date>(new Date());
  const [showAddCost, setShowAddCost] = useState<boolean>(true);

  const [collapseSidebar, setCollapseSidebar] = useState(true);
  const [allTimeCalculated, setAllTimeCalculated] = useState(false);
  const [selectedRow, updateSelectedRow] = useState("");

  const getCategories = async () => {
    try {
      setCategoriesLoading(AsyncStatus.Loading);
      const res = await client.get(`/categories`);

      setCategories(res.data.data);
      if (res.data.data.length > 0) {
        setCategoriesLoading(AsyncStatus.Loaded);
      } else {
        setCategoriesLoading(AsyncStatus.Empty);
      }
    } catch (err) {
      setCategoriesLoading(AsyncStatus.Failed);
      console.error(err);
    }
  };
  const getSKUs = async () => {
    try {
      setSKUsLoading(AsyncStatus.Loading);
      const res = await client.get(`/get-SKU-modal-SKUslist`);

      setSKUs(
        res.data.data
          .filter(({ sku }: any) => sku)
          .map(({ sku }: any) => {
            return { title: sku, id: sku };
          })
      );
      if (res.data.data.length > 0) {
        setSKUsLoading(AsyncStatus.Loaded);
      } else {
        setSKUsLoading(AsyncStatus.Empty);
      }
    } catch (err) {
      setSKUsLoading(AsyncStatus.Failed);
      console.error(err);
    }
  };

  const getProducts = async () => {
    try {
      setProductsLoading(AsyncStatus.Loading);
      const res = await client.get(`/products`);
      setProducts(res.data.data);
      if (res.data.data.length > 0) {
        setProductsLoading(AsyncStatus.Loaded);
      } else {
        setProductsLoading(AsyncStatus.Empty);
      }
    } catch (err) {
      setProductsLoading(AsyncStatus.Failed);
      console.error(err);
    }
  };

  useEffect(() => {
    async function getUser() {
      try {
        const result = await client.get("/whoami");
        setUser(result.data);
      } catch (err: any) {
        console.log(err);
      }
    }

    async function getSubscriptions() {
      try {
        const subscriptions = await client.get("/get-subscriptions" + cloudfrontCacheBreak());
        if (subscriptions?.data?.data.length > 0) {
          const activeSubRes = subscriptions.data.data.filter(
            (sub: any) => sub.status === "ACTIVE"
          )[0];

          if (activeSubRes) {
            const hourDiff = differenceInHours(
              addDays(new Date(activeSubRes.trial_start), activeSubRes.trial_days).getTime(),
              new Date().getTime()
            );

            // console.log("DifferenceInHours", hourDiff);

            if (hourDiff < 0) {
              console.log("the trial is already ended. . . ");
            } else if (hourDiff < 72) {
              console.log("the trial is about to end (72 hours)");
            } else {
              console.log("During trial ");
            }
          } else {
            navigate("/payment-information");
          }
          // console.log("activeSubRes", activeSubRes);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
    async function getInitStatus() {
      try {
        const initStatus = await client.get("/shopify-init-status" + cloudfrontCacheBreak());
        setInitStatus(initStatus.data.status);

        if (initStatus.data.status !== 5) {
          navigate("/data-pull");
        }
      } catch (err: any) {
        console.error(err);
      }
    }

    async function getForecastStatus() {
      try {
        const res = await client.get(`/forecast-init-status` + cloudfrontCacheBreak());
        setForecastStatus(res?.data?.status);
      } catch (err: any) {
        console.log(err);
      }
    }

    const getStates = async () => {
      try {
        const states = await client.get("/get-states");
        updateRawState(JSON.stringify(states.data));
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    if (permissions.length > 0) {
      fetchAllTimeDates();
      getUser();
      getSubscriptions();
      getInitStatus();
      getForecastStatus();
      getStates();
    }
  }, [permissions]);

  useEffect(() => {
    const checkLock = async () => {
      if (localStorage.getItem("changePassword") && location.pathname !== "/set-password") {
        navigate("/set-password");
      }
      if (
        localStorage.getItem("lockPaymentInformation") &&
        location.pathname !== "/payment-information"
      ) {
        try {
          const subscriptions = await client.get("/get-subscriptions" + cloudfrontCacheBreak());

          const activeSubRes = subscriptions.data.data.some((sub: any) => sub.status === "ACTIVE");
          if (!activeSubRes) {
            navigate("/payment-information");
          } else {
            localStorage.removeItem("lockPaymentInformation");
            localStorage.setItem("lockDataPull", "true");
            navigate("/data-pull");
          }
        } catch (error) {
          console.error("Error fetching subscriptions:", error);
        }
      }
      if (localStorage.getItem("lockDataPull") && location.pathname !== "/data-pull") {
        navigate("/data-pull");
      }
      if (localStorage.getItem("lockWalkthrough") && location.pathname !== "/walkthrough") {
        navigate("/walkthrough");
      }
    };

    checkLock();
  }, [location]);

  async function fetchAllTimeDates() {
    try {
      const oldestSaleRes = await client.get("/oldest-sale-date");
      const furthestForecast = await client.get("/furthest-forecast-date");

      const allTimeOption = DATE_OPTIONS.find((option) => option.key === TimeRangeKey.AllTime);
      if (allTimeOption) {
        allTimeOption.startDate = new Date(oldestSaleRes.data.data);
        allTimeOption.endDate = new Date(furthestForecast.data.data);
      }
    } catch (error) {
      console.error(error);
    }
    setAllTimeCalculated(true);
  }

  return (
    <AppStateContext.Provider
      value={{
        currUser,
        updateCurrUser: setCurrUser,

        //user
        user,
        updateUser: setUser,
        permissions,
        updatePermissions: setPermissions,
        firstLogin,
        updateFirstLogin: setFirstLogin,

        //products and variants used in filters
        products,
        getProducts: getProducts,
        updateProducts: setProducts,
        productsLoading,
        updateProductsLoading: setProductsLoading,
        fetchVariants,
        updateFetchVariants: setFetchVariants,

        categories,
        updateCategories: setCategories,
        getCategories,
        categoriesLoading,
        updateCategoriesLoading: setCategoriesLoading,

        SKUs,
        updateSKUs: setSKUs,
        getSKUs,
        SKUsLoading,
        updateSKUsLoading: setSKUsLoading,

        //other
        showAddCost,
        updateShowAddCost: setShowAddCost,
        initStatus,
        updateInitStatus: setInitStatus,
        forecastStatus,
        updateForecastStatus: setForecastStatus,
        oldestSaleDate,
        updateOldestSaleDate: setOldestSaleDate,
        collapseSidebar,
        updateCollapseSidebar: setCollapseSidebar,
        allTimeCalculated,
        updateAllTimeCalculated: setAllTimeCalculated,
        selectedRow,
        updateSelectedRow: updateSelectedRow,
      }}
    >
      {props.children}
    </AppStateContext.Provider>
  );
};
