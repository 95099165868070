import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, className, ...props }, ref) => {
    return (
      <div>
        <input
          ref={ref}
          className={`rounded-xl border-border-internal px-4 py-3 bg-widget text-sm ${className}`}
          {...props}
        />
        {errorMessage && <p className="pl-1 text-xs text-red-600">{errorMessage}</p>}
      </div>
    );
  }
);
TextInput.displayName = "TextInput";

export default TextInput;
