import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { RequestContext } from "context";
import { useContext, useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { ESSENTIAL_REQUESTS } from "utils/constants";
import client from "../api";

type PendingRequest = {
  config: AxiosRequestConfig;
  cancel: (message?: string) => void;
};

const pendingRequests: PendingRequest[] = [];

function AxiosInterceptor({ children, error }: any) {
  const { updatePendingRequests } = useContext(RequestContext);

  const handleError = useErrorHandler(error);

  useEffect(() => {
    const requestInterceptor = (config: AxiosRequestConfig) => {
      updatePendingRequests((prevCount) => prevCount + 1);

      const existingRequest = pendingRequests.find(
        (pendingRequest) => pendingRequest.config.url === config.url
      );

      if (existingRequest) {
        // this conditional is a holdover until there is a more intelligent way to allow to requests to the same endpoint
        if (
          existingRequest.config.url !== "/get-variant-modal-demand-chart" &&
          existingRequest.config.url !== "/edit-variant-forecast-direct" &&
          existingRequest.config.url !== "/edit-all-vendor-data" &&
          existingRequest.config.url !== "/edit-global-safety-stock"
        ) {
          existingRequest.cancel("");
        }
      }

      config.cancelToken = new axios.CancelToken((cancel) => {
        pendingRequests.push({ config, cancel });
      });

      return config;
    };

    const resInterceptor = (response: any) => {
      updatePendingRequests((prevCount) => prevCount - 1);

      // Handle the response here if needed
      const index = pendingRequests.findIndex(
        (request) => request.config.url === response.config.url
      );
      if (index !== -1) {
        pendingRequests.splice(index, 1);
      }

      return response;
    };

    const errInterceptor = (error: AxiosError) => {
      updatePendingRequests((prevCount) => prevCount - 1);
      // Handle request errors here
      if (
        error?.response?.status !== 400 &&
        error?.response?.status !== 402 &&
        error?.response?.status !== 429 &&
        !(error?.code === "ECONNABORTED" && error?.message.includes("timeout")) &&
        !(error?.code === "ERR_CANCELED")
      ) {
        handleError(error);
      }
      return Promise.reject(error);
    };

    const requestInterceptorId = client.interceptors.request.use(requestInterceptor);
    const responseInterceptorId = client.interceptors.response.use(resInterceptor, errInterceptor);

    return () => {
      // Eject the interceptors when the component unmounts
      client.interceptors.request.eject(requestInterceptorId);
      client.interceptors.response.eject(responseInterceptorId);
    };
  }, []);

  return children;
}

export const cancelAllPendingRequests = (message?: string) => {
  let essentialCount = 0;
  pendingRequests.forEach((pendingRequest) => {
    if (!ESSENTIAL_REQUESTS.includes(pendingRequest.config.url || "")) {
      pendingRequest.cancel(message);
    } else {
      essentialCount++;
    }
  });

  pendingRequests.length = essentialCount;
};

export const getAllPendingRequests = () => {
  return pendingRequests;
};

export { AxiosInterceptor };
