import React, { useRef, useEffect, useState, useContext } from "react";
import useDebounce from "hooks/useDebounce";
import Slide from "components/Slider";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import BreadCrumb from "components/Breadcrumb";
import { getHighlightedText } from "utils/helper";
import client from "api";
import { formatNumber, validateNumber } from "utils/helper-ts";
import { handleScrollTo, formatCurrency, errorHandler } from "utils/helper-ts";
import { AppStateContext, ModalContext } from "context";
import { AsyncStatus, type IVariant } from "types";
import type { IModalProduct, IModalProductAll } from "types/ModalTypes";
import { IUser } from "context/types";
import { ModalRow, Row } from "components/Organization/Row";
import { MetricColumn } from "components/Dashboard/MetricHelpers";
import { Card, SimpleCard, CardDescription, EditableCard } from "components/Dashboard/Card";
import {
  DetailModalWrapper,
  DetailModalContent,
  DetailModalSidebar,
  DetailModalDemandTimeseries,
  DetailModalPerformanceKPI,
  DetailModalChildPerformanceChart,
  DetailModalItemPillListDisplay,
} from ".";
import { SubmitHandler } from "react-hook-form";

export const ProductModal = ({
  close,
  visible,
  product,
  setEdited,
  resetId,
}: {
  close: () => void;
  visible: boolean;
  product: string;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { user } = useContext(AppStateContext);
  const { OpenModal } = useContext(ModalContext);
  const [productData, setProductData] = useState<IModalProductAll>({
    productTitle: "",
    description: "",
    tags: [],
    variantTitle: [],
    skus: [],
    priceRange: [null, null],
    unitCostRange: [null, null],
    marginRange: [null, null],
    images: [],
    vendor: "",
    numberOfVariants: 0,
    inventoryStatus: {
      healthy: 0,
      outOfStock: 0,
      lowStock: 0,
      all: 0,
    },
    totalUnitsOnHand: "-",
    category: "",
  });
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [variants, setVariants] = useState<IVariant[]>([]);
  const [productId, setProductId] = useState("");
  const [products, setProducts] = useState<IModalProduct[]>([]);
  const [productsPage, setProductsPage] = useState(0);
  const [productKeyword, setProductKeyword] = useState<string>("");
  const productSearchString = useDebounce(productKeyword, 1000);
  const top = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (productId) {
      getProduct(productId);
      getVariants([productId], "");
    }
  }, [productId]);

  useEffect(() => {
    setProductId(product);
  }, [product]);

  useEffect(() => {
    if (productId) {
      getProducts(productId, productsPage < 0 ? 0 : productsPage, productSearchString);
    }
  }, [productSearchString, productId]);

  useEffect(() => {
    if (productId) {
      setProductsPage(0);
    }
  }, [productSearchString]);

  const getProduct = async (pid: string) => {
    if (pid) {
      try {
        setLoading(AsyncStatus.Loading);
        const res = await client.get(`/get-product-modal-info`, { params: { productId: pid } });
        setProductData(res.data.data);
        setLoading(AsyncStatus.Loaded);
      } catch (error) {
        setLoading(AsyncStatus.Failed);
        console.error(error);
      }
    }
  };

  const getVariants = async (productIds: string[], searchString: string) => {
    try {
      const res = await client.post(`/variants`, {
        productId: productIds,
        searchString,
      });
      setVariants(res.data.data[0].variants);
    } catch (err) {
      console.error(err);
    }
  };

  const getProducts = async (productId: string, page: number, searchInfo: string) => {
    try {
      const res = await client.get(`/get-product-modal-productslist`, {
        params: {
          productId,
          page,
          searchInfo,
        },
      });
      if (page === 0) {
        setProducts(res.data.data);
      } else {
        setProducts((oldData) => {
          const oldIdArray = oldData.map((oItem: IModalProduct) => oItem.pid);
          return [
            ...oldData,
            ...(res.data.data.filter((item: IModalProduct) => !oldIdArray.includes(item.pid)) ||
              []),
          ];
        });
      }
      setProductsPage(res.data.page || 0);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeProduct = (id: string) => {
    setProductId(id);
    handleScrollTo(top);
  };

  const handleGoToCategory = () => {
    close();
    OpenModal("category", undefined, productData.category);
  };

  const handleGoToVariant = (vid?: string) => {
    close();
    OpenModal("variant", undefined, undefined, undefined, variants[0].id);
  };

  const handleClickBreadCrumb = (id: string) => {
    if (id === "category") {
      handleGoToCategory();
    }
  };

  return (
    productData && (
      <DetailModalWrapper
        organizeBy="product"
        title={
          <ProductTitle
            data={productData}
            loading={loading}
            handleClickBreadCrumb={handleClickBreadCrumb}
          />
        }
        loading={loading}
        visible={visible}
        resetId={resetId}
        handleGoToParent={productData.category ? handleGoToCategory : undefined}
        numberOfChildren={variants.length}
        handleGoToChild={variants.length > 0 ? handleGoToVariant : undefined}
        close={close}
      >
        <DetailModalSidebar
          itemCount={products.length}
          keyword={productKeyword}
          type="product"
          setKeyword={setProductKeyword}
        >
          <ProductList
            products={products}
            handleChangeProduct={handleChangeProduct}
            productKeyword={productKeyword}
            setProductKeyword={setProductKeyword}
          />
        </DetailModalSidebar>
        <DetailModalContent>
          <ModalRow columns={4}>
            <div>
              <DetailModalPerformanceKPI id={productId} type="product" />
              <KeyQualities loading={loading} productId={productId} setEdited={setEdited} />
            </div>
            <div className="col-span-3">
              <DetailModalDemandTimeseries id={productId} organizeBy="product" />
            </div>
          </ModalRow>
          <ModalRow columns={4}>
            <ProductInfo user={user} productData={productData} loading={loading} />
            <div className="col-span-3">
              <Slide images={productData.images} loading={loading} />
            </div>
          </ModalRow>
          <Row>
            <DetailModalChildPerformanceChart
              id={productId}
              organizeBy="variant"
              title={productData.productTitle}
              parentType="product"
              childType="variant"
              goToChild={handleGoToVariant}
            />
          </Row>
        </DetailModalContent>
      </DetailModalWrapper>
    )
  );
};

const ProductTitle = ({
  data,
  loading,
  handleClickBreadCrumb,
}: {
  data: IModalProductAll; // this will need to be more flexible
  handleClickBreadCrumb: (id: string) => void;
  loading: AsyncStatus;
}) => {
  return (
    <>
      <>{data.productTitle}</>
      <BreadCrumb
        data={[
          { name: data.category, type: "category" },
          { name: data.productTitle, type: "product" },
        ]}
        onClick={handleClickBreadCrumb}
        loading={loading}
      />
    </>
  );
};

export default ProductModal;

const ProductInfo = ({
  productData,
  user,
  loading,
}: {
  productData: IModalProductAll;
  user: IUser;
  loading: AsyncStatus;
}) => {
  let { priceRange, unitCostRange, marginRange } = productData;

  let price =
    priceRange?.[0] === priceRange?.[1]
      ? formatCurrency(priceRange?.[0], user.currency)
      : formatCurrency(priceRange?.[0], user.currency) +
        " - " +
        formatCurrency(priceRange?.[1], user.currency);

  let cost =
    unitCostRange?.[0] === unitCostRange?.[1]
      ? formatCurrency(unitCostRange?.[0], user.currency)
      : formatCurrency(unitCostRange?.[0], user.currency) +
        " - " +
        formatCurrency(unitCostRange?.[1], user.currency);

  let margins =
    marginRange?.[0] === marginRange?.[1]
      ? formatNumber(parseInt(marginRange?.[0] || "0"), "percent")
      : formatNumber(parseInt(marginRange?.[0] || "0"), "percent") +
        " - " +
        formatNumber(parseInt(marginRange?.[1] || "0"), "percent");

  return (
    <div>
      <AreaTopBar areaName="Details" />
      <MetricColumn>
        <CardDescription name={"Description"} value={productData.description} loading={loading} />
        <Card name={"Vendor"} value={productData.vendor} loading={loading} />
        <Card name={`Price`} value={price} loading={loading} />
        {parseInt(unitCostRange[0] || "0") !== 0 ? (
          <>
            <Card name={"Cost"} value={cost} loading={loading} />
            <Card name={"Margins"} value={margins} loading={loading} />
          </>
        ) : null}
        <Card
          name={"Total Units on Hand:"}
          value={productData.totalUnitsOnHand}
          loading={loading}
        />
        <SimpleCard title="Tags" loading={loading}>
          <DetailModalItemPillListDisplay
            label="Tags"
            items={productData.tags || []}
            loading={loading}
          />
        </SimpleCard>
        <SimpleCard title="SKUs" loading={loading}>
          <DetailModalItemPillListDisplay
            label="SKUs"
            items={productData.skus || []}
            loading={loading}
          />
        </SimpleCard>
      </MetricColumn>
    </div>
  );
};

export const ProductList = ({
  products,
  handleChangeProduct,
  productKeyword,
  setProductKeyword,
}: {
  products: IModalProduct[];
  handleChangeProduct: (productId: string) => void;
  productKeyword: string;
  setProductKeyword: (value: string) => void;
}) => {
  return (
    <ul className="overflow-y-auto max-h-full w-full flex flex-col gap-1 px-3">
      {products.map((product) => (
        <li
          key={product.pid}
          className={`rounded-lg p-2 hover:bg-black/5 cursor-pointer transition-all`}
          onClick={() => {
            handleChangeProduct(product.pid);
          }}
        >
          <p className="overflow-hidden text-sm text-ellipsis" title={product.title}>
            {getHighlightedText(product.title, productKeyword)}
          </p>
        </li>
      ))}
    </ul>
  );
};

const KeyQualities = ({
  productId,
  loading,
  setEdited,
}: {
  productId: string;
  loading: AsyncStatus;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useContext(AppStateContext);
  const [leadTime, setLeadTime] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [unitsToOrder, setUnitsToOrder] = useState("");
  const [refresh, setRefresh] = useState(false);
  const handleSubmit = async (
    type: string,
    data: any,
    setter: React.Dispatch<React.SetStateAction<string>>,
    overrideNullOnly?: any
  ) => {
    await client.post(`/edit-all-vendor-data`, {
      filter: [{ product: [productId] }],
      value: parseInt(data.editedValue),
      type,
      overrideNullOnly: overrideNullOnly,
    });
    setEdited?.(true);
    setter(data.editedValue);
    setRefresh((prev) => !prev);
    return true;
  };

  const getKeyQualities = async (productId: string) => {
    try {
      const res = await client.get(`/get-vendor-modal-key-qualities/?productId=${productId}`);
      setLeadTime(res.data.data.averageLeadTime || "-");
      setUnitsToOrder(res.data.data.averageUnitsToOrder || "-");
      setShippingCost(res.data.data.averageShippingCost || "-");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (productId) {
      getKeyQualities(productId);
    }
  }, [productId, refresh]);

  const onSubmitLeadTime: SubmitHandler<any> = async (data, overrideNullOnly) =>
    handleSubmit("vendorLeadTime", data, setLeadTime, overrideNullOnly);
  const onSubmitShippingCost: SubmitHandler<any> = async (data) =>
    handleSubmit("shippingCost", data, setShippingCost);
  const onSubmitUnitsToOrder: SubmitHandler<any> = async (data) =>
    handleSubmit("unitsToOrder", data, setUnitsToOrder);

  return (
    <div>
      <AreaTopBar areaName="Key Qualities"></AreaTopBar>
      <MetricColumn>
        <EditableCard
          name={"Average Lead Time"}
          value={leadTime}
          loading={loading}
          onSubmit={onSubmitLeadTime}
          validator={validateNumber}
          unit={leadTime === "-" ? undefined : "Days"}
        />
      </MetricColumn>
    </div>
  );
};
