import { ReactNode } from "react";

export const GlassWidget = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col w-fit gap-8 bg-white/10 rounded-xl border border-white/10">
    {children}
  </div>
);

export const GlassModal = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col gap-8 text-center bg-white/10 p-8 m-4 max-h-[90%] w-[70%] rounded-xl border border-white/10">
    {children}
  </div>
);
