import { useRef, useEffect, MutableRefObject, InputHTMLAttributes } from "react";
import type { CheckboxState } from "types";

interface Props {
  name: string;
  checked?: CheckboxState;
  onChange?: (state: CheckboxState, name: string) => void;
}
type ThreeStateCheckboxProps = Props &
  Omit<InputHTMLAttributes<HTMLInputElement>, "checked" | "onChange">;

const updateInput = (ref: MutableRefObject<any>, checked: CheckboxState) => {
  const input = ref.current;
  if (input) {
    input.checked = checked;
    input.indeterminate = checked == null;
  }
};

const ThreeStateCheckbox = ({
  name,
  checked = false,
  onChange,
  ...props
}: ThreeStateCheckboxProps) => {
  const inputRef = useRef(null);
  const checkedRef = useRef(checked);

  useEffect(() => {
    checkedRef.current = checked;
    updateInput(inputRef, checked);
  }, [checked]);

  const handleClick = () => {
    switch (checkedRef.current) {
      case true:
        checkedRef.current = false;
        break;
      case false:
        checkedRef.current = true;
        break;
      default: // null
        checkedRef.current = true;
        break;
    }
    updateInput(inputRef, checkedRef.current);
    if (onChange) {
      onChange(checkedRef.current, name);
    }
  };
  return (
    <input
      ref={inputRef}
      type="checkbox"
      name={name}
      onClick={handleClick}
      className="
        text-widget border border-solid border-border-external rounded-xs w-3 h-3 m-0.5 outline-none appearance-none
        focus:ring-0 focus:ring-transparent focus:ring-offset-transparent
        indeterminate:border indeterminate:border-solid indeterminate:border-border-external indeterminate:bg-dot indeterminate:bg-50%
        indeterminate:hover:border indeterminate:hover:border-solid indeterminate:hover:border-border-external
        checked:border checked:border-solid checked:border-border-external checked:bg-check
        checked:hover:border checked:hover:border-solid checked:hover:border-border-external
        checked:focus:border checked:focus:border-solid checked:focus:border-border-external
        disabled:opacity-50
      "
      {...props}
    />
  );
};

export default ThreeStateCheckbox;
