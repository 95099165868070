import {
  CellWrapper,
  CurrencyCell,
  QuantityCell,
  PercentCell,
  EditableCell,
  PercentPill,
} from "components/Table";
import { styleList, updateEditedCellsData } from "utils/helper-ts";
import { DemandContext } from "context";
import { useContext } from "react";
// we'll start with the simple string-based chooser for the special case of the demand page
import { DemandMetric } from "types";

// this is where I want borders to be controlled for rows, but they don't show up so they are at the cell level which is aweful
export const rowStyler = (rowType: DemandMetric) => {
  const rowBaseStyle = "relative";
  switch (rowType) {
    case "revenue":
      return styleList([rowBaseStyle]);
    case "percentOfTotalRevenue":
      return styleList([rowBaseStyle]);
    case "yearToYear":
      return styleList([rowBaseStyle]);
    case "previousYearSales":
      return styleList([rowBaseStyle]);
    default:
      return styleList([rowBaseStyle]);
  }
};

// column chooses first, then row
// this could happen at the component level, but I'm doing nested switches for now
// this may be named wrong,

export const historicalDemandMetric = (
  cell: any,
  metric: string,
  accessorKey: string,
  currency: string
) => {
  switch (metric) {
    case "revenue":
      return <CurrencyCell cell={cell} currency={currency} />;
    case "percentOfTotalRevenue":
      return <PercentCell cell={cell} />;
    case "yearToYear":
      return <PercentCell cell={cell} />;
    case "previousYearSales":
      return <CurrencyCell cell={cell} currency={currency} />;
    case "quantity":
      return <QuantityCell cell={cell} noTooltip={true} />;
    case "previousYearQuantity":
      return <QuantityCell cell={cell} />;
    default:
      return (
        <CellWrapper cell={cell}>
          <>-</>
        </CellWrapper>
      );
  }
};
export const currentDemandMetric = (
  cell: any,
  metric: string,
  accessorKey: string,
  currency: string
) => {
  const currentPeriodStyles = "border-r-base-text";
  switch (metric) {
    case "revenue":
      return <CurrencyCell cell={cell} currency={currency} wrapperStyle={currentPeriodStyles} />;
    case "percentOfTotalRevenue":
      return <PercentCell cell={cell} wrapperStyle={currentPeriodStyles} />;
    case "yearToYear":
      return <PercentCell cell={cell} wrapperStyle={styleList([currentPeriodStyles])} />;
    case "previousYearSales":
      return <CurrencyCell cell={cell} currency={currency} wrapperStyle={currentPeriodStyles} />;
    case "quantity":
      return <QuantityCell cell={cell} wrapperStyle={currentPeriodStyles} noTooltip={true} />;
    case "previousYearQuantity":
      return <QuantityCell cell={cell} wrapperStyle={currentPeriodStyles} />;
    default:
      return (
        <CellWrapper cell={cell}>
          <>-</>
        </CellWrapper>
      );
  }
};

export const forecastDemandMetric = (
  cell: any,
  metric: string,
  accessorKey: string,
  currency: string,
  editable: boolean
) => {
  const { updateEditedCells, revertCells } = useContext(DemandContext);
  const forecastPeriodStyles = "text-purple-bright";
  const percentage = cell.row.original[accessorKey + "-p"];
  switch (metric) {
    case "revenue":
      return <CurrencyCell cell={cell} currency={currency} wrapperStyle={forecastPeriodStyles} />;
    case "percentOfTotalRevenue":
      return <PercentCell cell={cell} wrapperStyle={forecastPeriodStyles} />;
    case "yearToYear":
      return <PercentCell cell={cell} wrapperStyle={styleList([forecastPeriodStyles])} />;
    case "previousYearSales":
      return <CurrencyCell cell={cell} currency={currency} wrapperStyle={forecastPeriodStyles} />;
    case "quantity":
      return editable ? (
        <CellWrapper cell={cell} styles={forecastPeriodStyles}>
          <EditableCell
            value={cell.getValue()}
            row={cell.row}
            numberOnly
            updateMyData={(value: any, original: any) => {
              updateEditedCellsData(updateEditedCells, cell, value, original);
            }}
            revert={revertCells}
            showBorder={true}
            percentage={percentage}
          />
        </CellWrapper>
      ) : (
        <QuantityCell cell={cell} wrapperStyle={forecastPeriodStyles} percentage={percentage} />
      );
    case "previousYearQuantity":
      return <QuantityCell cell={cell} wrapperStyle={forecastPeriodStyles} />;
    default:
      return (
        <CellWrapper cell={cell}>
          <>-</>
        </CellWrapper>
      );
  }
};
