import React from "react";
import "./style.css";
import { NoReturnData } from "components/Dashboard/DataWarning";
import { AsyncStatus } from "types";

// add children for consistent sizing

const Loading = ({ children }: { children?: React.ReactNode }) => (
  <div className="flex items-center justify-center transition-opacity ease-in duration-700 opacity-70 bg-gray-200 h-full rounded-lg animate-pulse">
    {children}
    {/* <div className="lds-hourglass"></div> */}
  </div>
);

export const ChartLoadingWrapper = ({
  data,
  loading,
  children,
}: {
  loading: AsyncStatus;
  data?: any;
  children?: React.ReactNode;
}) => {
  if (loading === AsyncStatus.Loading) {
    return <Loading />;
  } else if (loading === AsyncStatus.Empty) {
    return <NoReturnData />;
  } else {
    return <>{children}</>;
  }
};

export const UnitLoadingWrapper = ({
  loading,
  children,
}: {
  loading: AsyncStatus;
  children: React.ReactNode;
}) => {
  if (loading === AsyncStatus.Loading) {
    return (
      <Loading>
        <span className="opacity-0">empty stuff</span>
      </Loading>
    );
  } else {
    return <>{children}</>;
  }
};

export default Loading;

export const ImageLoadingWrapper = ({
  loading,
  children,
}: {
  loading: AsyncStatus;
  children?: React.ReactNode;
}) => {
  if (loading === AsyncStatus.Loading) {
    return (
      <div className="flex gap-3">
        <div className="h-[400px] w-[300px] rounded-md ">
          <Loading />
        </div>
        <div className="h-[400px] w-[300px] rounded-md ">
          <Loading />
        </div>
        <div className="h-[400px] w-[300px] rounded-md ">
          <Loading />
        </div>
        <div className="h-[400px] w-[300px] rounded-md ">
          <Loading />
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export const FlexibleLoadingWrapper = ({
  loading,
  children,
  styles,
}: {
  loading: AsyncStatus;
  children: React.ReactNode;
  styles?: string;
}) => {
  if (loading === AsyncStatus.Loading) {
    return (
      <div className="flex gap-3">
        <div className="h-[200px] w-full rounded-md ">
          <Loading />
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export const LoadingWrapper = ({
  loading,
  children,
  styles,
}: {
  loading: AsyncStatus;
  children: React.ReactNode;
  styles?: string;
}) => {
  switch (loading) {
    case AsyncStatus.Loading:
      return (
        <div className={styles}>
          <Loading />
        </div>
      );
    case AsyncStatus.Loaded:
      return <>{children}</>;
    case AsyncStatus.Empty:
      return <div className={styles}>No results</div>;
    case AsyncStatus.Failed:
      return <div className={styles}>Failed</div>;
    default:
      console.error("Unknown loading status:", loading);
      return <div className={styles}>Unexpected status</div>;
  }
};
