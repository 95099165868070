import client from "api";
import Chat from "components/Chat";
import React, { useState } from "react";
import { AsyncStatus } from "types";

interface IChatContext {
  OpenChat: (prompt: string) => void;
  prompt: string;
  updatePrompt: React.Dispatch<React.SetStateAction<string>>;
  summary: string;
  updateSummary: React.Dispatch<React.SetStateAction<string>>;
  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
  visible: boolean;
  updateVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatContext = React.createContext<IChatContext>({
  OpenChat: () => {},
  prompt: "",
  updatePrompt: () => {},
  summary: "",
  updateSummary: () => {},
  loading: AsyncStatus.Empty,
  updateLoading: () => {},
  visible: false,
  updateVisible: () => {},
});

interface IChatContextProps {
  children: React.ReactNode;
}

export const ChatProvider = (props: IChatContextProps) => {
  const [visible, setVisible] = useState(false);

  const [prompt, setPrompt] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(AsyncStatus.Loading);

  async function AISummary(prompt: string) {
    try {
      setLoading(AsyncStatus.Loading);
      const res = await client.post(`/summarize`, { prompt });
      setSummary(res.data);

      setLoading(AsyncStatus.Loaded);
    } catch (err: any) {
      console.log(err);
    }
  }

  const OpenChat = (newPrompt: string) => {
    setVisible(true);

    if (prompt != newPrompt) {
      setPrompt(newPrompt);
      AISummary(newPrompt);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        OpenChat,
        prompt,
        updatePrompt: setPrompt,
        summary,
        updateSummary: setSummary,
        loading,
        updateLoading: setLoading,
        visible,
        updateVisible: setVisible,
      }}
    >
      {props.children}
      {visible && <Chat></Chat>}
    </ChatContext.Provider>
  );
};
