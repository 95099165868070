export const viewAlertColumns = [
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Product",
    accessor: "p_title",
  },
  {
    Header: "Variant Title",
    accessor: "v_title",
  },
  {
    Header: "Vendor",
    accessor: "vendor",
  },
  {
    Header: "On Hand",
    accessor: "inventory_quantity",
  },
  {
    Header: "LOW STOCK THRESHOLD",
    accessor: "low_threshold",
    disableSortBy: true,
  },
];
