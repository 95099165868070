import React, { useRef, useEffect, useState, ReactNode, useContext } from "react";
import { ModalRow } from "components/Organization/Row";
import useDebounce from "hooks/useDebounce";
import BreadCrumb from "components/Breadcrumb";
import { getHighlightedText, formatNumber } from "utils/helper";
import { handleScrollTo, validateNumber } from "utils/helper-ts";
import { WidgetRow, Row } from "components/Organization/Row";
import { Category } from "types/ModalTypes";
import urlencode from "urlencode";
import { AppStateContext, ModalContext } from "context";
import client from "api";
import { AsyncStatus, IDateOption } from "types";
import { MetricColumn } from "components/Dashboard/MetricHelpers";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import { Card, EditableCard, SimpleCard } from "components/Dashboard/Card";
import {
  DetailModalWrapper,
  DetailModalContent,
  DetailModalSidebar,
  DetailModalDemandTimeseries,
  DetailModalPerformanceKPI,
  DetailModalChildPerformanceChart,
  DetailModalItemPillListDisplay,
} from ".";
import { SubmitHandler } from "react-hook-form";

export const CategoryModal = ({
  close,
  visible,
  category,
  resetId,
  dateRange,
}: {
  close: () => void;
  visible: boolean;
  category: string;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
  dateRange: IDateOption;
}) => {
  const { OpenModal } = useContext(ModalContext);

  const [categoryData, setCategoryData] = useState<Category>({
    categoryTitle: "",
    vendors: [],
    images: [],
    numberOfProducts: 0,
    numberOfVariants: 0,
    totalUnitsOnHand: 0,
    inventoryStatus: {
      healthy: 0,
      outOfStock: 0,
      lowStock: 0,
      all: 0,
    },
  });
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [products, setProducts] = useState<{ id: string }[]>([]);
  const [categories, setCategories] = useState<{ category: string }[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryPage, setCategoryPage] = useState(0);
  const [categoryKeyword, setCategoryKeyword] = useState<string>("");
  const categorySearchString = useDebounce(categoryKeyword, 1000);
  const top = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (categoryId) {
      getCategory(categoryId);
      getProducts(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    setCategoryId(category);
  }, [category]);

  useEffect(() => {
    getCategories(categoryPage < 0 ? 0 : categoryPage, categorySearchString);
  }, [categorySearchString]);

  const getCategory = async (category: string) => {
    if (category) {
      try {
        setLoading(AsyncStatus.Loading);
        const res = await client.get(`/get-category-modal-info`, {
          params: { categoryTitle: category },
        });
        setCategoryData(res.data.data);
        setLoading(AsyncStatus.Loaded);
      } catch (err) {
        console.error(err);
        setLoading(AsyncStatus.Failed);
      }
    }
  };

  const getCategories = async (page: number, searchInfo: string) => {
    try {
      setLoading(AsyncStatus.Loading);
      const res = await client.get(`/get-category-modal-categorylist`, {
        params: { page, searchInfo },
      });
      if (page === 0) {
        setCategories(res.data.data || []);
      } else {
        setCategories((oldData) => {
          const oldIdArray = oldData.map((oItem) => oItem.category);
          return [
            ...oldData,
            ...(res.data.data.filter(
              (item: { category: string }) => !oldIdArray.includes(item.category)
            ) || []),
          ];
        });
      }
      setLoading(AsyncStatus.Loaded);
      setCategoryPage(res.data.page || 0);
    } catch (err) {
      console.error(err);
      setLoading(AsyncStatus.Failed);
    }
  };

  const getProducts = async (category: string) => {
    try {
      const res = await client.get(`/products/${urlencode(category)}`);
      setProducts(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleGoToProduct = (productId?: string) => {
    close();
    if (productId) {
      OpenModal("product", undefined, undefined, productId);
    } else {
      OpenModal("product", undefined, undefined, products[0]?.id);
    }
  };

  const handleChangeCategory = (id: string) => {
    handleScrollTo(top);
    setCategoryId(id);
  };

  return (
    <DetailModalWrapper
      organizeBy="category"
      title={<CategoryTitle categoryTitle={categoryId} loading={loading} />}
      loading={loading}
      visible={visible}
      resetId={resetId}
      numberOfChildren={products.length}
      handleGoToChild={products[0]?.id ? handleGoToProduct : undefined}
      close={close}
    >
      <DetailModalSidebar
        itemCount={categories.length}
        keyword={categoryKeyword}
        type="category"
        setKeyword={setCategoryKeyword}
      >
        <CategoryList
          categories={categories}
          handleChangeCategory={handleChangeCategory}
          categoryKeyword={categoryKeyword}
        />
      </DetailModalSidebar>
      <DetailModalContent>
        <ModalRow columns={4}>
          <div className="col-span-1">
            <DetailModalPerformanceKPI id={categoryId} type="category" />
            <KeyQualities loading={loading} category={categoryId} />
          </div>

          <div className="col-span-3">
            <DetailModalDemandTimeseries id={categoryId} organizeBy="category" />
          </div>
        </ModalRow>
        <Row>
          <CategoryInfo categoryData={categoryData} loading={loading} />
        </Row>
        <Row>
          <DetailModalChildPerformanceChart
            id={categoryId}
            title={categoryId}
            parentType="category"
            organizeBy="product"
            childType="product"
            goToChild={handleGoToProduct}
          />
        </Row>
      </DetailModalContent>
    </DetailModalWrapper>
  );
};

const CategoryTitle = ({
  categoryTitle,
  loading,
}: // handleClickBreadCrumb
{
  categoryTitle: string;
  // handleClickBreadCrumb: (id: string) => void;
  loading: AsyncStatus;
}) => {
  return (
    <>
      <>{categoryTitle}</>
      <BreadCrumb
        data={[{ name: categoryTitle, type: "category" }]}
        onClick={() => null}
        loading={loading}
      />
    </>
  );
};

export default CategoryModal;

export const CategoryList = ({
  categories,
  handleChangeCategory,
  categoryKeyword,
}: {
  categories: { category: string }[];
  handleChangeCategory: (productId: string) => void;
  categoryKeyword: string;
}) => {
  return (
    <ul className="overflow-y-auto max-h-full flex flex-col gap-1 px-3">
      {categories.map(({ category }) => (
        <li
          key={category}
          className={`rounded-lg p-3 hover:bg-black/5 cursor-pointer transition-all`}
          onClick={() => {
            handleChangeCategory(category);
          }}
        >
          <p className="overflow-hidden text-sm text-ellipsis" title={category}>
            {getHighlightedText(category, categoryKeyword)}
          </p>
        </li>
      ))}
    </ul>
  );
};

const CategoryInfo = ({
  categoryData,
  loading,
}: {
  categoryData: Category;
  loading: AsyncStatus;
}) => {
  return (
    <div>
      <AreaTopBar areaName="Details"></AreaTopBar>
      <MetricColumn>
        <Card
          name="Number of Products"
          value={formatNumber(categoryData.numberOfProducts)}
          loading={loading}
        />
        <Card
          name="Number of Variants"
          value={formatNumber(categoryData.numberOfVariants)}
          loading={loading}
        />
        <SimpleCard title="Tags" loading={loading}>
          <DetailModalItemPillListDisplay
            label="Tags"
            items={categoryData.vendors || []}
            loading={loading}
          />
        </SimpleCard>
      </MetricColumn>
    </div>
  );
};

const KeyQualities = ({ category, loading }: { category: string; loading: AsyncStatus }) => {
  const { user } = useContext(AppStateContext);
  const [leadTime, setLeadTime] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [unitsToOrder, setUnitsToOrder] = useState("");
  const [refresh, setRefresh] = useState(false);

  const handleSubmit = async (
    type: string,
    data: any,
    setter: React.Dispatch<React.SetStateAction<string>>,
    overrideNullOnly?: any
  ) => {
    await client.post(`/edit-all-vendor-data`, {
      filter: [{ category: [category] }],
      value: parseInt(data.editedValue),
      type,
      overrideNullOnly,
    });

    setter(data.editedValue);
    setRefresh((prev) => !prev);
    return true;
  };

  const getKeyQualities = async (category: string) => {
    try {
      const res = await client.get(`/get-vendor-modal-key-qualities/?categoryId=${category}`);
      setLeadTime(res.data.data.averageLeadTime || "-");
      setUnitsToOrder(res.data.data.averageUnitsToOrder || "-");
      setShippingCost(res.data.data.averageShippingCost || "-");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (category) {
      getKeyQualities(category);
    }
  }, [category, refresh]);

  const onSubmitLeadTime: SubmitHandler<any> = async (data, overrideNullOnly) =>
    handleSubmit("vendorLeadTime", data, setLeadTime, overrideNullOnly);
  const onSubmitShippingCost: SubmitHandler<any> = async (data) =>
    handleSubmit("shippingCost", data, setShippingCost);
  const onSubmitUnitsToOrder: SubmitHandler<any> = async (data) =>
    handleSubmit("unitsToOrder", data, setUnitsToOrder);

  return (
    <div>
      <AreaTopBar areaName="Key Qualities"></AreaTopBar>
      <MetricColumn>
        <EditableCard
          name={"Average Lead Time"}
          value={leadTime}
          loading={loading}
          onSubmit={onSubmitLeadTime}
          validator={validateNumber}
          unit={leadTime === "-" ? undefined : "Days"}
        />
      </MetricColumn>
    </div>
  );
};
