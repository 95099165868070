import React, { useState, useRef, Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckboxState, IObject, IOption } from "types";
import useOutsideClick from "hooks/useOutsideClick";
import { ReactComponent as TriangleUp } from "assets/images/triangle_up.svg";
import { ReactComponent as Options } from "assets/images/options.svg";
import { SortDirection } from "@tanstack/react-table";
import ThreeStateCheckbox from "components/Checkbox";
import { ReactComponent as Up } from "assets/images/dropdown_up.svg";
import { DEMAND_METRICS } from "utils/constants";

type DropdownType = "sort" | "dropdown" | "metric";

export const RadioDropdown = ({
  options,
  option,
  onSelect,
  position,
  disabled,
  type,
  children,
}: {
  options: IOption[];
  option: IOption;
  onSelect: ({ key, value }: IOption) => void;
  type: DropdownType;
  position?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [positionStyle, setPositionStyle] = useState({
    top: "initial",
    left: "initial",
    right: "initial",
  });
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childrenRef.current) {
      const rect = childrenRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      if (rect.right + 300 > windowWidth) {
        setPositionStyle((prevStyle) => ({
          ...prevStyle,
          left: "auto",
          right: "0",
        }));
      } else {
        setPositionStyle((prevStyle) => ({
          ...prevStyle,
          left: `0`,
          right: "initial",
        }));
      }
    }
  }, [dropdown]);

  useOutsideClick(childrenRef, () => {
    setDropdown(false);
  });

  const transitionClasses = {
    enter: "transform transition duration-[150ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[150ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  const chooseButton = (option: string) => {
    switch (type) {
      case "metric":
        return <OptionButton />;
      case "dropdown":
        return <DropdownButton />;
      case "sort":
        return <SortButton sortState={(option as SortDirection) || undefined} />;
      default:
        return <SortButton sortState={(option as SortDirection) || undefined} />;
    }
  };

  return (
    <div className=" relative inline-block font-mono text-xs text-[#6D6392]" ref={childrenRef}>
      <div>
        <button
          disabled={disabled}
          onClick={() => {
            !disabled && setDropdown(!dropdown);
          }}
          type="button"
          className={`inline-flex w-full justify-center items-center rounded-md
            ${disabled ? "opacity-50" : "hover:bg-darken-1"}
            transition-all
            focus:bg-highlight-gentle focus:outline-none focus:ring-1 focus:ring-purple-bright focus:ring-offset-1 focus:ring-offset-gray-100`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {children}
          {chooseButton(option.key)}
        </button>
      </div>
      <Transition show={dropdown} as={Fragment} {...transitionClasses}>
        <div
          className={`absolute z-40 mt-2 rounded-md bg-purple-hover shadow-xl focus:outline-none border border-border-internal overflow-auto max-h-96`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          style={positionStyle}
        >
          {options.map((item) => {
            return (
              <div
                key={item.key}
                className={`text-white truncate text-xs flex items-center cursor-pointer hover:bg-lighten-3 h-6 px-2 transition-all ${
                  option.key === item.key ? "bg-lighten-2 font-mono-bold" : ""
                }`}
                onClick={() => {
                  onSelect(item);
                  setDropdown(false);
                }}
              >
                {item.value}
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
};

export const MultiselectDropdown = ({
  options,
  onSelect,
  selected,
  position,
  disabled,
  children,
}: {
  options: IOption[];
  onSelect: (selection: IOption[]) => void;
  selected: IOption[];
  position?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const childrenRef = useRef(null);

  const [selectAll, setSelectAll] = useState(false);
  const [selections, setSelections] = useState<string[]>(
    selected.map(({ value }: IObject) => value)
  );

  const handleChangeSelection = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...selections, name];
    } else if (state === false) {
      newSelection = selections.filter((item) => item !== name);
    }
    setSelections(newSelection);
  };

  const handleApply = () => {
    if (selections.length > 0) {
      const filteredMetrics = DEMAND_METRICS.filter((metric) => selections.includes(metric.value));
      onSelect(filteredMetrics);
    }
  };

  const handleToggle = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelections(options.map(({ value }: IObject) => value));
    } else {
      setSelections([]);
    }
  };

  useOutsideClick(childrenRef, () => {
    setDropdown(false);
  });

  const transitionClasses = {
    enter: "transform transition duration-[150ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[150ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  return (
    <div
      className="relative inline-block text-left uppercase text-xs text-[#6D6392]"
      ref={childrenRef}
    >
      <button
        disabled={disabled}
        onClick={() => {
          !disabled && setDropdown(!dropdown);
        }}
        type="button"
        className={`inline-flex w-full justify-center items-center rounded-md p-1
            ${disabled ? "opacity-50" : "hover:bg-darken-1"}
            transition-all
            focus:bg-highlight-gentle focus:outline-none focus:ring-1 focus:ring-purple-bright focus:ring-offset-1 focus:ring-offset-gray-100`}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {children}
        <DropdownButton />
      </button>

      <Transition show={dropdown} as={Fragment} {...transitionClasses}>
        <div
          className={`absolute left-0 z-40 mt-2 rounded-md bg-purple-hover shadow-xl overflow-hidden focus:outline-none border border-border-internal text-white p-2`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div>
            <label className="inline-flex relative items-center mb-4 cursor-pointer">
              <input type="checkbox" onChange={handleToggle} className="sr-only peer" />
              <div
                className={`w-6 h-3 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-border-external after:content-['']
              after:absolute after:bg-border-hover after:border
              after:rounded-full after:h-3 after:w-3 after:transition-all bg-white`}
              ></div>
              <span className="text-ellipsis whitespace-nowrap ml-2">
                {selectAll ? "Select all" : "Deselect all"}
              </span>
            </label>
          </div>
          <ul>
            {options.map(({ key, value }: IObject) => (
              <li
                key={key}
                className="flex items-center h-8 hover:rounded-full hover:bg-border-hover/40 hover:font-bold px-2"
              >
                <ThreeStateCheckbox
                  id={key}
                  name={value}
                  checked={selections.findIndex((v) => v === value) !== -1}
                  onChange={handleChangeSelection}
                />
                <label
                  htmlFor={key}
                  className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {value}
                </label>
              </li>
            ))}
          </ul>
          <button
            className={`flex items-center justify-center mt-1 h-8 w-full px-3 rounded-full ${
              selections.length > 0
                ? "bg-border-hover/40 hover:font-bold cursor-pointer"
                : "bg-border-hover/20 text-neutral-500 cursor-default"
            }`}
            disabled={selections.length === 0}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </Transition>
    </div>
  );
};

const SortButton = ({ sortState }: { sortState?: SortDirection }) => {
  switch (sortState) {
    case "asc":
      return (
        <div className="flex flex-col items-center p-2 rounded-md">
          <TriangleUp className={`mb-0.5 text-purple-bright`} />
          <TriangleUp className={` origin-center rotate-180 text-base-light`} />
        </div>
      );
    case "desc":
      return (
        <div className="flex flex-col items-center p-2 rounded-md">
          <TriangleUp className={`mb-0.5 text-base-light`} />
          <TriangleUp className={` origin-center rotate-180 text-purple-bright`} />
        </div>
      );
    default:
      return (
        <div className="flex flex-col items-center p-2 rounded-md">
          <TriangleUp className={`mb-0.5 text-base-light`} />
          <TriangleUp className={` origin-center rotate-180 text-base-light`} />
        </div>
      );
  }
};

const OptionButton = () => {
  return (
    <div className="flex flex-col items-center p-2 rounded-md">
      <Options className="h-[15px] w-[10px]" />
    </div>
  );
};

const DropdownButton = () => {
  return (
    <div className="flex flex-col items-center p-2 rounded-md">
      <Up className="h-[15px] w-[10px] rotate-180" />
    </div>
  );
};
