import React, { useRef, useEffect, useState, ReactNode, useContext } from "react";
import { ModalRow } from "components/Organization/Row";
import useDebounce from "hooks/useDebounce";
import BreadCrumb from "components/Breadcrumb";
import { getHighlightedText, formatNumber } from "utils/helper";
import { handleScrollTo, validateNumber } from "utils/helper-ts";
import { Row } from "components/Organization/Row";
import { Vendor } from "types/ModalTypes";
import { AppStateContext, ModalContext } from "context";
import client from "api";
import { AsyncStatus } from "types";
import { MetricColumn } from "components/Dashboard/MetricHelpers";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import { Card, EditableCard, SimpleCard } from "components/Dashboard/Card";
import {
  DetailModalWrapper,
  DetailModalContent,
  DetailModalSidebar,
  DetailModalDemandTimeseries,
  DetailModalPerformanceKPI,
  DetailModalChildPerformanceChart,
  DetailModalItemPillListDisplay,
} from ".";
import { SubmitHandler, useForm } from "react-hook-form";

export const VendorModal = ({
  close,
  visible,
  vendor,
  resetId,
  setEdited,
}: {
  close: () => void;
  visible: boolean;
  vendor: string;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { OpenModal } = useContext(ModalContext);

  const [vendorData, setVendorData] = useState<Vendor>({
    vendor: "",
    tags: [],
    skus: [],
    numberOfProducts: 0,
    numberOfVariants: 0,
  });
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [products, setProducts] = useState<{ id: string }[]>([]);
  const [vendors, setvendors] = useState<{ vendor: string }[]>([]);
  const [vendorId, setVendorId] = useState("");
  const [vendorPage, setVendorPage] = useState(0);
  const [vendorKeyword, setVendorKeyword] = useState<string>("");

  const vendorSearchString = useDebounce(vendorKeyword, 1000);
  const top = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (vendorId) {
      getVendor(vendorId);
      getProducts(vendorId);
    }
  }, [vendorId]);

  useEffect(() => {
    setVendorId(vendor);
  }, [vendor]);

  useEffect(() => {
    getvendors(vendorPage < 0 ? 0 : vendorPage, vendorSearchString);
  }, [vendorSearchString]);

  const getVendor = async (vendor: string) => {
    if (vendor) {
      try {
        setLoading(AsyncStatus.Loading);
        const res = await client.get(`/get-vendor-modal-info`, {
          params: { vendor: vendor },
        });
        setVendorData(res.data.data);
        setLoading(AsyncStatus.Loaded);
      } catch (err) {
        console.error(err);
        setLoading(AsyncStatus.Failed);
      }
    }
  };

  const getvendors = async (page: number, searchInfo: string) => {
    try {
      setLoading(AsyncStatus.Loading);
      const res = await client.get(`/get-vendor-modal-vendorslist`, {
        params: { page, searchInfo },
      });
      if (page === 0) {
        setvendors(res.data.data || []);
      } else {
        setvendors((oldData) => {
          const oldIdArray = oldData.map((oItem) => oItem.vendor);
          return [
            ...oldData,
            ...(res.data.data.filter(
              (item: { vendor: string }) => !oldIdArray.includes(item.vendor)
            ) || []),
          ];
        });
      }
      setLoading(AsyncStatus.Loaded);
      setVendorPage(res.data.page || 0);
    } catch (err) {
      console.error(err);
      setLoading(AsyncStatus.Failed);
    }
  };

  const getProducts = async (vendor: string) => {
    try {
      const res = await client.get(`/products/?vendor=${vendor}`);
      setProducts(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleGoToProduct = (productId?: string) => {
    close();
    if (productId) {
      OpenModal("product", undefined, undefined, productId);
    } else {
      OpenModal("product", undefined, undefined, products[0]?.id);
    }
  };

  const handleChangeVendor = (id: string) => {
    handleScrollTo(top);
    setVendorId(id);
  };

  return (
    <DetailModalWrapper
      organizeBy="vendor"
      title={<VendorTitle vendorTitle={vendorId} loading={loading} />}
      loading={loading}
      visible={visible}
      resetId={resetId}
      numberOfChildren={products.length}
      //handleGoToChild={handleGoToProduct}
      close={close}
    >
      <DetailModalSidebar
        itemCount={vendors.length}
        keyword={vendorKeyword}
        type="vendor"
        setKeyword={setVendorKeyword}
      >
        <VendorList
          vendors={vendors}
          handleChangeVendor={handleChangeVendor}
          vendorKeyword={vendorKeyword}
        />
      </DetailModalSidebar>
      <DetailModalContent>
        <Row>
          <DetailModalDemandTimeseries id={vendorId} organizeBy="vendor" />
        </Row>

        <ModalRow columns={3}>
          <div className="col-span-1">
            <VendorInfo vendorData={vendorData} loading={loading} />
          </div>
          <div className="col-span-1">
            <DetailModalPerformanceKPI id={vendorId} type="vendor" />
          </div>
          <div className="col-span-1">
            <KeyQualities vendorTitle={vendorId} loading={loading} setEdited={setEdited} />
          </div>
        </ModalRow>

        <Row>
          <DetailModalChildPerformanceChart
            id={vendorId}
            title={vendorId}
            parentType="vendor"
            organizeBy="product"
            childType="product"
            goToChild={handleGoToProduct}
          />
        </Row>
      </DetailModalContent>
    </DetailModalWrapper>
  );
};

const VendorTitle = ({
  vendorTitle,
  loading,
}: // handleClickBreadCrumb
{
  vendorTitle: string;
  // handleClickBreadCrumb: (id: string) => void;
  loading: AsyncStatus;
}) => {
  return (
    <>
      <>{vendorTitle}</>
      <BreadCrumb
        data={[{ name: vendorTitle, type: "vendor" }]}
        onClick={() => null}
        loading={loading}
      />
    </>
  );
};

export default VendorModal;

export const VendorList = ({
  vendors,
  handleChangeVendor,
  vendorKeyword,
}: {
  vendors: { vendor: string }[];
  handleChangeVendor: (productId: string) => void;
  vendorKeyword: string;
}) => {
  return (
    <ul className="overflow-y-auto max-h-full flex flex-col gap-1 px-3">
      {vendors.map(({ vendor }) => (
        <li
          key={vendor}
          className={`rounded-lg p-3 hover:bg-black/5 cursor-pointer transition-all`}
          onClick={() => {
            handleChangeVendor(vendor);
          }}
        >
          <p className="overflow-hidden text-sm text-ellipsis" title={vendor}>
            {getHighlightedText(vendor, vendorKeyword)}
          </p>
        </li>
      ))}
    </ul>
  );
};

const VendorInfo = ({ vendorData, loading }: { vendorData: Vendor; loading: AsyncStatus }) => {
  return (
    <div>
      <AreaTopBar areaName="Details"></AreaTopBar>
      <MetricColumn>
        <Card
          name="Number of Products"
          value={formatNumber(vendorData.numberOfProducts)}
          loading={loading}
        />
        <Card
          name="Number of Variants"
          value={formatNumber(vendorData.numberOfVariants)}
          loading={loading}
        />
        <SimpleCard title="Tags" loading={loading}>
          <DetailModalItemPillListDisplay
            label="Tags"
            items={vendorData.tags || []}
            loading={loading}
          />
        </SimpleCard>
        <SimpleCard title="SKUs" loading={loading}>
          <DetailModalItemPillListDisplay
            label="SKUs"
            items={vendorData.skus || []}
            loading={loading}
          />
        </SimpleCard>
      </MetricColumn>
    </div>
  );
};

const KeyQualities = ({
  vendorTitle,
  loading,
  setEdited,
}: {
  vendorTitle: string;
  loading: AsyncStatus;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useContext(AppStateContext);
  const [leadTime, setLeadTime] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [unitsToOrder, setUnitsToOrder] = useState("");
  const [refresh, setRefresh] = useState(false);

  const handleSubmit = async (
    type: string,
    data: any,
    setter: React.Dispatch<React.SetStateAction<string>>,
    overrideNullOnly?: any
  ) => {
    await client.post(`/edit-all-vendor-data`, {
      filter: [],
      vendor: vendorTitle,
      value: parseInt(data.editedValue),
      type,
      overrideNullOnly,
    });

    setter(data.editedValue);
    setEdited?.(true);
    setRefresh((prev) => !prev);

    return true;
  };

  const getKeyQualities = async (vendor: string) => {
    try {
      const res = await client.get(`/get-vendor-modal-key-qualities/?vendor=${vendor}`);
      setLeadTime(res.data.data.averageLeadTime || "-");
      setUnitsToOrder(res.data.data.averageUnitsToOrder || "-");
      setShippingCost(res.data.data.averageShippingCost || "-");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (vendorTitle) {
      getKeyQualities(vendorTitle);
    }
  }, [vendorTitle, refresh]);

  const onSubmitLeadTime: SubmitHandler<any> = async (data, overrideNullOnly) =>
    handleSubmit("vendorLeadTime", data, setLeadTime, overrideNullOnly);
  const onSubmitShippingCost: SubmitHandler<any> = async (data) =>
    handleSubmit("shippingCost", data, setShippingCost);
  const onSubmitUnitsToOrder: SubmitHandler<any> = async (data) =>
    handleSubmit("unitsToOrder", data, setUnitsToOrder);

  return (
    <div>
      <AreaTopBar areaName="Key Qualities"></AreaTopBar>
      <MetricColumn>
        <EditableCard
          name={"Average Lead Time"}
          value={leadTime}
          loading={loading}
          onSubmit={onSubmitLeadTime}
          validator={validateNumber}
          unit={leadTime === "-" ? undefined : "Days"}
        />
      </MetricColumn>
    </div>
  );
};
