import { useContext, useEffect, useState } from "react";
import { DATE_OPTIONS } from "utils/constants";
import { DashboardContext, AppStateContext } from "context";
import { Card } from "../Dashboard/Card";
import AreaTopBar from "../Dashboard/AreaTopBar";
import { MetricColumn, MetricColumnArea, MetricLoading } from "../Dashboard/MetricHelpers";
import { AsyncStatus, IDateOption } from "types";
import { formatNumber } from "utils/helper-ts";
import { ISystemForecast } from "./ForecastOverrideModal";

export const SystemForecast = ({ data }: { data: ISystemForecast }) => {
  const { user } = useContext(AppStateContext);
  const [loading, setLoading] = useState(AsyncStatus.Loaded);

  return (
    <MetricColumnArea>
      <AreaTopBar areaName="System Forecast"></AreaTopBar>
      <MetricColumn>
        <Card name="Total Units" value={data.sum.toFixed(2)} loading={loading} />
        <Card name="Units Per Day" value={data.average.toFixed(2)} loading={loading} />
        <Card name="Days" value={data.days.toString()} loading={loading} />
      </MetricColumn>
    </MetricColumnArea>
  );
};

export default SystemForecast;
