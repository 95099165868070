import { ReactNode } from "react";

// right now the only difference between these rows is whether there is built in padding
// not necessary

const baseStyle = "border-b border-solid border-border-internal overflow-visible";

export const Row = ({ children }: { children: ReactNode }) => {
  return <div className={`${baseStyle} p-4`}>{children}</div>;
};

export const WidgetRow = ({ children, columns }: { children: ReactNode; columns?: number }) => {
  // flexible
  let layoutStyle = "flex flex-wrap items-stretch";

  // rigid columns
  if (columns === 1) {
    layoutStyle = `grid grid-cols-1`;
  }
  if (columns === 2) {
    layoutStyle = `grid lg:grid-cols-2 grid-cols-1`;
  }
  if (columns === 4) {
    layoutStyle = `grid lg:grid-cols-4 grid-cols-2`;
  }
  if (columns === 6) {
    layoutStyle = `grid lg:grid-cols-6 grid-cols-2`;
  }

  return <div className={`${baseStyle} ${layoutStyle}`}>{children}</div>;
};

const modalStyle = "overflow-visible p-4 border-b border-solid border-border-internal";

// only difference right now is the padding
export const ModalRow = ({ children, columns }: { children: ReactNode; columns?: number }) => {
  // flexible
  let layoutStyle = "flex flex-wrap items-stretch";

  // rigid columns
  if (columns === 1) {
    layoutStyle = `grid grid-cols-1 gap-4`;
  }
  if (columns === 2) {
    layoutStyle = `grid lg:grid-cols-2 grid-cols-1 gap-4`;
  }
  if (columns === 3) {
    layoutStyle = `grid lg:grid-cols-3 grid-cols-1 gap-4`;
  }
  if (columns === 4) {
    layoutStyle = `grid lg:grid-cols-4 grid-cols-2 gap-4`;
  }
  if (columns === 6) {
    layoutStyle = `grid lg:grid-cols-6 grid-cols-2`;
  }

  return <div className={`${modalStyle} ${layoutStyle}`}>{children}</div>;
};
