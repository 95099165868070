import { LoadingWrapper } from "components/Loading";
import { ChatContext } from "context";
import { useContext, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import useOutsideClick from "hooks/useOutsideClick";

export const Chat = () => {
  const { prompt, summary, loading, updateVisible } = useContext(ChatContext);
  const childrenRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  useOutsideClick(childrenRef, () => {
    updateVisible(false);
  });

  /*   useEffect(() => {
    const element = chatRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [summary]); */

  return (
    <div
      className="absolute z-[1001] bottom-36 left-16 flex flex-col h-[600px] rounded-2xl w-[400px] bg-[#413878] border-2 border-[#7047EB] text-white "
      ref={childrenRef}
    >
      <div className="flex justify-center items-center h-[50px] rounded-t-2xl w-full bg-[#676093]">
        👋 Bucephalus AI Chat
      </div>
      <div className="overflow-y-auto max-h-600">
        <br></br>
        <div className="rounded-2xl bg-[#5C4BC5] ml-[50px] p-2">
          <div className="font-bold">Me</div>
          <div>{prompt.includes("analysis") ? "Summarize this card" : "Summarize this row"}</div>
        </div>
        <br></br>

        <div className="rounded-2xl bg-[#2C2269] mr-[50px] p-2">
          <div className="font-bold">Buce AI</div>
          <div>{`Sure I'd love to`}</div>
        </div>
        <br></br>

        <LoadingWrapper loading={loading} styles="mt-2 mr-[50px] h-16">
          <div className="rounded-2xl bg-[#2C2269] mr-[50px] p-2">
            <div className="font-bold">Buce AI</div>
            <AnswerPrinter htmlString={summary}></AnswerPrinter>
          </div>
        </LoadingWrapper>
      </div>
    </div>
  );
};

function AnswerPrinter({ htmlString }: { htmlString: string }) {
  const cleanHTML = DOMPurify.sanitize(htmlString);

  return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
}

export default Chat;
