export interface IObject {
  key: string;
  value: string;
}

export interface IProduct {
  productId: string;
  productTitle: string;
}

export interface IVariant {
  id: string;
  sku: string;
  title: string;
}

export interface IVariantWrapper {
  productId: string;
  productTitle: string;
  variants: IVariant[];
}

export interface IThreshold {
  low: string | number;
  high: string | number;
}

export interface IDays {
  over: string | number;
  forecast: string | number;
}

export interface ILegend {
  payload?: Array<{
    value: string;
    dataKey: string;
    type: string;
    color: string;
  }>;
}

export interface IFilter {
  product: string;
  variant: string;
  over: string;
  forecast: string;
}

export type CheckboxState = boolean | null;

export interface IPlanning {
  dservicecomp: string;
  eleadtime: string;
  sp4orders: string;
}

export interface IFilterPlus {
  key: FilterKey;
  value: any;
  name: string;
}

export interface IKPICard {
  value: number;
}

// need to update the backend so none is an option
export type VerticalDirection = "up" | "down" | "none";

export interface IKPICardWithChange {
  value: number;
  change: number;
  changeType: VerticalDirection;
}

export interface IKPI {
  Sales: IKPICardWithChange;
  TotalOrders: IKPICardWithChange;
  AOV: IKPICardWithChange;
  TotalVariantsWithLowStockThreshold: IKPICard;
  TotalVariants: IKPICard;
  OOSStockVariants: IKPICard;
  Cost: IKPICard;
  Discount: IKPICard;
  GrossProfitMargin: IKPICard;
  TotalActiveAlerts: IKPICard;
}

export interface ISalesSummary {
  revenue: number;
  profit: number;
  pendingOrders: number;
  allOrders: number;
  paidOrders: number;
  COGs: number;
  refundedOrders: number;
}

export interface IInventoryStatus {
  healthy: number;
  lowStock: number;
  outOfStock: number;
  all: number;
}

export interface ICashflowSummary {
  grossProfitMargin: number;
  workingCapitalRatio: number;
  operatinCashflow: number;
  freeCashflow: number;
  forecastVariance: number;
}

export interface IInventorySummary {
  stockToPurchase: number;
  lockedCapital: number;
  stockOnHand: number;
  onHandRetailValue: number;
  forecastVariance: number;
}

export interface IOption {
  key: string;
  value: string;
  name?: string;
}

export interface ICustomCells {
  key: string; // only key ? col
  className: string;
  type: "col" | "row" | "cell";
  value?: string | number; // only key and value ? row
  col?: string; // key, value, col ? cell
}

export interface OrganizeByOptions {
  key: OrganizeBy;
  value: string;
}

export interface PerformanceChartData {
  name: string;
  value: number;
  averageRevenue: number;
  unitPrice: number;
  unitCost: number;
  SKUs: string[];
}

export interface TopVariants extends PerformanceChartData {
  variantId: string;
  pid: string;
  pTitle: string;
}

export interface ITopPerformers {
  productId: string;
  name: string;
  value: number;
  averageRevenue: number;
  unitPrice: number;
  unitCost: number;
  SKUs: string[];
}

export interface IBottomPerformers {
  productId: string;
  name: string;
  value: number;
  averageRevenue: number;
  unitPrice: number;
  unitCost: number;
  SKUs: string[];
}

export interface IDemandFilter {
  comparand: string;
  comparee: string;
  over: string;
  forecast: string;
}

export type DemandMetric =
  | "revenue"
  | "percentOfTotalRevenue"
  | "yearToYear"
  | "previousYearSales"
  | "quantity"
  | "previousYearQuantity";

export interface DemandMetricOptions {
  key: DemandMetric;
  value: string;
}

export interface IRow {
  [key: string]: any;
}

export interface IRawVariant {
  comparee: string;
  comparand: string;
  quantity: number;
  fQuantity: number;
}

export interface IRawDemandData {
  id: string;
  product: string;
  comparees: string[];
  comparands: string[];
  children: IRawVariant[];
}

export interface IDemandFilter2 {
  comparand: string;
  comparee: string;
  over: IObject;
}

export interface IDateOption {
  name: string;
  startDate: Date;
  endDate: Date;
  key: string;
}

export interface DateRangeOption {
  key: DateRangeOptionKeys;
  active: boolean;
  name: string;
}

export interface DateFrequencyOption {
  key: FrequencyOptionKeys;
  active: boolean;
  name: string;
}

export interface ILocation {
  locationName: string;
  locationId: string;
  inventoryQuantity: number;
  numberOfVariants: number;
  status: boolean;
  createdDate: string;
  lastUpdated: string;
  city: string;
  province: string;
  zipCode: string;
}

export interface ILocationSmall {
  locationName: string;
  locationId: string;
}

export enum TimeRangeKey {
  Last24Hours = "1",
  Last7Days = "7",
  Last30Days = "30",
  Last90Days = "90",
  Last365Days = "365",
  AllTime = "-1",
  Custom = "custom",
}

export enum TimeRangeKeyForecast {
  Next7Days = "7",
  Next30Days = "30",
  Next90Days = "90",
  Last365Days = "365",
  Custom = "custom",
}

export interface ITimeRange {
  key: TimeRangeKey;
  value: string;
}

export type OrganizeBy =
  | "category"
  | "product"
  | "variant"
  | "shop"
  | "location"
  | "sku"
  | "collection"
  | "vendor"
  | "tag";

export enum OrgByOptionKey {
  Variant = "variant",
  Vendor = "vendor",
  SKU = "sku",
  Product = "product",
  Category = "category",
  Collection = "collection",
  Tag = "tag",
}

export interface IOrgByOption {
  key: OrgByOptionKey;
  value: string;
}

export enum TopPerfCriteriaKey {
  Grossing = "grossing",
  PercentRevenue = "percent-revenue",
  RateSales = "rate-sales",
}

export interface ITopPerfCriteria {
  key: TopPerfCriteriaKey;
  value: string;
  name: string;
}

export type FilterKey =
  | "category"
  | "product"
  | "variant"
  | "sku"
  | "price"
  | "cost"
  | "sales"
  | "hasSales"
  | "vendor"
  | "tag"
  | "hasImage"
  | "hasDescription"
  | "weight"
  | "status"
  | "inventoryStatus"
  | "location"
  | "unitsOnHand"
  | "created"
  | "modified"
  | "sale"
  | "noSale"
  | "topPerformers"
  | "recommendedPurchases"
  | "more";

export type PagesWithFilters =
  | "products"
  | "demandSuite"
  | "inventory"
  | "planning"
  | "overrideReference";

export type FrequencyOptionKeys = "day" | "week" | "month" | "quarter";
export type DateRangeOptionKeys = "week" | "month" | "quarter" | "year" | "all";

export interface DropdownItem {
  id: string;
  title: string;
}

export enum AsyncStatus {
  Loading = "loading",
  Loaded = "loaded",
  Empty = "empty",
  Failed = "failed",
}
