import { useState } from "react";
import ThreeStateCheckbox from "components/Checkbox";
import { CheckboxState, IFilterPlus, IObject } from "types";
import { ApplyButton } from "components/Button";

export type MultiselectTypes = "status" | "inventoryStatus";

const INVENTORY_STATUS_OPTIONS: IObject[] = [
  { key: "outofstock", value: "Out of Stock" },
  { key: "understock", value: "Understock" },
  { key: "healthy", value: "Healthy" },
];

const STATUS_OPTIONS: IObject[] = [
  { key: "active", value: "active" },
  { key: "draft", value: "draft" },
  { key: "archived", value: "archived" },
];

const OptionsMap: Record<MultiselectTypes, IObject[]> = {
  status: STATUS_OPTIONS,
  inventoryStatus: INVENTORY_STATUS_OPTIONS,
};

interface Props {
  onApply: () => void;
  updatePageFilter: (items: any) => void;
  type: MultiselectTypes;
  disableApply: boolean;
  selected: string[];
}

export const MultiselectDropdown = ({
  onApply,
  updatePageFilter,
  type,
  disableApply,
  selected,
}: Props) => {
  const options = OptionsMap[type];
  const [selectAll, setSelectAll] = useState(false);
  const [selections, setSelections] = useState<string[]>(selected);

  const handleChangeSelection = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...selections, name];
    } else if (state === false) {
      newSelection = selections.filter((item) => item !== name);
    }
    setSelections(newSelection);
  };

  const handleApply = () => {
    if (selections.length > 0) {
      updatePageFilter((old: IFilterPlus[]) => {
        const updatedFilters = old.map((item: IFilterPlus) =>
          item.key === type
            ? {
                key: type,
                value: selections,
                name:
                  selections.length < 3
                    ? selections.join(", ")
                    : selections.length +
                      " " +
                      type.charAt(0).toUpperCase() +
                      type.slice(1) +
                      "(s)", // TODO: check -es, -s, -etc
              }
            : item
        );
        return updatedFilters;
      });

      onApply?.();
    }
  };

  const handleToggle = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelections(options.map(({ value }: IObject) => value));
    } else {
      setSelections([]);
    }
  };

  return (
    <div className="font-medium max-h-96 overflow-y-auto">
      <div>
        <label className="inline-flex relative items-center mb-4 cursor-pointer">
          <input type="checkbox" onChange={handleToggle} className="sr-only peer" />
          <div
            className={`w-6 h-3 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-border-external after:content-['']
              after:absolute after:bg-border-hover after:border 
              after:rounded-full after:h-3 after:w-3 after:transition-all bg-white`}
          ></div>
          <span className="text-ellipsis whitespace-nowrap ml-2">
            {selectAll ? "Select all" : "Deselect all"}
          </span>
        </label>
      </div>
      <ul>
        {options.map(({ key, value }: IObject) => (
          <li
            key={key}
            className="flex items-center h-8 hover:rounded-full hover:bg-border-hover/40 hover:font-bold px-2"
          >
            <ThreeStateCheckbox
              id={key}
              name={value}
              checked={selections.findIndex((v) => v === value) !== -1}
              onChange={handleChangeSelection}
            />
            <label
              htmlFor={key}
              className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {value}
            </label>
          </li>
        ))}
      </ul>

      <ApplyButton onClick={handleApply} disabled={disableApply || selections.length === 0} />
    </div>
  );
};

export default MultiselectDropdown;
