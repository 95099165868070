import { useEffect } from "react";
import client from "api";
import { useState } from "react";
import ThreeStateCheckbox from "components/Checkbox";
import { CheckboxState, IFilterPlus } from "types";
import { ApplyButton } from "components/Button";

export type TaggingTypes = "tag" | "vendor" | "location";

export interface ITags {
  tags?: string;
  vendor?: string;
  location_name?: string;
  location_id?: string;
}

interface Props {
  onApply: () => void;
  updatePageFilter: (items: any) => void;
  type: TaggingTypes;
  disableApply: boolean;
  selected: string[];
}

export const TaggingDropdown = ({
  onApply,
  updatePageFilter,
  type,
  disableApply,
  selected,
}: Props) => {
  const [Tags, setTags] = useState<string[]>([]);
  const [selections, setSelections] = useState<string[]>(selected);
  const [selectAll, setSelectAll] = useState(false);
  const handleChangeSelection = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...selections, name];
    } else {
      newSelection = selections.filter((item) => item !== name);
    }
    setSelections(newSelection);
  };

  const handleApply = () => {
    if (selections.length > 0) {
      updatePageFilter((old: IFilterPlus[]) => {
        const updatedFilters = old.map((item: IFilterPlus) =>
          item.key === type
            ? {
                key: type,
                value: selections,
                name:
                  selections.length < 3
                    ? selections.join(", ")
                    : selections.length +
                      " " +
                      type.charAt(0).toUpperCase() +
                      type.slice(1) +
                      "(s)", // TODO: check -es, -s, -etc
              }
            : item
        );
        return updatedFilters;
      });

      onApply?.();
    }
  };

  async function getTags() {
    let res;
    try {
      if (type === "tag" || type === "vendor") {
        res = await client.get(`/tags`, {
          params: {
            type: type === "tag" ? "tags" : "vendor",
          },
        });
      } else {
        res = await client.get(`/inventory-locations`, {
          params: {
            productIds: [],
            variantIds: [],
            columns: [type],
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    let tags = res?.data?.data;
    let allTags: string[] = [];

    tags.forEach((tag: ITags) => {
      if (tag.tags) {
        let curTag = tag.tags.split(",");
        for (let i = 0; i < curTag.length; i++) {
          if (!allTags.includes(curTag[i])) {
            allTags.push(curTag[i]);
          }
        }
      } else if (tag.vendor) {
        if (!allTags.includes(tag.vendor)) {
          allTags.push(tag.vendor);
        }
      } else if (tag.location_name) {
        if (!allTags.includes(tag.location_name)) {
          allTags.push(tag.location_name);
        }
      }
    });

    setTags(allTags);
    if (selected.length === 0) {
      setSelections(allTags);
    }
  }
  useEffect(() => {
    getTags();
  }, [type]);

  const handleToggle = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      getTags();
    } else {
      setSelections([]);
    }
  };
  return (
    <div>
      <div>
        <label className="inline-flex relative items-center mb-4 cursor-pointer">
          <input type="checkbox" onChange={handleToggle} className="sr-only peer" />
          <div
            className={`w-6 h-3 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-border-external after:content-['']
              after:absolute after:bg-border-hover after:border 
              after:rounded-full after:h-3 after:w-3 after:transition-all bg-white`}
          ></div>
          <span className="text-ellipsis whitespace-nowrap ml-2">
            {selectAll ? "Select all" : "Deselect all"}
          </span>
        </label>
      </div>
      <div className="font-medium max-h-96 overflow-y-auto">
        <ul>
          {Tags.map((tag) => (
            <li
              key={tag}
              className="flex items-center h-8 hover:rounded-full hover:bg-border-hover/40 hover:font-bold px-2"
            >
              <ThreeStateCheckbox
                id={tag}
                name={tag}
                checked={selections.findIndex((v) => v === tag) !== -1}
                onChange={handleChangeSelection}
              />
              <label
                htmlFor={tag}
                className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {tag}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <ApplyButton onClick={handleApply} disabled={disableApply || selections.length === 0} />
    </div>
  );
};

export default TaggingDropdown;
