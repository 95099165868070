import Modal, { ModalLegacyImage, ModalLegacyFooter, ModalLegacySection } from "./BasicModal/index";
import InventoryPreview from "../../assets/images/inventory-preview.png";

export const InventoryOnboardingModal = ({
  visible,
  handleOk,
}: {
  visible: boolean;
  handleOk: () => void;
}) => {
  return (
    <Modal
      visible={visible}
      title="Welcome to the Inventory Tab"
      size="medium"
      onOk={handleOk}
      onCancel={handleOk}
    >
      <ModalLegacySection>
        <ModalLegacyImage imageSrc={InventoryPreview} />
        <p>
          The chart you see takes daily snapshots of your inventory levels and will eventually look
          like the screen above.
        </p>
        <p className="mt-2">
          <b>Please check back after a few days so we can collect your data. </b>
          In the meantime, please explore the table below.
        </p>
      </ModalLegacySection>
      <ModalLegacyFooter onOk={handleOk} />
    </Modal>
  );
};

export default InventoryOnboardingModal;
