import { openInNewTab } from "utils/helper-ts";

export const FailedFetch = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-base-inverted ">
      <div className="rounded-[10px] p-8 flex flex-col justify-center shadow-dashboard border border-solid ">
        <div className="flex items-center gap-3">
          <p className="font-mono text-2xl">🤔</p>
          <p className="font-mono-bold text-md">Failed to fetch data for given selection</p>
        </div>
        <div className="flex flex-col gap-3">
          {/* <p className="font-mono text-sm">You may not have any data for your selection. </p> */}
          <p className="font-mono text-sm">
            If this seems wrong, please let us know in the chat, or{" "}
            <a
              className="underline cursor-pointer transition-all hover:text-purple-base"
              onClick={() => {
                openInNewTab("https://calendly.com/bucephalus/30min");
              }}
            >
              schedule a call
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
