import React, { useState, useCallback, useEffect, useRef } from "react";
import { ReactComponent as Search } from "assets/images/search.svg";
import { getHighlightedText } from "utils/helper";
import type { IVariant, CheckboxState, IVariantWrapper, AsyncStatus } from "types";
import ThreeStateCheckbox from "components/Checkbox";
import Loading, { LoadingWrapper } from "components/Loading";
import { Index, IndexRange, InfiniteLoader, List } from "react-virtualized";
import { useWindowSize } from "hooks/useWindowSize";
import { ApplyButton } from "components/Button";

interface Props {
  data: IVariantWrapper[];
  selectedVariants: string[];
  onApply: (ids: string[]) => void;
  loading: AsyncStatus;
  disableApply: boolean;
  getVariants: (productIds: string[], searchString: string) => Promise<any>;

  // This is for search
  variantKeyword?: string;
  setVariantKeyword?: (keyword: string) => void;
}

function getProductVariants(data: IVariantWrapper[], id: string) {
  const filteredProduct = data.filter((prod: IVariantWrapper) => prod.productId === id);
  return filteredProduct[0].variants.map((variant: IVariant) => variant.id);
}

const VariantsDropdown = ({
  data,
  selectedVariants,
  onApply,
  variantKeyword,
  setVariantKeyword,
  loading,
  disableApply,
  getVariants,
}: Props) => {
  const listRef = useRef<List | null>(null);
  const [selections, setSelections] = useState<string[]>(selectedVariants);

  const [filteredItems, setFilteredItems] = useState<IVariantWrapper[]>(data);
  const [searchKeyword, setSearchkeyword] = useState("");

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (searchKeyword) {
      const keywordLower = searchKeyword.toLowerCase();

      const filtered = data.map((product) => {
        const filteredVariants = product.variants.filter(
          (variant) =>
            variant.title?.toLocaleLowerCase()?.includes(keywordLower) ||
            variant.sku?.toLocaleLowerCase()?.includes(keywordLower) ||
            variant.id?.toLocaleLowerCase()?.includes(keywordLower) ||
            product.productTitle.toLocaleLowerCase()?.includes(keywordLower) ||
            product.productId.toLocaleLowerCase()?.includes(keywordLower)
        );
        return { ...product, variants: filteredVariants };
      });
      setFilteredItems(filtered);
    } else {
      setFilteredItems(data);
    }

    if (listRef.current) {
      (listRef.current as List).recomputeRowHeights(); // Type assertion
    }
  }, [data, searchKeyword]);

  useEffect(() => {
    setSelections(selectedVariants);
  }, [selectedVariants]);

  useEffect(() => {
    if (listRef.current) {
      (listRef.current as List).recomputeRowHeights(); // Type assertion
    }
  }, [width]);

  useEffect(() => {
    async function getAllVariants() {
      await getVariants([], "");
    }
    if (data.length === 0) {
      getAllVariants();
    }
  }, []);

  const handleChangeCheckbox = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...new Set([...selections, ...getProductVariants(filteredItems, name)])];
      setSelections(newSelection);
    } else if (state === false) {
      newSelection = selections.filter(
        (id) => !getProductVariants(filteredItems, name).includes(id)
      );
    }
    setSelections(newSelection);
  };

  const handleChangeVariantSelection = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...selections, name];
    } else if (state === false) {
      newSelection = selections.filter((id) => id !== name);
    }
    setSelections(newSelection);
  };

  const getCheckboxState = useCallback(
    (productId: string) => {
      const productVariants = getProductVariants(filteredItems, productId);
      const length = selections.filter((v) => productVariants.includes(v)).length;
      if (length === 0) {
        return false;
      }
      if (length === productVariants.length) {
        return true;
      }
      return null;
    },
    [selections, filteredItems]
  );

  const [loadedItems, setLoadedItems] = useState<number[]>([]);

  const isItemLoaded = ({ index }: Index): boolean => loadedItems.includes(index);

  const loadMoreItems = ({ startIndex, stopIndex }: IndexRange): Promise<any> => {
    return new Promise(() => {
      const newLoadedItems = [...loadedItems];
      for (let i = startIndex; i <= stopIndex; i++) {
        newLoadedItems.push(i);
      }
      setLoadedItems(newLoadedItems);
    });
  };

  const renderItem = ({
    index,
    key,
    style,
  }: {
    index: number;
    key: React.Key;
    style: React.CSSProperties;
  }) => (
    <li key={key} style={style} className="list-none">
      <div key={filteredItems[index].productId}>
        {filteredItems[index].variants.length > 0 && (
          <div className="flex items-center hover:rounded-full hover:bg-border-hover/40 font-bold">
            <ThreeStateCheckbox
              id={filteredItems[index].productId}
              name={filteredItems[index].productId}
              checked={getCheckboxState(filteredItems[index].productId)}
              onChange={handleChangeCheckbox}
            />
            <label
              htmlFor={filteredItems[index].productId}
              className="w-full font-bold pl-2.5 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {filteredItems[index].productTitle}
              {` (${filteredItems[index].variants.length}  variants)`}
            </label>
          </div>
        )}
        <div>
          <ul>
            {filteredItems[index].variants.map(({ id, sku, title }) => (
              <li
                key={id}
                className="flex items-center h-8 pl-5 pr-2 hover:rounded-full hover:bg-border-hover/40 hover:font-bold"
              >
                <ThreeStateCheckbox
                  id={id}
                  name={id}
                  checked={selections.findIndex((v) => v === id) !== -1}
                  onChange={handleChangeVariantSelection}
                />
                <label
                  htmlFor={id}
                  className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {getHighlightedText(title, variantKeyword)}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  );

  const calculateRowHeight = ({ index }: Index) => {
    let itemHeight = 32;
    if (width < 1500) {
      itemHeight = 28;
    }
    if (width < 1200) {
      itemHeight = 24;
    }
    return filteredItems[index].variants.length === 0
      ? 0
      : (filteredItems[index].variants.length + 1) * itemHeight;
  };

  return (
    <div>
      <LoadingWrapper loading={loading} styles="h-[30px] w-full rounded-md mt-2 text-center">
        <div>
          <Search className="absolute w-10 h-10 m-[2.5px] text-base-text" />
          <input
            type="text"
            className="w-full h-[45px] bg-base-gentle rounded-xl outline-none pl-12 pr-4 text-base text-purple-base appearance-none"
            value={searchKeyword}
            onChange={(e) => setSearchkeyword?.(e.target.value)}
          />
        </div>
        <div className="py-2.5">
          <div className="py-2.5 font-bold ">{`selected: ${
            selections.length
          } / ${filteredItems.reduce((sum, product) => sum + product.variants.length, 0)}`}</div>
          <InfiniteLoader
            isRowLoaded={isItemLoaded}
            loadMoreRows={loadMoreItems}
            rowCount={filteredItems.length}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={(list) => {
                  listRef.current = list; // Assign the List component reference
                  registerChild(list);
                }}
                rowCount={filteredItems.length}
                rowHeight={calculateRowHeight}
                rowRenderer={renderItem}
                onRowsRendered={onRowsRendered}
                width={300}
                height={300}
              />
            )}
          </InfiniteLoader>
        </div>
        {selections.length === 0 ? (
          <div className="text-center">please select at least 1 variant</div>
        ) : (
          <ApplyButton onClick={() => onApply(selections)} disabled={disableApply} />
        )}
      </LoadingWrapper>
    </div>
  );
};

export default VariantsDropdown;
