export function getHighlightedText(text, highlight = "") {
  // Split on highlight term and include term into parts, ignore case
  // TODO: add title attribute to span
  const parts = text?.split(
    new RegExp(`(${highlight.replace(/[\\/]/g, (match) => `\\${match}`)})`, "gi")
  );
  return (
    <span className="overflow-hidden whitespace-nowrap text-ellipsis">
      {parts &&
        parts.map((part, i) => (
          <span
            key={i}
            style={part?.toLowerCase() === highlight?.toLowerCase() ? { fontWeight: "bold" } : {}}
          >
            {part}
          </span>
        ))}
    </span>
  );
}

export function downloadCSV(data) {
  let content = "data:text/csv;charset=utf-8,";
  content += Object.keys(data[0]).join(",") + "\r\n";
  data.forEach((item) => {
    content += Object.values(item).join(",");
  });
  const encodedUri = encodeURI(content);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "download.csv");
  document.body.appendChild(link);
  link.click();
}

export function scrollLock(on) {
  if (on) disableScroll();
  else enableScroll();
}

function disableScroll() {
  document.body.classList.add("h-full", "overflow-hidden");
}

function enableScroll() {
  document.body.classList.remove("h-full", "overflow-hidden");
}

// this needs to be renamed
export function formatNumber(value) {
  if (value === undefined) return "";
  return new Intl.NumberFormat("en-US").format(value);
}
