// these were similiar, but not similar enough to merge to me

export const PasswordStrengthBar = ({
  complexityThreshold,
  score,
}: {
  complexityThreshold: number;
  score: number;
}) => {
  let widthStyle = ["2%", "25%", "50%", "75%", "100%"];

  return (
    <div className={`w-full bg-white/30 h-1 overflow-hidden rounded-md`}>
      {score >= complexityThreshold ? (
        <div
          style={{ width: widthStyle[score] }}
          className="bg-green-600 h-1 transition-all duration-500"
        ></div>
      ) : (
        <div
          style={{ width: widthStyle[score] }}
          className="bg-red-500 h-1 transition-all duration-500"
        ></div>
      )}
    </div>
  );
};

export const InitProgressBar = ({ initStatus }: { initStatus: number }) => {
  let widthStyle = ["0%", "20%", "40%", "60%", "80%", "100%"];

  return (
    <div className={`w-full bg-gray-300 h-1 overflow-hidden rounded-md`}>
      <div
        style={{ width: widthStyle[initStatus] }}
        className="bg-green-600 h-1 transition-all"
      ></div>
      {/* {score >= complexityThreshold ? (
      ) : (
        <div style={{ width: widthStyle[initStatus] }} className="bg-red-400 h-1 transition-all"></div>
      )} */}
    </div>
  );
};

export const LoadingProgressBar = ({
  initStatus,
  isDone,
}: {
  initStatus: number;
  isDone?: boolean;
}) => {
  let widthStyle = ["5%", "20%", "40%", "60%", "80%", "90%"];

  return (
    <div className={`w-full bg-gray-300 h-1 overflow-hidden rounded-md`}>
      <div
        style={isDone ? { width: "100%" } : { width: widthStyle[initStatus] || "90%" }}
        className={`${isDone ? "bg-green-500" : "bg-gray-500"} h-1 transition-all duration-300`}
      ></div>
    </div>
  );
};
