import React, { useRef, useEffect, useState, ReactNode } from "react";
import Modal from "../BasicModal/index";
import BreadCrumb from "components/Breadcrumb";
import client from "api";
import { getHighlightedText } from "utils/helper";
import { handleScrollTo } from "utils/helper-ts";
import { ReactComponent as Search } from "assets/images/search.svg";
import Loading from "components/Loading";
import { IOption, ITopPerformers, ILocation, ILocationSmall, AsyncStatus } from "types";
import PerformanceChart from "components/PerformanceBarChart";

const StatFlex = ({ title, value }: { title: string; value: string | React.ReactNode }) => {
  return (
    <div className="flex items-center">
      <div className="font-bold">{title}</div>
      <div className="ml-1 my-1">{value || "Unknown"} </div>
    </div>
  );
};

export const LocationModal = ({
  close,
  visible,
  location,
}: {
  close: () => void;
  visible: boolean;
  location: string;
}) => {
  const [expanded, setExpanded] = useState(true);
  const [locationData, setLocationData] = useState<ILocation>({
    locationName: "",
    locationId: "",
    inventoryQuantity: 0,
    numberOfVariants: 0,
    status: false,
    createdDate: "",
    lastUpdated: "",
    city: "",
    province: "",
    zipCode: "",
  });
  const [locations, setLocations] = useState<ILocationSmall[]>([]);
  const [locationKeyword, setLocationKeyword] = useState<string>("");
  const [locationId, setLocationId] = useState("");
  const [products, setProducts] = useState<ITopPerformers[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(AsyncStatus.Loading);
  const top = useRef<HTMLDivElement>(null);
  const criteria: IOption = { name: "Quantity in Stock", value: "10", key: "product" };

  useEffect(() => {
    if (locationId) {
      getLocation(locationId);
      getProductData(locationId);
    }
  }, [locationId]);

  useEffect(() => {
    setLocationId(location);
  }, [location]);

  // Data pull for all locations, grabs all the locations listed in LocationSmall type
  // Intended use is to populate the sidebar and make navigating different locations in the modal possible
  useEffect(() => {
    async function fetchLocations() {
      try {
        const res = await client.get("/get-all-locations");
        setLocations(res.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchLocations();
  }, []);

  // Data pull for the specific location the user is currently clicked into
  const getLocation = async (location: string) => {
    try {
      setLoadingData(true);
      const res = await client.get("/get-location-info", { params: { location: location } });
      setLocationData(res.data.data);
      setLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  };

  // Data pull that populates the PerformanceChart on the modal
  const getProductData = async (location: string) => {
    try {
      setLoadingTable(AsyncStatus.Loading);
      const res = await client.get("/get-location-product-data", {
        params: { location: location },
      });
      setProducts(res.data.data);
      setLoadingTable(AsyncStatus.Loaded);
    } catch (error) {
      console.error(error);
      setLoadingTable(AsyncStatus.Failed);
    }
  };

  const handleChangeLocation = (locationId: string) => {
    handleScrollTo(top);
    setLocationId(locationId);
  };

  // A lot of this is ripped directly from the category modal return statement, except with all the variables removed
  // CategoryModal can be used as a reference for the empty places
  return (
    <Modal
      title={locationId}
      visible={visible}
      titleClassName="overflow-hlocationIdden text-ellipsis whitespace-nowrap flex-1"
      onCancel={() => {
        close();
      }}
      size={"w-11/12"}
    >
      <div className="flex -mx-10 -my-4 w-full">
        <section id="left" className={`flex h-[calc(100vh-200px)] sticky -top-4 py-8 bg-[#EDECF3]`}>
          <p
            className="px-6 font-mono text-base-text/40 text-xs font-black uppercase text-right select-none origin-center rotate-180 cursor-pointer"
            style={{ writingMode: "vertical-lr" }}
            onClick={() => setExpanded((old) => !old)}
          >
            {expanded ? "hide" : "show"} {locations.length} locations
          </p>
          <div className={`${!expanded ? "hidden" : "w-72 pr-8"}`}>
            <div className="pb-2.5 relative">
              <Search className="absolute w-10 h-10 m-[2.5px] text-transparent" />
              <input
                type="text"
                className="w-full h-[45px] bg-black/5 rounded-3xl outline-none border-none pl-12 pr-4 text-purple-base appearance-none"
                value={locationKeyword}
                onChange={(e) => setLocationKeyword(e.target.value)}
                placeholder="location"
              />
            </div>
            <div className="font-medium overflow-y-auto h-[calc(100vh-320px)]">
              <ul className="space-y-2.5">
                {locations.map(({ locationName, locationId }) => (
                  <li
                    key={locationName}
                    className={`
                      rounded-xl p-2.5 hover:bg-black/5 font-normal cursor-pointer
                      ${locationName === locationId ? "bg-black/5" : ""}
                    `}
                    onClick={() => handleChangeLocation(locationName)}
                  >
                    <p className="overflow-hlocationIdden text-ellipsis">
                      {getHighlightedText(locationName, locationKeyword)}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section id="right" className="flex-1 py-4 px-8">
          <div ref={top}></div>
          <BreadCrumb
            data={[{ name: locationId, type: "category" }]}
            loading={AsyncStatus.Loaded}
          />
          <div className="flex mt-4 mb-12 font-mono text-xs">
            <div className="basis-0 space-y-2.5 w-full min-w-[250px] max-w-[500px]">
              {loadingData ? (
                <Loading />
              ) : (
                <div>
                  <StatFlex title="Province:" value={locationData.province} />
                  <StatFlex title="City:" value={locationData.city} />
                  <StatFlex title="Zip Code:" value={locationData.zipCode} />
                  <StatFlex
                    title="Date Created:"
                    value={locationData.createdDate.substring(0, 10)}
                  />
                  <StatFlex
                    title="Last Updated:"
                    value={locationData.lastUpdated.substring(0, 10)}
                  />
                  <StatFlex title="Inventory Quantity:" value={locationData.inventoryQuantity} />
                  <StatFlex title="Number of Variants:" value={locationData.numberOfVariants} />
                  <StatFlex title={"Status:"} value={locationData.status ? "Active" : "Inactive"} />
                </div>
              )}
            </div>

            {loadingTable ? (
              <div className="w-full">
                <Loading />
              </div>
            ) : (
              <div className="h-5/6 ml-auto w-full">
                <PerformanceChart
                  data={products}
                  loading={loadingTable}
                  organizeBy="location"
                  criteria={criteria}
                  option={{ key: "product", value: "By Product" }}
                  barColor={"#C7E3B1"}
                  type={"demandChart"}
                  isVertical={true}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default LocationModal;
