import Modal from "./BasicModal/index";
import Meeting from "components/Others/meeting";

export const InventoryHelpModal = ({ close, visible }: { close: () => void; visible: boolean }) => {
  return (
    <Modal title={"Inventory Help"} visible={visible} onCancel={close}>
      <div className="prose max-w-prose text-base-text mx-7 my-5">
        <p>
          This is where you can track the inventory of your units and set thresholds for when you
          consider a variant over or understock.{" "}
        </p>
        <p className="font-bold">Changing Thresholds</p>
        <p>
          Thresholds can be changed in the table – just like excel. If there are different
          thresholds for some variants, the product will report “mixed” to show that the thresholds
          are not all the same.
        </p>
        <p className="font-bold">Populating Data</p>
        <p>
          Shopify doesnt automatically track inventory over time, so it will take a little while for
          us to generate charts for you. If you want to share your data with us manually. Please
          schedule a meeting down below!
        </p>
        <p className="font-bold">Using the Chart</p>
        <p>
          Historical data and forecasts are only generated at the product level, so be sure to pick
          a product to start. Then use the variant dropdown if you want to select a specific mix of
          variants.
        </p>
        <Meeting />
      </div>
    </Modal>
  );
};

export default InventoryHelpModal;
