import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  PropsWithChildren,
  ReactNode,
  HTMLAttributes,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useOutsideClick from "hooks/useOutsideClick";
import { getHighlightedText } from "utils/helper";
import { IObject } from "types";
import Loading from "components/Loading";
import { ReactComponent as Search } from "assets/images/search.svg";
import { ReactComponent as Up } from "assets/images/dropdown_up.svg";
import { ReactComponent as Plus } from "assets/images/plus.svg";

export enum POSITION {
  BOTTOM_RIGHT = "bottomRight",
  BOTTOM_LEFT = "bottomLeft",
}

interface Props {
  name: string;
  position: POSITION;
  hasSearch?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
  loading?: boolean;

  // This is for the filter
  plusIcon?: boolean;

  size?: "small" | "large" | "dynamic";

  // This is for the children component
  overlay: ReactNode | Array<IObject>;

  closeDropdown?: boolean;
  setCloseDropdown?: (props: false) => void;

  // This is for item click event
  onChange?: (key: string, value: IObject) => void;

  // This is for infinite scroll
  hasMore?: boolean;
  nextFetch?: () => void;

  // This is for search
  keyword?: string;
  setKeyword?: (keyword: string) => void;

  openDropdown?: boolean;

  custom?: boolean;
}

const Dropdown = ({
  name,
  position,
  hasSearch,
  disabled,
  showArrow,
  loading,
  plusIcon,
  size = "large",
  overlay,
  hasMore = false,
  nextFetch = () => {},
  onChange,
  closeDropdown = false,
  setCloseDropdown,
  keyword,
  setKeyword,
  openDropdown,
  custom,
  children,
  ...props
}: PropsWithChildren<Props> & Omit<HTMLAttributes<HTMLDivElement>, "onChange">) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isRightOverflowing, setIsRightOverflowing] = useState(false);
  const [isLeftOverflowing, setIsLeftOverflowing] = useState(false);
  const [isBottomOverflowing, setIsBottomOverflowing] = useState(false);
  const [customStyles, setCustomStyles] = useState({});
  const childrenRef = useRef(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (closeDropdown) {
      setVisible(false);
      setCloseDropdown?.(false);
    }
  }, [closeDropdown]);

  //I dont like this code I want to refactor it but its big task need to test a lot of things, to make it less weird I kept using previous logic pattern
  useEffect(() => {
    if (openDropdown) {
      setVisible(openDropdown);
    }
  }, [openDropdown]);

  useEffect(() => {
    if (dropdownRef.current) {
      setIsRightOverflowing(dropdownRef.current.getBoundingClientRect().right > window.innerWidth);
      setIsLeftOverflowing(dropdownRef.current.getBoundingClientRect().left < 0);
      setIsBottomOverflowing(
        dropdownRef.current.getBoundingClientRect().bottom > window.innerHeight
      );
    }
  }, [visible]);

  useOutsideClick(childrenRef, () => {
    setVisible(false);
  });

  const positionClass = useMemo(() => {
    let className = "";
    switch (position) {
      case POSITION.BOTTOM_RIGHT:
        if (isLeftOverflowing) className += "left-0";
        else className += "right-0";
        break;
      case POSITION.BOTTOM_LEFT:
        if (isRightOverflowing) {
          if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            if (rect.width < rect.left - 120) {
              className += "right-0";
              setCustomStyles({});
            } else {
              setCustomStyles({ left: `-${rect.right - window.innerWidth + 20}px` });
            }
          }
        } else {
          className += "left-0";
          setCustomStyles({});
        }
        break;
      default:
        className += "";
        break;
    }
    if (isBottomOverflowing) className += " top-[-350px]";

    return className;
  }, [position, isRightOverflowing, isLeftOverflowing, isBottomOverflowing]);

  const handleOpenDropdown = useCallback(() => {
    if (!disabled) {
      setVisible((old) => !old);
      setKeyword?.("");
    }
  }, [disabled]);

  const handleListItemSelected = useCallback(
    (item: IObject) => {
      setVisible(false);
      onChange?.(name, item);
    },
    [name]
  );

  return (
    <div className={`relative cursor-pointer`} onClick={handleOpenDropdown} ref={childrenRef}>
      <div
        className={
          custom
            ? "h-4"
            : `
          flex justify-between items-center border-none outline-none
          ${
            plusIcon
              ? "rounded-[3px] p-1 bg-border-hover text-base-inverted"
              : "rounded-full w-[150px] px-2 py-2.5 bg-base-gentle"
          }
          ${disabled ? "" : !plusIcon ? "hover:bg-[#E7E4F3]" : ""}
          ${props.className}
        `
        }
        style={props.style}
      >
        {children}
        {showArrow && (
          <div>
            {plusIcon ? (
              <Plus />
            ) : (
              <Up
                className={`
              mx-1
              ${disabled ? "text-base-light" : ""}
              ${visible ? "" : "origin-center rotate-180"}
            `}
              />
            )}
          </div>
        )}
      </div>

      {overlay && (
        <div
          className={`
          absolute pt-2.5 ${size !== "dynamic" ? "max-w-sm" : ""} z-40
          ${positionClass} ${visible ? "block" : "hidden"}
          ${hasSearch ? "min-w-[300px]" : "min-w-[200px]"}
        `}
          style={customStyles}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="rounded-xl border border-solid border-border-hover shadow-popup px-4 py-2.5 bg-purple-base text-white text-xs"
            ref={dropdownRef}
          >
            {!Array.isArray(overlay) ? (
              <div tabIndex={0}>{overlay}</div>
            ) : (
              <>
                {hasSearch && (
                  <>
                    <Search className="absolute w-10 h-10 m-[2.5px] text-transparent" />
                    <input
                      type="text"
                      className="w-full h-[45px] bg-base-gentle rounded-xl outline-none pl-12 pr-4 text-base text-purple-base appearance-none"
                      value={keyword}
                      onChange={(e) => setKeyword?.(e.target.value)}
                    />
                  </>
                )}
                <div id="scrollableDropdown" className="overflow-auto max-h-96">
                  <InfiniteScroll
                    dataLength={overlay.length}
                    next={nextFetch}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDropdown"
                    loader={<Loading />}
                  >
                    <ul className="font-medium">
                      {overlay.map(({ key, value }: IObject) => (
                        <li
                          key={key}
                          className="flex items-center h-8 px-2 hover:rounded-full hover:bg-border-hover/40 hover:font-bold"
                          onClick={() => handleListItemSelected({ key, value })}
                        >
                          {getHighlightedText(value, keyword)}
                        </li>
                      ))}
                    </ul>
                  </InfiniteScroll>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
