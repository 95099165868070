// v4 uuid to ensure that there is a one in a billion chance for a grouping title to break this system
// this can just stay static
export const ROW_SPAN_KEY = "a0f47503-c79a-4e74-84c3-3f64319c0610";

// needs to take in number of rows at a high level
// this is redundant right now because I started thinking it was more elegant to check if the titles have changed only
// now it also takes in a param for spanned rows because that number is also flexible
// it may only need spanned rows system
export function insertRowSpan(rows: any, spannedRows: number) {
  if (rows?.length > 0) {
    let currentValue;
    let spanCounter = 1;

    for (let i = 0; i < rows.length; i++) {
      if (currentValue !== rows[i].grouping || spanCounter >= spannedRows) {
        spanCounter = 1;
        rows[i].preservedGrouping = rows[i].grouping;
        currentValue = rows[i].grouping;
      } else {
        spanCounter++;
        rows[i].preservedGrouping = rows[i].grouping;
        rows[i].grouping = ROW_SPAN_KEY;
        if (spanCounter === spannedRows) {
          rows[i].lastRow = true;
        }
      }
    }
  }
  return rows;
}
