import { useContext, useEffect, useState } from "react";
import { handleScrollTo } from "utils/helper-ts";
import { DemandContext } from "context";
import Button from "components/Button";

const TableScrollCurrent = () => {
  const { current } = useContext(DemandContext);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      },
      { threshold: 0 }
    );

    if (current.current) {
      observer.observe(current.current);
    }

    return () => {
      if (current.current) {
        observer.unobserve(current.current);
      }
    };
  }, [current]);

  return isVisible ? (
    <Button
      label={"Scroll to Current Period"}
      onClick={() => {
        handleScrollTo(current);
      }}
      buttonType={"secondary"}
    />
  ) : null;
};

export default TableScrollCurrent;
