import { ReactNode } from "react";
import Loading from "components/Loading";

interface MetricWrapperProps {
  children: ReactNode;
  noBorder?: boolean;
  noPadding?: boolean;
}

export const MetricColumnArea = ({ children, noBorder, noPadding }: MetricWrapperProps) => {
  return (
    <div className={`flex-auto relative min-w-[96] ${noBorder ? "" : "border-r"}`}>
      <div className={`w-full ${noPadding ? "" : "p-6"}`}>{children}</div>
    </div>
  );
};

export const MetricLoading = () => {
  return (
    <div className="h-full flex justify-center items-center">
      <Loading />
    </div>
  );
};

export const MetricColumn = ({ children }: MetricWrapperProps) => {
  return (
    <div className="flex flex-wrap bg-base-lightwhite shadow-dashboard w-full rounded-[10px] border border-solid border-border-internal overflow-hidden">
      <div className="w-full flex flex-col">{children}</div>
    </div>
  );
};
