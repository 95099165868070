import { Button } from "../Buttons";
import { openInNewTab, brandGradient } from "utils/helper-ts";

const Meeting = () => (
  <div
    style={brandGradient}
    className="prose items-center justify-center text-white p-8 my-4 rounded-xl"
  >
    <h3 className="text-white">Talk to Us 👋</h3>
    <p>
      If you have any feedback, questions about the application or need one on one help analyzing
      your data please schedule a meeting. We are here to help.
    </p>
    {/* <p>Feedback? Questions? Need one on one help to analyze your data?</p> */}
    <div className="flex gap-2 my-4">
      <Button
        text="Chat with the CEO"
        action={() => openInNewTab("https://calendly.com/bucephalus/30min")}
      />
      <Button
        text="Analyze My Data"
        action={() => openInNewTab("https://calendly.com/bucephalus/chat-with-a-ds")}
      />
    </div>
    <p className="text-xs opacity-70 text-center">
      You can also use the chat feature hovering in the bottom right!
    </p>
  </div>
);

export default Meeting;
