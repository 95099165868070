import * as DateUtils from "utils/date";

function calcYAxisTicks(tickCount: number, minY: number, maxY: number): number[] {
  var ticks: number[] = [];
  var interval: number;
  const numTicks = tickCount - 1;
  const max = (maxY - minY) % (tickCount - 1) === 0 ? maxY : maxY + (numTicks - (maxY % numTicks));
  interval = Math.round((maxY - minY) / numTicks);
  var fisrtTick = minY + interval;
  var adjustInterval: number;
  if (fisrtTick % 5 !== 0) {
    adjustInterval = fisrtTick + (5 - (fisrtTick % 5));
  } else {
    adjustInterval = interval;
  }
  for (let i = 0; i < tickCount; i++) {
    ticks.push(minY + adjustInterval * i);
  }
  return ticks;
}

function DateTick(props: any) {
  const { x, y, tickFormatter, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} fill="#4C307A">
        <tspan
          textAnchor="middle"
          x="0"
          style={{ fontSize: 10, lineHeight: 2, fontFamily: "SF Mono" }}
        >
          {tickFormatter(payload.value)}
        </tspan>
        <tspan
          textAnchor="middle"
          x="0"
          dy="16"
          style={{ fontSize: 10, lineHeight: 2, fontFamily: "SF Mono" }}
        >
          {DateUtils.currentDay(payload.value)}
        </tspan>
      </text>
    </g>
  );
}

function interpolatedDateTicks(count: number, start: Date, end: Date): string[] {
  let ticks: string[] = [];

  let diff = DateUtils.daysBetween(start, end);

  // check if days are divisible by number of ticks
  if (diff % count != 0) {
    const endDate = DateUtils.forwardDays(end, count - (diff % count));
    diff = DateUtils.daysBetween(start, endDate);
  }

  let interval = diff / count;
  for (let i = 0; i < count; i++) {
    let forwardDay = DateUtils.forwardDays(start, interval * i);
    ticks.push(forwardDay.toString().slice(4, 10));
  }
  return ticks;
}

const HEX_COLORS = {
  currentYear: "#180A30",
  currentYearLighten: "#736a83",
  previousYear: "#C4BAD4",
  forecast: "#7047EB",
  forecastConfidenceInterval: "rgba(0,0,0,.1)",
  forecastUpper: "#EA3EEE",
  forecastLower: "#00ccff",
  inventoryBar: "rgba(0,0,0,.1)",
  inventoryBarHover: "rgba(0,0,0,.2)",
  demandOverlay: "#EA3EEE",
};

interface TwColors {
  currentYear: string;
  currentYearBg: string;
  previousYear: string;
  previousYearBg: string;
  forecast: string;
  forecastBg: string;
  forecastConfidenceInterval: string;
  forecastConfidenceIntervalBg: string;
  forecastUpper: string;
  forecastUpperBg: string;
  forecastLower: string;
  forecastLowerBg: string;
  positive: string;
  negative: string;
  topPerformers: string;
  bottomPerformers: string;
  demandChart: string;
}

const TW_COLORS: TwColors = {
  currentYear: "base-text",
  currentYearBg: "bg-base-text",
  previousYear: "base-light",
  previousYearBg: "bg-base-light",
  forecast: "purple-bright",
  forecastBg: "bg-purple-bright",
  forecastConfidenceInterval: "darken-2",
  forecastConfidenceIntervalBg: "bg-darken-2",
  forecastUpper: "pink",
  forecastUpperBg: "bg-pink",
  forecastLower: "aqua",
  forecastLowerBg: "bg-aqua",
  positive: "bg-valence-positive",
  negative: "bg-valence-negative",
  topPerformers: "bg-valence-positive",
  bottomPerformers: "bg-valence-negative",
  demandChart: "bg-valence-positive",
};

export { calcYAxisTicks, DateTick, interpolatedDateTicks, HEX_COLORS, TW_COLORS };
