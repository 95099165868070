import { useState, useRef, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as Angledown } from "assets/images/angledown.svg";
import { IOption } from "types";
import useOutsideClick from "hooks/useOutsideClick";

export const Dropdown = ({
  options,
  option,
  onSelect,
  position,
  disabled,
}: {
  options: IOption[];
  option: IOption;
  onSelect: ({ key, value }: IOption) => void;
  position?: string;
  disabled?: boolean;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const childrenRef = useRef(null);

  useOutsideClick(childrenRef, () => {
    setDropdown(false);
  });

  const transitionClasses = {
    enter: "transform transition duration-[150ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[150ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  return (
    <div
      className="relative inline-block text-left font-mono text-xs text-[#6D6392]"
      ref={childrenRef}
    >
      <div>
        <button
          disabled={disabled}
          onClick={() => {
            !disabled && setDropdown(!dropdown);
          }}
          type="button"
          className={`inline-flex w-full justify-center items-center rounded-md px-2 py-1 border bg-base-lightwhite border-border-internal
            ${disabled ? "opacity-50" : "hover:bg-highlight-gentle"}
            transition-all
            focus:bg-highlight-gentle focus:outline-none focus:ring-2 focus:ring-[#4F369B] focus:ring-offset-2 focus:ring-offset-gray-100`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {option.value}
          <Angledown className={dropdown ? `rotate-180 transition-all` : `transition-all`} />
        </button>
      </div>
      <Transition show={dropdown} as={Fragment} {...transitionClasses}>
        <div
          className={`absolute ${position} right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-highlight-gentle shadow-lg overflow-hidden focus:outline-none border border-border-internal`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          {options
            //.filter((item) => item.key !== option.key)
            .map((item) => {
              return (
                <div
                  key={item.key}
                  className={`text-base-textmedium text-xs flex items-center cursor-pointer hover:bg-gray-300/30 h-6 px-2 transition-all ${
                    option.key === item.key
                      ? " bg-gray-300/30 text-purple-hover font-extrabold"
                      : "" // Highlight selected option
                  }`}
                  onClick={() => {
                    onSelect(item);
                    setDropdown(false);
                  }}
                >
                  {item.value}
                </div>
              );
            })}
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
