import React from "react";
import { useNavigate } from "react-router-dom";

// add color and other things

interface ButtonProps {
  action: () => void;
  text: string;
  className?: string;
}

export const Button = ({ text, action, className }: ButtonProps) => {
  return (
    <button
      className={`text-white bg-base-navtext/90 rounded-lg p-2.5 hover:bg-base-navtext w-1/2 ${className}`}
      onClick={action}
    >
      {text}
    </button>
  );
};

interface ILinkButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  url?: string;
}
export const LinkButton = ({ label, url, onClick }: ILinkButton) => {
  const navigate = useNavigate();
  return (
    <button
      className="rounded-[3px] py-0.5 px-1.5 bg-button-badge-bg text-button-badge-text text-sm underline"
      onClick={(e) => (url ? navigate(url) : onClick?.(e))}
    >
      {label}
    </button>
  );
};

// I'm making separate buttons here
// Ideally the index button component should have just a few named variations that are consumed elsewhere in the app
// otherwise there is just as much inconsistency

interface AccountButton {
  text: string;
  disabled?: boolean;
  specialOnclick?: () => void;
  type?: "submit" | "reset" | "button" | undefined;
  loading?: boolean;
}

const accountButtonStyle = "p-2 px-4 rounded-xl cursor-pointer";
const disabledStyle = "opacity-50 hover:border-white/10 cursor-default";

export const PrimaryAccountButton = (props: AccountButton) => {
  let disabled = props.loading || props.disabled;

  if (props.specialOnclick) {
    return (
      <button
        className={`bg-purple-base/90 border border-white/10 text-base-inverted hover:border-white/40 transition-all ${accountButtonStyle} ${
          disabled ? disabledStyle : ""
        }`}
        onClick={props.specialOnclick}
        disabled={disabled}
        type={props.type || "button"}
      >
        {props.text}
      </button>
    );
  } else {
    return (
      <button
        className={`bg-purple-base/90 border border-white/10 text-base-inverted hover:border-white/40 transition-all ${accountButtonStyle} ${
          disabled ? disabledStyle : ""
        }`}
        disabled={disabled}
        type={props.type || "button"}
      >
        {props.text}
      </button>
    );
  }
};

export const SecondaryAccountButton = (props: AccountButton) => {
  let disabled = props.loading || props.disabled;
  if (props.specialOnclick) {
    return (
      <button
        className={`bg-none text-base-inverted bg-white/10 border border-solid border-white/10 hover:border-white/40 ${accountButtonStyle} ${
          disabled ? disabledStyle : ""
        }`}
        disabled={disabled}
        type={props.type || "button"}
        onClick={props.specialOnclick}
      >
        {props.text}
      </button>
    );
  } else {
    return (
      <button
        className={`bg-none text-base-inverted bg-white/10 border border-solid border-white/10 hover:border-white/40 ${accountButtonStyle} ${
          disabled ? disabledStyle : ""
        }`}
        disabled={disabled}
        type={props.type || "button"}
      >
        {props.text}
      </button>
    );
  }
};
