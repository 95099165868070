import { EmailPasswordResetForm } from "pages/auth/set-password";
import Modal from "./BasicModal/index";

export const SetPasswordModal = ({
  visible,
  handleOk,
  handleCancel,
}: {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) => {
  const modalCustomFooter = [
    {
      label: "",
      action: () => false,
    },
  ];
  return (
    <Modal
      title="Expired Token 🤔"
      visible={visible}
      onOk={() => handleOk()}
      showCancel={true}
      customFooter={modalCustomFooter}
    >
      <div className="max-w-prose m-8">
        <div className="prose max-w-prose text-base-text py-4">
          <p>
            Try sending a new link with your Shopify email below. Or or try re-installing through
            the{" "}
            <a
              href="https://apps.shopify.com/bucephalus-inventory-alerts"
              target="_blank"
              rel="noreferrer"
              className="text-purple-base"
            >
              Shopify app store
            </a>
            .
          </p>
        </div>
        <EmailPasswordResetForm setVisible={handleOk} />
      </div>
    </Modal>
  );
};

export default SetPasswordModal;
