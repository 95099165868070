import { useEffect, MutableRefObject } from "react";

const useOutsideClick = <T extends MutableRefObject<any>>(ref: T, callback: () => void): void => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  // TODO? is this a custom hook? check if we can return ref, callback, etc.
};

export default useOutsideClick;
