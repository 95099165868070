import Modal from "./BasicModal/index";
import Button from "components/Button";
import { openInNewTab } from "utils/helper-ts";

interface ModalInterface {
  close: () => void;
  visible: boolean;
  sectionTitle: string;
}

export const FakeStoreModal = ({ sectionTitle, close, visible }: ModalInterface) => {
  return (
    <Modal title={sectionTitle} visible={visible} imageClassName={"mx-auto"} showSideBar={false}>
      <div className="w-full text-base-text px-8">
        <div>
          <span className="text-black text-base font-normal font-['Poppins']">
            We pulled your data from Shopify but it looks like you have{" "}
          </span>
          <span className="text-black text-base font-bold font-['Poppins']">no orders</span>
          <span className="text-black text-base font-normal font-['Poppins']">
            .<br />
            <br />
            We are currently only accepting{" "}
          </span>
          <span className="text-black text-base font-bold font-['Poppins']">active stores</span>
          <span className="text-black text-base font-normal font-['Poppins']">
            . If you are looking for a demo{" "}
          </span>
          <span className="text-black text-base font-bold font-['Poppins']">or</span>
          <span className="text-black text-base font-normal font-['Poppins']">
            {" "}
            you believe this is our error, please reach out in the chat below, or schedule a call
            with us.
          </span>
          <div className="py-3 flex justify-center">
            <Button
              className="w-[151px] h-[33px] px-5 py-1.5 bg-violet-800 rounded justify-center items-center gap-2 inline-flex"
              label="Schedule a Call"
              onClick={() => openInNewTab("https://calendly.com/bucephalus/chat-with-a-ds")}
              buttonType="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
