import client from "api";
import React, { useEffect, useState } from "react";

interface IStatesContext {
  rawState: string;
  updateRawState: React.Dispatch<React.SetStateAction<string>>;
}

export const StatesContext = React.createContext<IStatesContext>({
  rawState: "",
  updateRawState: () => {},
});

interface StatesContextProps {
  children: React.ReactNode;
}

export const StatesProvider = (props: StatesContextProps) => {
  const [rawState, setRawState] = useState("");

  /*   useEffect(() => {
    const getStates = async () => {
      try {
        const states = await client.get("/get-states");
        setRawState(JSON.stringify(states.data));
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    getStates();
  }, []); */

  return (
    <StatesContext.Provider
      value={{
        rawState,
        updateRawState: setRawState,
      }}
    >
      {props.children}
    </StatesContext.Provider>
  );
};
