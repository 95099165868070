import React, { useContext, useEffect, useState } from "react";
import { columns } from "components/ChooseColumns/ProductsChooseColumns";
import { type IObject, type IFilterPlus, AsyncStatus } from "types";
import { StatesContext } from "./States";
import { processAndSet, saveState } from "utils/helper-ts";

interface IProductContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  fProducts: string[];
  updateFProducts: (products: string[]) => void;
  fSelectedVariants: string[];
  updateFSelectedVariants: (fSelectedVariants: string[]) => void;
  productFilter: IFilterPlus[];
  updateProductFilter: (items: any) => void;
  isProductInitialized: boolean;
  updateIsProductInitialized: (isProductInitialized: boolean) => void;
  organizeByProducts: IObject;
  updateOrganizeByProducts: (organizeByProduct: IObject) => void;
  selColProducts: any[];
  updateSelColProducts: (columns: any[]) => void;
  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
}

export const ProductContext = React.createContext<IProductContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  fProducts: [],
  updateFProducts: () => {},
  fSelectedVariants: [],
  updateFSelectedVariants: () => {},
  productFilter: [{ key: "more", value: "Filter & Search", name: "Filter & Search" }],
  updateProductFilter: () => {},
  isProductInitialized: false,
  updateIsProductInitialized: () => {},
  selColProducts: [],
  updateSelColProducts: () => {},
  organizeByProducts: { key: "none", value: "None" },
  updateOrganizeByProducts: () => {},
  loading: AsyncStatus.Loading,
  updateLoading: () => {},
});

interface IProductContextProps {
  children: React.ReactNode;
}

export const ProductProvider = (props: IProductContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);
  const [fProducts, setFProducts] = useState<string[]>([]);
  const [fSelectedVariants, setFSelectedVariants] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<IFilterPlus[]>([
    { key: "more", value: "Filter & Search", name: "Filter & Search" },
  ]);
  const [isProductInitialized, setIsProductInitialized] = useState(false);
  const [organizeByProducts, setOrganizeByProducts] = useState<IObject>({
    key: "none",
    value: "None",
  });
  const [selColProducts, setSelColProducts] = useState<any[]>(columns);
  const [loading, setLoading] = useState(AsyncStatus.Loading);

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        processAndSet(parsed, "products_filter", setProductFilter);
      }
      setStatesFetched(true);
    }
  }, [rawState]);

  useEffect(() => {
    if (statesFetched) {
      saveState("products_filter", productFilter);
    }
  }, [JSON.stringify(productFilter), statesFetched]);

  return (
    <ProductContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        fProducts,
        updateFProducts: setFProducts,
        fSelectedVariants,
        updateFSelectedVariants: setFSelectedVariants,
        productFilter,
        updateProductFilter: setProductFilter,
        isProductInitialized,
        updateIsProductInitialized: setIsProductInitialized,
        organizeByProducts,
        updateOrganizeByProducts: setOrganizeByProducts,
        selColProducts,
        updateSelColProducts: setSelColProducts,
        loading,
        updateLoading: setLoading,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};
