import React, { RefObject, useEffect, useState, useRef, useContext } from "react";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import { Card, SimpleCard, CardDescription, EditableCard } from "components/Dashboard/Card";
import { useNavigate } from "react-router-dom";
import Dropdown, { POSITION } from "components/Dropdown";
import Slide from "components/Slider";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import useDebounce from "hooks/useDebounce";
import { getHighlightedText } from "utils/helper";
import { handleScrollTo, formatCurrency, formatNumber, validateNumber } from "utils/helper-ts";
import { IObject, IThreshold, IDays, AsyncStatus } from "types";
import { AppStateContext, ModalContext } from "context";
import { IUser } from "context/types";
import { Row, ModalRow } from "components/Organization/Row";
import { TVariant, IVariantDetail } from "types/ModalTypes";
import BreadCrumb from "components/Breadcrumb";
import client from "api";
import { MetricColumn } from "components/Dashboard/MetricHelpers";
import { VariantInventory } from ".";
import {
  DetailModalWrapper,
  DetailModalContent,
  DetailModalSidebar,
  DetailModalDemandTimeseries,
  DetailModalPerformanceKPI,
  DetailModalItemPillListDisplay,
} from ".";
import { SubmitHandler } from "react-hook-form";

export const VariantModal = ({
  close,
  visible,
  variant,
  setEdited,
  resetId,
}: {
  close: () => void;
  visible: boolean;
  variant: string;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { OpenModal } = useContext(ModalContext);

  const navigate = useNavigate();
  const { user } = useContext(AppStateContext);
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const avgLeadTimeRef = useRef<HTMLDivElement>(null);
  const unitsReceivedRef = useRef<HTMLDivElement>(null);
  const top = useRef<HTMLDivElement>(null);
  const [threshold, setThreshold] = useState<IThreshold>({ high: -1, low: -1 });
  const [inventoryDays, setInventoryDays] = useState<IDays>({ over: "90", forecast: "7" });
  const [variants, setVariants] = useState<TVariant[]>([]);
  const [variantId, setVariantId] = useState<string>("");
  // TODO: variantData object destruction
  const [variantData, setVariantData] = useState<IVariantDetail>({
    productTitle: "",
    category: "",
    pid: "",
    vid: "",
    variantTitle: "",
    sku: "",
    price: "",
    cost: "",
    marginRange: 0,
    tags: [],
    image: "",
    vendor: "",
    totalUnitsOnHand: "",
    inventoryStatus: "",
    averageLeadTime: "",
    sellThroughRate: "",
  });
  const [variantKeyword, setVariantKeyword] = useState<string>("");
  const variantSearchString = useDebounce(variantKeyword, 1000);
  const [variantsPage, setVariantsPage] = useState(0);

  useEffect(() => {
    if (variantData.pid && variantId) {
      getThresholds(variantData.pid, variantId);
    }
  }, [variantData, variantId]);

  useEffect(() => {
    if (variantId) {
      getVariants(variantId, 0, variantSearchString);
    }
  }, [variantId]);

  useEffect(() => {
    setVariantId(variant);
  }, [variant]);

  useEffect(() => {
    if (variantId) {
      getVariants(variantId, variantsPage < 0 ? 0 : variantsPage, variantSearchString);
    }
  }, [variantSearchString]);
  useEffect(() => {
    if (variantId) {
      getVariantModalInfo(variantId);
    }
  }, [variantId]);

  const getVariants = async (variantId: string, page: number, searchInfo: string) => {
    try {
      setLoading(AsyncStatus.Loading);
      const res = await client.get(`/get-variant-modal-variantslist`, {
        params: {
          variantId,
          page,
          searchInfo,
        },
      });
      if (page === 0) {
        setVariants(res.data.data);
      } else {
        setVariants((oldData: TVariant[]) => {
          const oldIdArray = oldData.map((oItem: TVariant) => oItem.vid);
          return [
            ...oldData,
            ...(res.data.data.filter((item: TVariant) => !oldIdArray.includes(item.vid)) || []),
          ];
        });
      }
      setVariantsPage(res.data.page || 0);
      setLoading(AsyncStatus.Loaded);
    } catch (err) {
      console.error(err);
      setLoading(AsyncStatus.Failed);
    }
  };

  const getThresholds = async (productId: string, variantId?: string) => {
    try {
      if (localStorage.getItem("shop")) {
        let res;
        if (variantId) {
          res = await client.get(`/thresholds/${productId}/${variantId}`);
        } else {
          res = await client.get(`/thresholds/${productId}`);
        }
        if (res.data && res.data.data) {
          setThreshold(res.data.data);
          // setValue("overstock", res.data.data.high, { shouldValidate: false });
          // setValue("understock", res.data.data.low, { shouldValidate: false });
        }
      }
    } catch (err: any) {
      // console.error(err);
      console.error(err);
    }
  };

  const getVariantModalInfo = async (variantId: string) => {
    try {
      const res = await client.get("/get-variant-modal-info", { params: { variantId } });
      if (res.data.data !== false) {
        setVariantData(res.data.data);
        // setValue("avgLeadTime", res.data.data.averageLeadTime, { shouldValidate: false });
        // setValue("numberOfUnitsReceived", res.data.data.sellThroughRate, { shouldValidate: false });
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleChangeDropdown = (key: string, value: IObject) => {
    setInventoryDays((prev) => ({ ...prev, [key]: value.key }));
  };

  const handleChangeVariant = (id: string) => {
    handleScrollTo(top);
    setVariantId(id);
  };

  const handleGoToProduct = () => {
    close();
    OpenModal("product", undefined, undefined, variantData.pid);
  };

  const handleGoToCategory = () => {
    if (variantData.category) {
      close();
      OpenModal("category", undefined, variantData.category);
    }
  };

  const handleClickBreadCrumb = (id: string) => {
    if (id === "product") {
      handleGoToProduct();
    } else if (id === "category") {
      handleGoToCategory();
    }
  };

  return (
    <DetailModalWrapper
      organizeBy="variant"
      loading={loading}
      visible={visible}
      resetId={resetId}
      handleGoToParent={variantData.pid ? handleGoToProduct : undefined}
      close={close}
      title={
        <VariantTitle
          data={variantData}
          loading={loading}
          handleClickBreadCrumb={handleClickBreadCrumb}
        />
      }
    >
      <DetailModalSidebar
        itemCount={variants.length}
        keyword={variantKeyword}
        type="variant"
        setKeyword={setVariantKeyword}
      >
        <VariantList
          variants={variants}
          variantId={variantId}
          handleChangeVariant={handleChangeVariant}
          variantKeyword={variantKeyword}
        />
      </DetailModalSidebar>
      <DetailModalContent>
        <ModalRow columns={4}>
          <div>
            <DetailModalPerformanceKPI id={variantId} type="variant" />
            <KeyQualities loading={loading} variantId={variantId} setEdited={setEdited} />
          </div>
          <div className="col-span-3">
            <DetailModalDemandTimeseries id={variantId} organizeBy="variant" />
          </div>
        </ModalRow>
        <ModalRow columns={3}>
          <div className="col-span-1">
            <VariantInfo
              variantData={variantData}
              user={user}
              variantId={variantId}
              unitsReceivedRef={unitsReceivedRef}
              avgLeadTimeRef={avgLeadTimeRef}
              loading={loading}
            />
          </div>
          <div className="col-span-2">
            <Slide images={[variantData.image]} loading={loading} />
          </div>
        </ModalRow>
        <ModalRow>
          <VariantInventory
            handleChangeDropdown={handleChangeDropdown}
            inventoryDays={inventoryDays}
            variantData={variantData}
            variantId={variantId}
            threshold={threshold}
            id={variantId}
            organizeBy="variant"
          />
        </ModalRow>
      </DetailModalContent>
    </DetailModalWrapper>
  );
};

const VariantTitle = ({
  data,
  loading,
  handleClickBreadCrumb,
}: {
  data: IVariantDetail; // this will need to be more flexible
  handleClickBreadCrumb: (id: string) => void;
  loading: AsyncStatus;
}) => {
  return (
    <>
      <>{data.variantTitle}</>
      <BreadCrumb
        data={[
          { name: data.category, type: "category" },
          { name: data.productTitle, type: "product" },
          { name: data.variantTitle, type: "variant" },
        ]}
        onClick={handleClickBreadCrumb}
        loading={loading}
      />
    </>
  );
};

export default VariantModal;

export const VariantList = ({
  variants,
  variantId,
  handleChangeVariant,
  variantKeyword,
}: {
  variants: TVariant[];
  handleChangeVariant: (vid: string) => void;
  variantKeyword: string;
  variantId: string;
}) => {
  return (
    <ul className="overflow-y-auto max-h-full flex flex-col gap-1 px-3">
      {variants.map(({ vid, title, sku }: TVariant) => (
        <li
          key={vid}
          className={`rounded-lg p-3 hover:bg-black/5 cursor-pointer transition-all
          ${vid === variantId ? "text-purple-base font-bold" : ""}
        `}
          onClick={() => handleChangeVariant(vid)}
        >
          <p className="overflow-hidden text-sm text-ellipsis">
            {getHighlightedText(title, variantKeyword)}
          </p>
          {sku !== "-" && (
            <p className="overflow-hidden text-xs text-ellipsis">
              {getHighlightedText(sku, variantKeyword)}
            </p>
          )}
        </li>
      ))}
    </ul>
  );
};

const VariantInfo = ({
  variantData,
  user,
  variantId,
  avgLeadTimeRef,
  unitsReceivedRef,
  loading,
}: {
  variantData: IVariantDetail;
  user: IUser;
  variantId: string;
  avgLeadTimeRef: RefObject<HTMLElement>;
  loading: AsyncStatus;
  unitsReceivedRef: RefObject<HTMLElement>;
}) => {
  let cost =
    variantData.cost === "-" || (variantData.cost == 0 && variantData.pid)
      ? "No costs added"
      : formatCurrency(variantData.cost, user.currency);

  const margin = formatNumber(variantData.marginRange, "percent");

  return (
    <div>
      <AreaTopBar areaName="Details"></AreaTopBar>
      <MetricColumn>
        <Card
          name="Parent Product"
          value={variantData.productTitle || "unknown"}
          loading={loading}
        />
        {variantData.sku === "-" ? null : (
          <Card name="SKU" value={variantData.sku} loading={loading} />
        )}
        <SimpleCard title="Tags" loading={loading}>
          <DetailModalItemPillListDisplay
            label="Tags"
            items={variantData.tags || []}
            loading={loading}
          />
        </SimpleCard>
        <Card
          loading={loading}
          name="Price"
          value={formatCurrency(variantData.price, user.currency)}
        />
        <Card name="Cost" value={cost} loading={loading} />
        <Card name="Margin" value={variantData.marginRange ? margin : "-"} loading={loading} />
      </MetricColumn>
    </div>
  );
};

const KeyQualities = ({
  variantId,
  loading,
  setEdited,
}: {
  variantId: string;
  loading: AsyncStatus;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useContext(AppStateContext);
  const [leadTime, setLeadTime] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [unitsToOrder, setUnitsToOrder] = useState("");
  const handleSubmit = async (
    type: string,
    data: any,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    await client.post(`/edit-all-vendor-data`, {
      filter: [{ variant: [variantId] }],
      value: parseInt(data.editedValue),
      type,
    });
    setEdited?.(true);
    setter(data.editedValue);
    return true;
  };

  const getKeyQualities = async (variantId: string) => {
    try {
      const res = await client.get(`/get-vendor-modal-key-qualities/?variantId=${variantId}`);
      setLeadTime(res.data.data.averageLeadTime || "-");
      setUnitsToOrder(res.data.data.averageUnitsToOrder || "-");
      setShippingCost(res.data.data.averageShippingCost || "-");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (variantId) {
      getKeyQualities(variantId);
    }
  }, [variantId]);

  const onSubmitLeadTime: SubmitHandler<any> = async (data) =>
    handleSubmit("vendorLeadTime", data, setLeadTime);
  const onSubmitShippingCost: SubmitHandler<any> = async (data) =>
    handleSubmit("shippingCost", data, setShippingCost);
  const onSubmitUnitsToOrder: SubmitHandler<any> = async (data) =>
    handleSubmit("unitsToOrder", data, setUnitsToOrder);

  return (
    <div>
      <AreaTopBar areaName="Key Qualities"></AreaTopBar>
      <MetricColumn>
        <EditableCard
          name={"Lead Time"}
          value={leadTime}
          loading={loading}
          onSubmit={onSubmitLeadTime}
          validator={validateNumber}
          unit={leadTime === "-" ? undefined : "Days"}
          isVariantLevel={true}
        />
      </MetricColumn>
    </div>
  );
};
