import { useState, useContext } from "react";
import { AppStateContext } from "context";
import type { FC, ReactNode } from "react";
import SideBar from "../components/SideBar";
import Mobile from "components/Others/desktop-only-warning";

export interface AdminLayoutProps {
  children: ReactNode;
}

const TableLayout: FC<AdminLayoutProps> = ({ children }) => {
  const { collapseSidebar, updateCollapseSidebar } = useContext(AppStateContext);

  const handleClose = () => {
    updateCollapseSidebar(!collapseSidebar);
  };

  return (
    <div className="flex min-h-screen bg-base-inverted">
      <Mobile />
      {<SideBar collapsed={collapseSidebar} close={handleClose} />}
      <div
        className={`${collapseSidebar ? "ml-top-bar" : "ml-left-nav"}
          overflow-auto flex-1`}
      >
        <div className={`z-0 max-h-[100vh] overflow-hidden`}>{children}</div>
      </div>
    </div>
  );
};

export default TableLayout;
