import Modal from "./BasicModal/index";
import Button from "components/Button";
import { openInNewTab } from "utils/helper-ts";

interface ModalInterface {
  close: () => void;
  visible: boolean;
  sectionTitle: string;
}

export const NoForecastModal = ({ sectionTitle, close, visible }: ModalInterface) => {
  return (
    <Modal title={sectionTitle} visible={visible} imageClassName={"mx-auto"} showBackground={true}>
      <div className="prose max-w-prose text-base-text p-8">
        <div className="w-[1/2]">
          <span className="text-black text-base font-normal">
            These pages rely heavily on generating a premium-quality demand forecast for your shop.
            We&#8217;re still working on yours right now. If you&#8217;d like to reach out and learn
            more about this process please reach out
          </span>
          <div className="py-3 flex justify-center">
            <Button
              className="w-[151px] h-[33px] px-5 py-1.5 bg-violet-800 rounded justify-center items-center gap-2 inline-flex"
              label="Schedule a Call"
              onClick={() => openInNewTab("https://calendly.com/bucephalus/chat-with-a-ds")}
              buttonType="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
