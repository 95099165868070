import { ApplyButton } from "components/Button";
import { ChangeEvent, useEffect, useState } from "react";
import { IFilterPlus } from "types";

export type NumericRangeTypes = "price" | "cost" | "sales" | "weight" | "unitsOnHand";

const COMPARISON_KEYWORD = [
  "no value",
  "is exactly",
  "is less than",
  "is greater than",
  "is between",
];

interface Props {
  onApply: () => void;
  updatePageFilter: (items: any) => void;
  type: NumericRangeTypes;
  disableApply: boolean;
  selected: string;
}

export const NumericRangeDropdown = ({
  onApply,
  updatePageFilter,
  type,
  disableApply,
  selected,
}: Props) => {
  const [comparisonType, setComparisonType] = useState("is exactly");
  const [comparisonValue, setComparisonValue] = useState({
    firstValue: 0,
    secondValue: 0,
  });

  const decodeComparison = (value: string) => {
    let comparisonType = "is exactly";
    let comparisonValue = { firstValue: 0, secondValue: 0 };

    if (value.startsWith(">")) {
      comparisonType = "is greater than";
      comparisonValue.firstValue = parseInt(value.slice(1), 10);
    } else if (value.startsWith("<")) {
      comparisonType = "is less than";
      comparisonValue.firstValue = parseInt(value.slice(1), 10);
    } else if (value.includes("-")) {
      comparisonType = "is between";
      const values = value.split("-");
      comparisonValue.firstValue = parseInt(values[0], 10);
      comparisonValue.secondValue = parseInt(values[1], 10);
    } else if (value.startsWith("=")) {
      comparisonType = "is exactly";
      comparisonValue.firstValue = parseInt(value.slice(1), 10);
    } else if (value === "no value") {
      comparisonType = "no value";
    } else {
      console.error("Unrecognized comparison format:", value);
      comparisonType = "is exactly";
      comparisonValue.firstValue = 0;
      return null;
    }
    setComparisonType(comparisonType);
    setComparisonValue(comparisonValue);
  };

  useEffect(() => {
    decodeComparison(selected);
  }, [selected]);

  const handleApply = () => {
    let value = "";
    switch (comparisonType) {
      case "no value":
        value = "no value";
        break;
      case "is less than":
        value = `<${comparisonValue.firstValue}`;
        break;
      case "is greater than":
        value = `>${comparisonValue.firstValue}`;
        break;
      case "is between":
        value = `${comparisonValue.firstValue} - ${comparisonValue.secondValue}`;
        break;
      case "is exactly":
      default:
        value = `=${comparisonValue.firstValue}`;
        break;
    }

    updatePageFilter((old: IFilterPlus[]) => {
      const updatedFilters = old.map((item: IFilterPlus) =>
        item.key === type ? { key: type, value: value, name: `${type}: ${value}` } : item
      );
      return updatedFilters;
    });

    onApply();
  };

  const handleChangeComparison = (key: string) => {
    setComparisonType(key);
  };

  const handleChangeComparisonValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setComparisonValue((old) => ({ ...old, [name]: +value || 0 }));
  };

  return (
    <div>
      {COMPARISON_KEYWORD.map(
        (keyword) =>
          ((type !== "unitsOnHand" && type !== "sales") || keyword != "no value") && (
            <div key={keyword}>
              <div className="flex items-center p-2.5">
                <input
                  type="radio"
                  value={keyword}
                  id={keyword}
                  name="comparison"
                  checked={keyword === comparisonType}
                  onChange={() => handleChangeComparison(keyword)}
                  className="
                border border-solid border-border-external rounded-full w-3 h-3 outline-none appearance-none
                focus:ring-0 focus:ring-transparent focus:ring-offset-transparent
                checked:border checked:border-solid checked:border-border-external checked:bg-dot
                checked:hover:border checked:hover:border-solid checked:hover:border-border-external
                checked:focus:border checked:focus:border-solid checked:focus:border-border-external
              "
                />
                <label htmlFor={keyword} className="pl-2.5 font-medium">
                  {keyword}
                </label>
              </div>

              {keyword !== "no value" && (
                <input
                  type="text"
                  name="firstValue"
                  value={comparisonValue.firstValue >= 0 ? comparisonValue.firstValue : 0}
                  onChange={handleChangeComparisonValue}
                  className={`h-8 rounded-xl outline-none font-medium text-purple-base bg-highlight-gentle ${
                    keyword !== comparisonType ? "hidden" : ""
                  }`}
                />
              )}
              {keyword === "is between" && comparisonType === keyword && (
                <>
                  <label htmlFor="between-and" className="inline-block p-2.5 font-medium">
                    and
                  </label>
                  <input
                    type="text"
                    id="between-and"
                    name="secondValue"
                    value={comparisonValue.secondValue}
                    onChange={handleChangeComparisonValue}
                    className="h-8 rounded-xl outline-none font-medium text-purple-base bg-highlight-gentle"
                  />
                </>
              )}
            </div>
          )
      )}

      <ApplyButton onClick={handleApply} disabled={disableApply} />
    </div>
  );
};

export default NumericRangeDropdown;
