import {
  CurrencyCell,
  DefaultCell,
  RegressionCell,
  DetailsCell,
  CellWrapper,
  RankCell,
  LoadingCell,
  PercentCell,
  DateCell,
  ImageCell,
} from "components/Table";
import { tdBorderStyle } from "utils/helper-ts";
import { Cell } from "@tanstack/react-table";
import { AsyncStatus } from "types";

export const TopPerformersCellMap = ({
  cell,
  currency,
  loading,
}: {
  cell: Cell<any, any>;
  currency: string;
  loading: AsyncStatus;
}) => {
  const { productId, productTitle, variantId } = cell.row.original;
  const accessorKey = (cell.column.columnDef as { accessorKey: string }).accessorKey;

  if (loading === AsyncStatus.Loading) {
    return <LoadingCell cell={cell} />;
  }

  switch (
    accessorKey // predefined cases
  ) {
    case "productTitle":
      if (productId !== "unknown") {
        return (
          <CellWrapper cell={cell} styles={tdBorderStyle}>
            <DetailsCell
              cell={cell}
              title={cell.getValue()}
              id={productId}
              vid={variantId}
              organizeBy={"product"}
              productTitle={productTitle}
            />
          </CellWrapper>
        );
      } else {
        return <DefaultCell cell={cell} />;
      }
    case "vendor":
      return (
        <CellWrapper cell={cell} styles={tdBorderStyle}>
          <DetailsCell
            cell={cell}
            title={cell.getValue()}
            id={productId}
            vid={variantId}
            organizeBy={"vendor"}
            productTitle={productTitle}
          />
        </CellWrapper>
      );
    case "image":
      return (
        <CellWrapper cell={cell} styles={tdBorderStyle}>
          {productTitle !== "Other Products" && <ImageCell cell={cell} />}
        </CellWrapper>
      );
    case "totalRevenue":
      return <CurrencyCell cell={cell} currency={currency} />;
    case "lastOrderDate":
      return <DateCell cell={cell} />;
    case "shareOfTotalRevenue":
      return <PercentCell cell={cell} />;
    case "revenue":
      return <CurrencyCell cell={cell} currency={currency} />;
    case "rank":
      return <RankCell cell={cell} wrapperStyle={tdBorderStyle} />;
    case "slope":
      return <RegressionCell cell={cell} currency={currency} wrapperStyle={tdBorderStyle} />;
    default:
      return <DefaultCell cell={cell} />;
  }
};
