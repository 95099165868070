import client from "api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PasswordStrengthBar } from "components/Others/ProgressBar";
import PasswordChecklist from "react-password-checklist";
import { PrimaryAccountButton, SecondaryAccountButton } from "components/Buttons";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeClosed } from "assets/images/eye-closed.svg";
import { ReactComponent as CheckMark } from "assets/images/check-in-circle.svg";
import { ReactComponent as XMark } from "assets/images/x-in-circle.svg";
import zxcvbn from "zxcvbn";
import { AuthForm, AuthFormPasswordItem } from "layouts/AuthLayout";

export const COMPLEXITY_THRESHOLD = 4;

export const PasswordForm = () => {
  const navigate = useNavigate();
  const [firstChange, setFirstChange] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [score, setScore] = useState<number>(0);
  const [score2, setScore2] = useState<number>(0);

  useEffect(() => {
    if (passwordMatched && score >= COMPLEXITY_THRESHOLD) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [password, password2, passwordMatched, score]);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setSubmitDisabled(true); // frontend check to ensure password is not resent
      const res = await client.post(`/changePassword`, { password: password });

      if (res) {
        toast.success("Password Changed!");
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("shop", res.data.shop);
        localStorage.setItem("shopId", res.data.shopId);
        localStorage.removeItem("changePassword");

        navigate("/");
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data);
    }
  };

  const checkPassword = (password: string) => {
    setFirstChange(true);
    setScore(zxcvbn(password).score);
    setPassword(password);
  };

  const checkPassword2 = (password: string) => {
    setFirstChange(true);
    setScore2(zxcvbn(password).score);
    setPassword2(password);
  };

  return (
    <form onSubmit={submitForm}>
      <div className="flex flex-col justify-center gap-10 w-80">
        <PasswordField
          password={password}
          score={score}
          checkPassword={checkPassword}
          firstChange={firstChange}
        />
        <PasswordField
          password={password2}
          score={score2}
          checkPassword={checkPassword2}
          firstChange={firstChange}
        />
        <PasswordChecklist
          rules={["match"]}
          className="flex items-center text-md text-base-text"
          value={password}
          valueAgain={password2}
          iconComponents={{
            ValidIcon: <CheckMark className="text-green-600 mr-3" />,
            InvalidIcon: <XMark className="text-red-400 mr-3" />,
          }}
          iconSize={15}
          onChange={(isValid) => {
            setPasswordMatched(isValid);
          }}
        />
        <button
          className="bg-base-text text-base-inverted p-2 px-4 rounded-xl cursor-pointer disabled:bg-slate-500 disabled:pointer-events-none"
          type="submit"
          disabled={submitDisabled}
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};
export const PasswordFormInverted = () => {
  const navigate = useNavigate();
  const [firstChange, setFirstChange] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [score, setScore] = useState<number>(0);
  const [score2, setScore2] = useState<number>(0);

  useEffect(() => {
    if (passwordMatched && score >= COMPLEXITY_THRESHOLD) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [password, password2, passwordMatched, score]);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setSubmitDisabled(true); // frontend check to ensure password is not resent
      const res = await client.post(`/changePassword`, { password: password });

      if (res) {
        toast.success("Password Changed!");
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("shop", res.data.shop);
        localStorage.setItem("shopId", res.data.shopId);
        localStorage.removeItem("changePassword");

        const dataPullVisited = await client.get("/data-pull-visited");
        if (dataPullVisited.data.data === null) {
          localStorage.setItem("lockPaymentInformation", "true");
          navigate("/payment-information");
        } else {
          navigate("/");
        }
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data);
    }
  };

  const checkPassword = (password: string) => {
    setFirstChange(true);
    setScore(zxcvbn(password).score);
    setPassword(password);
  };

  const checkPassword2 = (password: string) => {
    setFirstChange(true);
    setScore2(zxcvbn(password).score);
    setPassword2(password);
  };

  return (
    <AuthForm submit={submitForm}>
      <AuthFormPasswordItem
        label="password"
        value={password}
        changeHandler={checkPassword}
        score={score}
      />
      <AuthFormPasswordItem
        label="re-type password"
        value={password2}
        changeHandler={checkPassword2}
        score={score2}
      />

      <div className="bg-white/10 rounded-full px-2 py-1 w-fit">
        <PasswordChecklist
          rules={["match"]}
          className="flex items-center text-sm text-white"
          value={password}
          valueAgain={password2}
          iconComponents={{
            ValidIcon: <CheckMark className="text-green-600 mr-3" />,
            InvalidIcon: <XMark className="text-red-400 mr-3" />,
          }}
          iconSize={12}
          onChange={(isValid) => {
            setPasswordMatched(isValid);
          }}
        />
      </div>
      <div className="flex flex-row justify-end">
        <PrimaryAccountButton type={"submit"} disabled={submitDisabled} text={"Submit"} />
      </div>
    </AuthForm>
  );
};

export const PasswordField = ({
  password,
  checkPassword,
  firstChange,
  score,
  hideStrengthBar,
}: {
  password: string;
  checkPassword: (password: string) => void;
  firstChange?: boolean;
  score: number;
  hideStrengthBar?: boolean;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((passwordVisible) => !passwordVisible);
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex items-center gap-3 relative">
        <input
          value={password}
          onChange={(e) => checkPassword(e.target.value)}
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          className="input rounded-lg w-full"
        />
        <PasswordVisiblity
          passwordVisible={passwordVisible}
          togglePasswordVisibility={togglePasswordVisibility}
        />
      </div>
      {hideStrengthBar ? null : (
        <PasswordStrengthBar complexityThreshold={COMPLEXITY_THRESHOLD} score={score} />
      )}
      {firstChange ? <PasswordStrengthText score={score} /> : null}
    </div>
  );
};

export const PasswordFieldInverted = ({
  password,
  checkPassword,
  firstChange,
  score,
  hideStrengthBar,
}: {
  password: string;
  checkPassword: (password: string) => void;
  firstChange?: boolean;
  score: number;
  hideStrengthBar?: boolean;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((passwordVisible) => !passwordVisible);
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex items-center gap-3 relative">
        <input
          value={password}
          onChange={(e) => checkPassword(e.target.value)}
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          className="input rounded-lg w-full bg-white/60 text-base-text hover:border-white/40 focus:text-white clicked:bg-blue focus:bg-blue transition-all"
        />
        <PasswordVisiblity
          passwordVisible={passwordVisible}
          togglePasswordVisibility={togglePasswordVisibility}
        />
      </div>
      {hideStrengthBar ? null : (
        <PasswordStrengthBar complexityThreshold={COMPLEXITY_THRESHOLD} score={score} />
      )}
      {firstChange ? <PasswordStrengthText score={score} /> : null}
    </div>
  );
};

export const PasswordStrengthText = ({ score }: { score: number }) => {
  return (
    <>
      {score >= COMPLEXITY_THRESHOLD ? (
        <div className="text-green-600 text-xs transition-all">Password strong enough</div>
      ) : (
        <div className="text-red-400 text-xs transition-all">Password too weak</div>
      )}
    </>
  );
};

const PasswordVisiblity = ({
  togglePasswordVisibility,
  passwordVisible,
}: {
  togglePasswordVisibility: () => void;
  passwordVisible: boolean;
}) => {
  return (
    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="absolute bg-white/50 hover:bg-white transition-all rounded-md px-1 right-3 text-base-text/90"
    >
      {passwordVisible ? <Eye className="w-[18px]" /> : <EyeClosed className="w-[18px]" />}
    </button>
  );
};
