/** NEW TAB
 * Not sure of the origin of this, or how important it is, just cleaning functions that appear more than once.
 */

import { DATE_OPTIONS, FILTER_PLACEHOLDERS } from "./constants";
import {
  TimeRangeKey,
  type DemandMetric,
  type DemandMetricOptions,
  type IDateOption,
  type IObject,
} from "types";
import { EMAIL_REGEX, PASSWORD_REGEX } from "utils/constants";
import client from "api";
import { RefObject } from "react";
import { toast } from "react-toastify";
import { ColumnSort } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener, noreferrer");
};

/** STYLES
 * These are consistent patterns that don't seem to fit into tailwind easily
 * todo: see if there's an easy way to integrate
 */

export const truncateClass = "whitespace-nowrap text-ellipsis overflow-hidden max-w-[120px]";

export const brandGradient = {
  background: "linear-gradient(93.5deg, #391672 -64.74%, #8D6BD7 163.91%)",
};

export const blurBg = {
  backdropFilter: "blur(9px)",
  background: "rgba(246, 245, 251, .4)",
};

export const gentleBlurBg = {
  backdropFilter: "blur(5px)",
  // background: "rgba(246, 245, 251, .4)",
};

export const lightBlurBg = {
  backdropFilter: "blur(6px)",
  // background: "rgba(228, 224, 247, .3)",
  background: "rgba(0,0,0,.3)",
};

export const chartHeight = 320; // too hard to keep up to date with tailwind

// migrate to table styles
export let styleList = (styles: string[]) => styles.join(" ");
export let baseSpacing = "w-full h-full px-3 py-[.2em]";
export let baseWrapper = `${baseSpacing} flex items-center`;
export let baseInner = "px-1 py-1 truncate rounded-md";
export let hoverable = "hover:bg-base-lightwhite hover:transition-all hover:duration-300";
export let clickable =
  "cursor-pointer focus:bg-base-lightwhite hover:bg-base-lightwhite hover:transition-all hover:duration-300";
export let countPillStyles =
  "w-min whitespace-nowrap border border-internal text-gray-500 bg-base-lightwhite rounded-full px-2.5 text-xs";

export let tdBorderStyle = "border-b border-r border-darken-2"; // this is where borders definitely show up
export let rowGroupingBorder = "border-b border-b-border-external";
export const lastRowStyle = (cell: any) => (cell.row.original.lastRow ? rowGroupingBorder : "");

// wanted to use theme for consistency but its not working
// max-h-[calc(100vh-theme(height.top-bar)-theme(height.pagination)-theme(height.chart-height)]";
/**
 * top bar: 70
 * pagination: 35
 * chart: 320
 * chart border: 1
 */

export const baseTableHeight8 = "max-h-[calc(100vh-70px)] h-[calc(100vh-70px)]"; // new structure for tanstack8, will eventually be all pages
export const tableHeightWithChart8 = "max-h-[calc(100vh-371px)] h-[calc(100vh-371px)]"; // new structure for tanstack8, will eventually be all pages

export const tableHeightSelector8 = (showChart: boolean) =>
  `${showChart ? tableHeightWithChart8 : baseTableHeight8}`;

export const baseTableHeight = "max-h-[calc(100vh-105px)] h-[calc(100vh-105px)]";
export const tableHeightWithChart = "max-h-[calc(100vh-426px)] h-[calc(100vh-426px)]";

export const tableHeightSelector = (showChart: boolean) =>
  `${showChart ? tableHeightWithChart : baseTableHeight}`;

export const formatFilter = (filter: IObject[]) => {
  return filter
    .filter(
      (f: IObject) =>
        (f.key !== "more" || !FILTER_PLACEHOLDERS.includes(f.value)) &&
        !f.value.includes("- Nothing") &&
        !f.value.includes("- All")
    )
    .map((item: IObject) => ({
      [item.key]: item.value,
    }));
};

export function isEmailValid(email: string): boolean {
  if (!email) return false;

  if (email.length > 254) return false;

  const valid = EMAIL_REGEX.test(email);
  if (!valid) return false;

  // Further checking of some things regex can't handle
  const parts = email.split("@");
  if (parts[0].length > 64) return false;

  const domainParts = parts[1].split(".");
  if (domainParts.some((part) => part.length > 63)) return false;

  return true;
}

// TODO: Implement
export function isPasswordValid(password: string): boolean {
  if (!password || !PASSWORD_REGEX.test(password)) return false;

  return true;
}

export function getInventoryUrl(pid: string, pName: string, vid?: string, vName?: string) {
  return `/inventory?inventoryProduct=${pid}&pName=${pName}
  ${vid ? `vName ? &inventoryVariant=${vid}&vName=${vName}` : ""}`;
}

export const validateLength = (fieldName: string, value: string) => {
  if (value.length === 0) {
    return `${fieldName} should not be empty`;
  } else if (value.length > 256) {
    return `Maximum length is 256 characters`;
  }
  return true;
};

export const validateNumber = (value: string) => {
  const regex = /^\d+(\.\d+)?$/;
  if (!regex.test(value)) {
    return "Input should be a positive number";
  } else {
    return parseInt(value, 10) <= Number.MAX_SAFE_INTEGER || "Maximum value is 2147483647";
  }
};

export const validateSpecialCharacters = (fieldName: string, value: string) => {
  const regex = /[!@#$%^&*(),.?":{}|<>]/;
  if (regex.test(value)) {
    return `${fieldName} should not contain special characters`;
  }
  return true;
};

export const validateOriginality = async (request: string, value: string) => {
  try {
    const res = await client.get(request, { params: { name: value } });
    if (res?.data) {
      return true;
    }
    return "Same name already exists";
  } catch (error) {
    return "Unexpected error while validating originality";
  }
};

export const handleScrollTo = (ref: RefObject<HTMLElement>) => {
  ref.current?.scrollIntoView({
    behavior: "smooth",
    inline: "center",
    block: "nearest",
  });
};

export const getEmailInboxLink = (email: string) => {
  const domain = email.split("@")[1];
  if (domain.includes("gmail")) {
    return `https://mail.google.com/mail/u/${encodeURIComponent(email)}`;
  } else if (domain.includes("outlook") || domain.includes("hotmail")) {
    return `https://outlook.live.com/mail/0/inbox`;
  } else if (domain.includes("yahoo")) {
    return `https://mail.yahoo.com/d/folders/1`;
  } else if (domain.includes("aol")) {
    return `https://mail.aol.com/webmail-std/en-us/suite`;
  } else {
    return `https://www.google.com/search?q=${encodeURIComponent(email)}`;
  }
};

export function errorHandler(error: any): void {
  console.log(error);
  if (error?.response?.status === 402) {
    window.location.replace("/payment-information");
  }

  if (error?.response?.status === 401 && error?.code === "ERR_CANCELED") {
    return;
  }
  if (error?.code === "ECONNABORTED" && error?.message.includes("timeout")) {
    toast.info(
      `${
        error?.config?.url ? error?.config?.url : "This"
      } is taking a while. If something looks wrong, try refreshing the page.`,
      {
        progressClassName: "bg-gray-600",
      }
    );

    return;
  }
  console.log("error");
  if (error?.response?.data) {
    console.log(error.response.data);
    toast.error(error.response.data);
  } else if (error?.message) {
    toast.error(error.message);
  }
}

export const shopNameOnly = (shop: string): string => shop.split(".")[0];

// I've come to dispise this function
// I think there needs to input types and output types and an options object or something like that
type NumberFormat =
  | "number"
  | "percent"
  | "decimalShort"
  | "decimalToPercent"
  | "decimalToPercentWhole"
  | "formatPercentWhole"
  | "percentFloat"
  | "rate"
  | "rateFloat"
  | "currency"
  | "currencyRate"
  | "currencyCompact"
  | "default";

export function formatNumber(
  number: number,
  type: NumberFormat = "default",
  currency: string = "",
  denominator: string = "day",
  places: number = 0
) {
  // this check will become obsolete as the backend is fixed
  if (typeof number !== "number") {
    number = parseInt(number);
  }

  try {
    switch (type) {
      case "number":
        return formatNumberDefault(number);
      case "percent":
        return formatPercent(number);
      case "decimalShort":
        return decimalShort(number);
      case "decimalToPercent":
        return decimalToPercent(number);
      case "decimalToPercentWhole":
        return decimalToPercentWhole(number);
      case "formatPercentWhole":
        return formatPercentWhole(number);
      case "percentFloat":
        return formatPercentFloat(number);
      case "rate":
        return formatRate(number, denominator);
      case "rateFloat":
        return formatFloatRate(number, denominator, places);
      case "currency":
        return formatCurrency(number, currency);
      case "currencyRate":
        return formatCurrencyRate(number, currency, denominator);
      case "currencyCompact":
        return formatCurrency(number, currency, true);
      default:
        return formatNumberDefault(number);
    }
  } catch (e) {
    console.log(e);
    return "unknown";
  }
}

export function formatCurrency(
  value: number | string | null | undefined,
  currency: string,
  compact?: boolean,
  maximumFractionDigits: number = 0
): string {
  if (typeof value === "string") value = parseFloat(value);
  if (value === undefined || value === null) value = 0;

  if (isNaN(value as number)) return "-";

  if (value < 1000) {
    maximumFractionDigits = 2;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    maximumFractionDigits,
    notation: compact ? "compact" : undefined,
  }).format(value);
}

// FLOAT OR FRACTION TO PERCENT

export const formatNumberDefault = (number: number): string =>
  new Intl.NumberFormat("en-US").format(number);
export const decimalShort = (float: number): string => float.toFixed(1);
export const decimalToPercent = (float: number): string => formatPercent(float * 100);
export const decimalToPercentWhole = (float: number): string => formatPercentWhole(float * 100);
export const formatPercent = (number: number): string => number.toFixed(1) + "%";
export const formatPercentFloat = (number: number): string => number.toFixed(2) + "%";
export const formatPercentWhole = (number: number): string => number.toFixed(0) + "%";
export const formatRate = (number: number, denominator: string, places = 0): string =>
  number.toFixed(places) + "/" + denominator;
export const formatFloatRate = (number: number, denominator: string, places: number): string =>
  number.toFixed(places) + "/" + denominator;
export const formatCurrencyRate = (
  number: number,
  currency: string,
  denominator: string,
  places = 0
): string => formatCurrency(number, currency) + "/" + denominator;

export const objectsAreEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 *
 * The following two functions are used to manipulate the demand returns
 * They should eventually be removed entirely and the backend should just return the correct thing
 */

interface ChartData {
  accessorKey: string;
  header: string;
  chartQuantity: [ChartQuantity];
  chartRevenue: [ChartRevenue];
}

interface ChartQuantity {
  accessorKey: string;
  header: string;
  lower: string;
  upper: string;
}

interface ChartRevenue {
  accessorKey: string;
  header: number;
  lower: number;
  upper: number;
}

export const cloudfrontCacheBreak = () => {
  return "?date=" + new Date();
};

export const validateGeneral = (value: string) => {
  const lengthValidationResult = validateLength("Input", value);
  if (lengthValidationResult !== true) {
    return lengthValidationResult;
  }
  return validateNumber(value);
};

export const getISODate = (date: Date) => {
  return new Date(date.toISOString().slice(0, 10));
};

export const validatePositiveFloat = (value: string) => {
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue) || parsedValue < 0) {
    return "This should be a positive number";
  }

  if (parsedValue > Number.MAX_SAFE_INTEGER) {
    return "Maximum value is 2147483647";
  }

  return true;
};

export const validatePercentage = (value: string) => {
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return "This should be a number";
  }

  if (parsedValue < -100) {
    return "Minimum value is -100";
  }

  if (parsedValue > Number.MAX_SAFE_INTEGER) {
    return "Maximum value is 2147483647";
  }
  return true;
};

export const demandFormatSorting = (sorting: ColumnSort[]) => {
  const fortmattedSorting = sorting.map((sort: ColumnSort) => {
    return { ...sort, id: sort.id.replace("-historical", "").replace("-current", "") };
  });
  return fortmattedSorting;
};

export const formatMetrics = (metricOptions: DemandMetricOptions[]): DemandMetric[] => {
  return metricOptions.map((option) => option.key);
};

export function extractAdditionalProps(
  obj: { [key: string]: any },
  standardProps: string[]
): { [key: string]: any } {
  const additionalProps: { [key: string]: any } = {};

  for (const key in obj) {
    if (!standardProps.includes(key)) {
      additionalProps[key] = obj[key];
    }
  }

  return additionalProps;
}

export function validateEditedCells(editedCells: any[]) {
  let isValid: string | boolean = true;
  for (let i = 0; i < editedCells.length; i++) {
    const standardProps = ["productId", "variantId"];
    const additionalProps = extractAdditionalProps(editedCells[i], standardProps);

    for (let j = 0; j < Object.keys(additionalProps).length; j++) {
      if (typeof validateNumber(Object.values(additionalProps)[j]) === "string") {
        isValid = validateNumber(Object.values(additionalProps)[j]);
      }
    }
  }
  return isValid;
}

export function updateEditedCellsData(
  updateEditedCells: any,
  cell: any,
  value: any,
  original: any
) {
  updateEditedCells((prevState: any) => {
    const updatedCells = prevState.map((uCell: any) => {
      if (
        JSON.stringify({
          productId: uCell.productId,
          variantId: uCell.variantId,
          [cell.column.id]: null,
        }) ===
        JSON.stringify({
          productId: original.productId,
          variantId: original.variantId,
          [cell.column.id]: null,
        })
      ) {
        return { ...uCell, [cell.column.id]: value };
      }
      return uCell;
    });

    if (
      !updatedCells.some(
        (uCell: any) =>
          JSON.stringify({
            productId: uCell.productId,
            variantId: uCell.variantId,
            [cell.column.id]: null,
          }) ===
          JSON.stringify({
            productId: original.productId,
            variantId: original.variantId,
            [cell.column.id]: null,
          })
      )
    ) {
      updatedCells.push({
        productId: original.productId,
        variantId: original.variantId,
        [cell.column.id]: value,
      });
    }

    return updatedCells;
  });
}

export const processTime = (timeData: IDateOption, dateOptions: IDateOption[]) => {
  if (timeData.key === TimeRangeKey.Custom) {
    return {
      ...timeData,
      startDate: new Date(timeData.startDate),
      endDate: new Date(timeData.endDate),
    };
  }
  return dateOptions.find((item) => item.key === timeData.key);
};

export const saveState = async (column: string, state: any) => {
  try {
    await client.post("/save-state", { column, state });
  } catch (error) {
    console.error("Error saving state:", error);
  }
};

export function processAndSetTime(data: any, key: string, setTimeFunction: any, dateOptions: any) {
  try {
    if (!data[key]) {
      return;
    }
    const timeData = JSON.parse(data[key]);
    if (timeData) {
      const processedTime = processTime(timeData, dateOptions);
      if (processedTime) {
        setTimeFunction(processedTime);
      }
    }
  } catch (error) {
    console.log("couldnt parse ", error);
  }
}
export function processAndSet(data: any, key: string, setter: any, isbool?: boolean) {
  try {
    const parsedData = JSON.parse(data[key]);
    if (!data[key]) {
      return;
    }
    console.log("data[key] ", data[key]);
    if (isbool) {
      if (typeof parsedData === "boolean") {
        setter(parsedData);
      }
    } else {
      if (parsedData) {
        setter(parsedData);
      }
    }
  } catch (error) {
    console.log("couldnt parse ", error);
  }
}
