import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as InventoryStatus } from "assets/images/inventory_status.svg";
import { ReactComponent as Dollar } from "assets/images/dollar.svg";
import { ReactComponent as Rect } from "assets/images/rect.svg";
import { ReactComponent as Grid } from "assets/images/grid.svg";
import { ReactComponent as Variant } from "assets/images/variant.svg";
import { ReactComponent as Sales } from "assets/images/sales.svg";
import { ReactComponent as Truck } from "assets/images/truck.svg";
import { ReactComponent as Brand } from "assets/images/brand.svg";
import { ReactComponent as Image } from "assets/images/image.svg";
import { ReactComponent as Subject } from "assets/images/subject.svg";
import { ReactComponent as Barbell } from "assets/images/barbell.svg";
import { ReactComponent as Shop } from "assets/images/shop-w.svg";
import { ReactComponent as Champion } from "assets/images/champion.svg";
import { IObject } from "types";

interface Props {
  filter: IObject[];
  onSelect: ({ key, value }: IObject) => void;
  filterName: string;
}

export const FiltersDropdown = ({ onSelect, filter, filterName }: Props) => {
  const location = useLocation();
  const [filterList, setFilterList] = useState({});

  useEffect(() => {
    let filterList = {};
    switch (filterName) {
      case "products":
        filterList = {
          ITEMS: [
            { icon: <Grid className={"w-5 h-5"} />, key: "product", value: "Product" },
            { icon: <Variant className={"w-5 h-5"} />, key: "variant", value: "Variant" },
          ],
          SALES: [
            { icon: <Dollar className={"w-5 h-5"} />, key: "price", value: "Price" },
            { icon: <Dollar className={"w-5 h-5"} />, key: "cost", value: "Cost" },
            { icon: <Dollar className={"w-5 h-5"} />, key: "sales", value: "Sales" },
          ],
          QUALITIES: [
            { icon: <Image className={"w-5 h-5"} />, key: "hasImage", value: "Has image" },
            {
              icon: <Subject className={"w-5 h-5"} />,
              key: "hasDescription",
              value: "Has description",
            },
            { icon: <InventoryStatus className={"w-5 h-5"} />, key: "status", value: "Status" },
            { icon: <Barbell className={"w-5 h-5"} />, key: "weight", value: "Weight" },
          ],
          INVENTORY: [
            {
              icon: <InventoryStatus className={"w-5 h-5"} />,
              key: "inventoryStatus",
              value: "Inventory status",
            },
            { icon: <Shop className={"w-5 h-5"} />, key: "unitsOnHand", value: "Units on hand" },
            { icon: <Shop className={"w-5 h-5"} />, key: "location", value: "Location" },
          ],
        };
        break;
      case "demandSuite":
        filterList = {
          ORGANIZATION: [
            { icon: <Rect className={"w-5 h-5"} />, key: "category", value: "Categories" },
            { icon: <Grid className={"w-5 h-5"} />, key: "product", value: "Products" },
            { icon: <Variant className={"w-5 h-5"} />, key: "variant", value: "Variants" },
            { icon: <Variant className={"w-5 h-5"} />, key: "sku", value: "SKUs" },
            {
              icon: <Champion className={"w-5 h-5"} />,
              key: "topPerformers",
              value: "Top Performers",
            },
          ],

          QUALITIES: [
            { icon: <InventoryStatus className={"w-5 h-5"} />, key: "status", value: "Status" },
          ],
          SALES: [
            { icon: <Sales className={"w-5 h-5"} />, key: "sale", value: "Sales" },
            { icon: <Sales className={"w-5 h-5"} />, key: "noSale", value: "No Sales" },
          ],
          CHANNELS: [
            { icon: <Truck className={"w-5 h-5"} />, key: "vendor", value: "Vendors" },
            { icon: <Brand className={"w-5 h-5"} />, key: "tag", value: "Tags" },
          ],
        };
        break;
      case "inventory":
        filterList = {
          ORGANIZATION: [
            { icon: <Rect className={"w-5 h-5"} />, key: "category", value: "Categories" },
            { icon: <Grid className={"w-5 h-5"} />, key: "product", value: "Products" },
            { icon: <Variant className={"w-5 h-5"} />, key: "variant", value: "Variants" },
            { icon: <Variant className={"w-5 h-5"} />, key: "sku", value: "SKUs" },
            {
              icon: <Champion className={"w-5 h-5"} />,
              key: "topPerformers",
              value: "Top Performers",
            },
          ],

          QUALITIES: [
            { icon: <InventoryStatus className={"w-5 h-5"} />, key: "status", value: "Status" },
          ],
          SALES: [
            { icon: <Sales className={"w-5 h-5"} />, key: "sale", value: "Sales" },
            { icon: <Sales className={"w-5 h-5"} />, key: "noSale", value: "No Sales" },
          ],
          CHANNELS: [
            { icon: <Truck className={"w-5 h-5"} />, key: "vendor", value: "Vendors" },
            { icon: <Brand className={"w-5 h-5"} />, key: "tag", value: "Tags" },
          ],
        };
        break;
      case "planning":
        filterList = {
          ORGANIZATION: [
            { icon: <Rect className={"w-5 h-5"} />, key: "category", value: "Categories" },
            { icon: <Grid className={"w-5 h-5"} />, key: "product", value: "Products" },
            { icon: <Variant className={"w-5 h-5"} />, key: "variant", value: "Variants" },
            { icon: <Variant className={"w-5 h-5"} />, key: "sku", value: "SKUs" },
            {
              icon: <Champion className={"w-5 h-5"} />,
              key: "topPerformers",
              value: "Top Performers",
            },
            {
              icon: <Champion className={"w-5 h-5"} />,
              key: "recommendedPurchases",
              value: "Recommended Purchases",
            },
          ],

          QUALITIES: [
            { icon: <InventoryStatus className={"w-5 h-5"} />, key: "status", value: "Status" },
          ],
          SALES: [
            { icon: <Sales className={"w-5 h-5"} />, key: "sale", value: "Sales" },
            { icon: <Sales className={"w-5 h-5"} />, key: "noSale", value: "No Sales" },
          ],
          CHANNELS: [
            { icon: <Truck className={"w-5 h-5"} />, key: "vendor", value: "Vendors" },
            { icon: <Brand className={"w-5 h-5"} />, key: "tag", value: "Tags" },
          ],
        };
        break;
      case "overrideReference":
        filterList = {
          ORGANIZATION: [
            { icon: <Rect className={"w-5 h-5"} />, key: "category", value: "Categories" },
            { icon: <Grid className={"w-5 h-5"} />, key: "product", value: "Products" },
            { icon: <Variant className={"w-5 h-5"} />, key: "variant", value: "Variants" },
            { icon: <Variant className={"w-5 h-5"} />, key: "sku", value: "SKUs" },
            {
              icon: <Champion className={"w-5 h-5"} />,
              key: "topPerformers",
              value: "Top Performers",
            },
          ],

          QUALITIES: [
            { icon: <InventoryStatus className={"w-5 h-5"} />, key: "status", value: "Status" },
          ],
          SALES: [
            { icon: <Sales className={"w-5 h-5"} />, key: "sale", value: "Sales" },
            { icon: <Sales className={"w-5 h-5"} />, key: "noSale", value: "No Sales" },
          ],
          CHANNELS: [
            { icon: <Truck className={"w-5 h-5"} />, key: "vendor", value: "Vendors" },
            { icon: <Brand className={"w-5 h-5"} />, key: "tag", value: "Tags" },
          ],
        };
        break;
      default:
        filterList = {};
        break;
    }
    setFilterList(filterList);
  }, [location.pathname]);

  const isDisabled = (key: string) => {
    if (filter.findIndex((item) => item.key === key) > -1) {
      return true;
    } else if (key === "noSale") {
      return filter.findIndex((item) => item.key === "sale") > -1;
    } else if (key === "sale") {
      return filter.findIndex((item) => item.key === "noSale") > -1;
    }
  };

  const handleSelect = ({ key, value }: IObject) => {
    if (!isDisabled(key)) {
      onSelect?.({ key, value });
    }
  };

  return (
    <div className="columns-2 w-96">
      {Object.entries(filterList).map(([key, value]: [string, any]) => (
        <div key={key} className="py-4 inline-block w-full">
          <p className="text-light">{key}</p>
          <ul className="pt-2.5 ">
            {value.map((item: any) => (
              <li
                key={item.key}
                className={`flex items-center py-2 ${
                  isDisabled(item.key)
                    ? "cursor-default text-neutral-500"
                    : "cursor-pointer text-neutral-100"
                }`}
                onClick={() => handleSelect({ key: item.key, value: item.value })}
              >
                {item.icon}
                <span className="ml-2.5 text-xs font-medium">{item.value}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FiltersDropdown;
