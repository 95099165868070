import React, { useContext, useState } from "react";
import { FORECAST_OVERRIDE_OPTIONS } from "utils/constants";
import { AppStateContext } from "context";

import AreaTopBar from "../Dashboard/AreaTopBar";
import { MetricColumnArea } from "../Dashboard/MetricHelpers";
import { AsyncStatus, IObject } from "types";
import { validatePercentage, validatePositiveFloat } from "utils/helper-ts";
import { UseFormSetValue } from "react-hook-form";
import InputCard from "./Card";

export const ForecastOverrides = ({
  overrideUnit,
  setOverrideUnit,
  control,
  errors,
  setValue,
  originalValue,
  watch,
}: {
  overrideUnit: IObject;
  setOverrideUnit: React.Dispatch<React.SetStateAction<IObject>>;
  control: any;
  setValue: UseFormSetValue<any>;
  originalValue: string;
  errors: any;
  watch: any;
}) => {
  const { user } = useContext(AppStateContext);
  const [loading, setLoading] = useState(AsyncStatus.Loaded);

  return (
    <div className="bg-highlight-gentle rounded-md my-2">
      <InputCard
        name="New forecast value"
        value={watch("change")}
        loading={loading}
        inputName="change"
        control={control}
        setValue={setValue}
        validator={overrideUnit.key === "percentage" ? validatePercentage : validatePositiveFloat}
        originalValue={originalValue}
        errors={errors}
        options={FORECAST_OVERRIDE_OPTIONS}
        selected={overrideUnit}
        setSelected={setOverrideUnit}
      />
    </div>
  );
};

export default ForecastOverrides;
