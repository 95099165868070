import { ImageLoadingWrapper, UnitLoadingWrapper } from "components/Loading";
import { useState } from "react";
import { AsyncStatus } from "types";

export const Slide = ({ images, loading }: { loading: AsyncStatus; images: string[] }) => {
  const [brokenImages, setBrokenImages] = useState(0);

  const handleImageError = () => {
    setBrokenImages((prev) => prev + 1);
  };

  return (
    <div className="flex flex-col h-[600px] rounded-md w-full min-w-[200px]">
      <div className="flex items-center h-full gap-3 max-w-3/4 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        <ImageLoadingWrapper loading={loading}>
          {images.map((image: string, index: number) => (
            <img
              key={image + index}
              src={image}
              className="h-full rounded-md"
              onError={handleImageError}
            ></img>
          ))}
        </ImageLoadingWrapper>
      </div>
      <div className="flex items-center text-xs font-mono m-4 mx-auto w-fit max-w-[600px] overflow-x-auto">
        <UnitLoadingWrapper loading={loading}>
          {images.length - brokenImages > 1 ? (
            <div>{images.length - brokenImages + " Images, scroll right for more →"}</div>
          ) : (
            <div>{images.length - brokenImages} Image(s) </div>
          )}
        </UnitLoadingWrapper>
      </div>
    </div>
  );
};

export default Slide;
