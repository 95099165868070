import React, { ReactNode, useEffect, useState, useRef, ChangeEvent, useContext } from "react";
import { useForm } from "react-hook-form";
import { PrimaryAccountButton } from "components/Buttons";
import Modal from "../../BasicModal/index";
import client from "api";
import { IObject, IVariant, IThreshold, IDays } from "types";

// UNDER CONSTRUCTION
// DON'T EMULATE THIS PATTERN
// here I'm just repurposing other code for this edit modal

interface IVariantDetail {
  productTitle: string;
  category: string;
  pid: string;
  vid: string;
  variantTitle: string;
  sku: string;
  price: string | number;
  cost: string | number;
  vendor: string;
  tags: string[];
  marginRange: string | number;
  image: string;
  totalUnitsOnHand: string | number;
  inventoryStatus: string;
  sellThroughRate: number | string;
  averageLeadTime: number | string;
}

type FormValues = {
  avgLeadTime: string;
  overstock: string;
  understock: string;
  numberOfUnitsReceived: string;
  dateOfUnitsReceived: string;
};

export const UpdateLowStockModal = ({
  close,
  variantTitle,
  productTitle,
  visible,
  product,
  variant,
  low,
  resetId,
}: {
  close: () => void;
  variantTitle: string;
  productTitle: string;
  visible: boolean;
  product: IObject;
  variant: string;
  low: string;
  resetId?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [threshold, setThreshold] = useState<IThreshold>({ high: -1, low: low });
  const [lowStockThreshold, setLowStockThreshold] = useState<string | number>(low);
  const [variantId, setVariantId] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  // TODO: variantData object destruction
  const [variantData, setVariantData] = useState<IVariantDetail>({
    productTitle: "",
    category: "",
    pid: "",
    vid: "",
    variantTitle: "",
    sku: "",
    price: "",
    cost: "",
    marginRange: "",
    tags: [],
    image: "",
    vendor: "",
    totalUnitsOnHand: "",
    inventoryStatus: "",
    averageLeadTime: "",
    sellThroughRate: "",
  });

  const getVariantModalInfo = async (variantId: string) => {
    try {
      const res = await client.get("/get-variant-modal-info", { params: { variantId } });
      if (res.data.data !== false) {
        setVariantData(res.data.data);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const getThresholds = async (productId: string, variantId?: string) => {
    try {
      if (localStorage.getItem("shop")) {
        let res;
        if (variantId) {
          res = await client.get(`/thresholds/${productId}/${variantId}`);
        } else {
          res = await client.get(`/thresholds/${productId}`);
        }
        if (res.data && res.data.data) {
          setThreshold(res.data.data);
        }
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    setVariantId(variant);
  }, [variant]);

  useEffect(() => {
    if (variantData.pid && variantId) {
      getThresholds(variantData.pid, variantId);
    }
  }, [variantData, variantId]);

  useEffect(() => {
    if (variantId) {
      getVariantModalInfo(variantId);
    }
  }, [variantId]);

  const handleSetLowStockThreshold = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (localStorage.getItem("shop") && typeof lowStockThreshold === "string") {
        const res = await client.post(`/thresholds`, {
          productId: variantData.pid || product.key,
          variantId,
          high: 999999, // spoofing the api right now
          low: parseInt(lowStockThreshold),
        });
        setLoading(false);
        close();
        window.location.reload();
        return res;
      }
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  // why is this called check
  const checkLowStockThreshold = (threshold: string) => {
    setLowStockThreshold(threshold);
  };

  return (
    <Modal
      title={"Update Low Stock Threshold"}
      titleClassName="overflow-hidden text-ellipsis whitespace-nowrap flex-1 mr-20"
      visible={visible}
      imageClassName={"mx-auto"}
      imageDescClassName={"text-center text-sm"}
      onCancel={() => {
        resetId?.("");
        close();
      }}
    >
      <div className="flex flex-col w-full my-6">
        <form onSubmit={handleSetLowStockThreshold}>
          <div className="flex flex-col justify-center space-y-4">
            <div className="flex flex-col w-full gap-3">
              <label>Product: {productTitle}</label>
              <label>Variant: {variantTitle}</label>
              <label>{"Update Low Stock Threshold"}</label>
              <input
                type="number"
                min="0"
                value={lowStockThreshold}
                onChange={(e) => checkLowStockThreshold(e.target.value)}
                className={`rounded-xl border-border-internal px-4 py-3 bg-widget text-sm`}
                placeholder={threshold.low.toString() || "Set low stock threshold"}
              />
              <PrimaryAccountButton type={"submit"} text={"Update"} />
            </div>
          </div>
        </form>
        {isLoading ? "loading!!" : null}
      </div>
    </Modal>
  );
};

export default UpdateLowStockModal;
