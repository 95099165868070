import Modal from "./BasicModal/index";

export const DemandForecastNotificationModal = ({
  visible,
  handleOk,
}: {
  visible: boolean;
  handleOk: () => void;
}) => {
  return (
    <Modal visible={visible} title="Notification" size="medium" onOk={handleOk} onCancel={handleOk}>
      <p>{`Category and multiple variant filters are not compatible with our forecasting feature at this time.`}</p>
    </Modal>
  );
};

export default DemandForecastNotificationModal;
