import React, { useState } from "react";

interface IRequestContext {
  pendingRequests: number;
  updatePendingRequests: React.Dispatch<React.SetStateAction<number>>;
}

export const RequestContext = React.createContext<IRequestContext>({
  pendingRequests: 0,
  updatePendingRequests: () => {},
});

interface RequestContextProps {
  children: React.ReactNode;
}

export const RequestProvider = (props: RequestContextProps) => {
  const [pendingRequests, setPendingRequests] = useState(0);

  return (
    <RequestContext.Provider
      value={{
        pendingRequests,
        updatePendingRequests: setPendingRequests,
      }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};
