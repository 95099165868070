import { useState, useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Modal from "./BasicModal/index";
import client from "api";
import { validateLength, validateNumber } from "utils/helper-ts";
import Button from "components/Button";
import TextInput from "components/FormInputs/TextInput";
import Loading from "components/Loading";
import { PlanningContext } from "context/planning";
import { InventoryContext } from "context";

interface FormValues {
  value: string;
}

export const updateCellModal = ({
  close,
  visible,
  title,
  value,
  type,
  product,
  variant,
}: {
  close: () => void;
  visible: boolean;
  title: string;
  value: string;
  type: "vendorLeadTime" | "low";
  product: string;
  variant: string;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: { value },
  });
  const { rows: inventoryRows, updateRows: updateInventoryRows } = useContext(InventoryContext);

  const { rows: planningRows, updateRows: updatePlanningRows } = useContext(PlanningContext);

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSetLowStockThreshold = async (product: string, variant: string, value: string) => {
    try {
      setLoading(true);
      if (localStorage.getItem("shop")) {
        const res = await client.post(`/thresholds`, {
          productId: product,
          variantId: variant,
          high: 999999, // spoofing the api right now
          low: parseInt(value),
        });

        const index = inventoryRows.findIndex(
          (item: any) => item.pid === product && item.vid === variant
        );

        if (index !== -1) {
          const updatedData = [...inventoryRows];
          updatedData[index].low = value;

          updateInventoryRows(updatedData);
        } else {
          console.log("Row not found");
        }

        setLoading(false);
        return res;
      }
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSetVendorLeadTime = async (product: string, variant: string, value: string) => {
    try {
      setLoading(true);

      const index = planningRows.findIndex(
        (item: any) => item.pid === product && item.vid === variant
      );

      if (index !== -1) {
        const updatedData = [...planningRows];
        updatedData[index].vendorLeadTime = value;

        updatePlanningRows(updatedData);
      } else {
        console.log("Row not found");
      }

      /* if (localStorage.getItem("shop")) {
        const res = await client.post(`/vendorLeadTime`, {
          productId: product,
          variantId: variant,
          value: parseInt(value),
        });
        setLoading(false);
        return res;
      } */
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async ({ value }) => {
    if (type === "low") {
      await handleSetLowStockThreshold(product, variant, value);
    } else if (type === "vendorLeadTime") {
      await handleSetVendorLeadTime(product, variant, value);
    }

    close();
  };

  const validateGeneral = (value: string) => {
    const lengthValidationResult = validateLength("Input", value);
    if (lengthValidationResult !== true) {
      return lengthValidationResult;
    }
    return validateNumber(value);
  };

  return (
    <Modal
      title={"Update " + title}
      titleClassName="overflow-hidden text-ellipsis whitespace-nowrap flex-1 mr-20"
      visible={visible}
      onCancel={() => {
        close();
      }}
    >
      <div className="flex flex-col w-full my-6 px-6">
        <div className="flex flex-col justify-center space-y-4">
          <div className="flex flex-col w-full gap-3">
            <form className="flex items-center">
              <div className="pr-2"> {title}</div>
              <Controller
                name="value"
                control={control}
                rules={{ validate: validateGeneral }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="text"
                    placeholder={title}
                    errorMessage={errors.value?.message}
                  />
                )}
              />
            </form>
            {isLoading ? (
              <Loading />
            ) : (
              <Button buttonType="primary" label="Update" onClick={handleSubmit(onSubmit)} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default updateCellModal;
