import React, { useContext, useEffect, useState } from "react";
import {
  IKPI,
  ISalesSummary,
  IInventoryStatus,
  ICashflowSummary,
  IInventorySummary,
  ITopPerformers,
  IBottomPerformers,
  IOrgByOption,
  ITopPerfCriteria,
  IDateOption,
  TimeRangeKey,
  IOption,
} from "types";
import {
  DATE_OPTIONS,
  DASHBOARD_INVENTORY_OPTIONS,
  DASHBOARD_BOTTOM_CRITERIA_OPTIONS,
  DASHBOARD_TOP_CRITERIA_OPTIONS,
  IBottomPerfCriteria,
  DATE_OPTIONS_FORECAST,
  TIME_SERIES_OPTIONS,
  DATE_FREQUENCY_TIME_SERIES,
} from "utils/constants";
import { addMonths, subMonths } from "date-fns";
import client from "api";
import { StatesContext } from "./States";
import { processAndSet, processAndSetTime, processTime, saveState } from "utils/helper-ts";

interface IDashboardContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  //KPI
  KPI: IKPI;
  updateKPI: (KPI: IKPI) => void;
  KPITime: IDateOption;
  updateKPITime: (KPITime: IDateOption) => void;
  //ABC
  abcTime: IDateOption;
  updateAbcTime: (abcTime: IDateOption) => void;
  //sales summary
  salesSummary: ISalesSummary;
  updateSalesSummary: (salesSummary: ISalesSummary) => void;
  salesTime: IDateOption;
  updateSalesTime: (salesTime: IDateOption) => void;
  //inventory status
  inventoryStatus: IInventoryStatus;
  updateInventoryStatus: (inventoryStatus: IInventoryStatus) => void;
  inventoryStatusOption: IOrgByOption;
  updateInventoryStatusOption: (inventoryStatusOption: IOrgByOption) => void;
  //inventory Summary
  inventorySummary: IInventorySummary;
  updateInventorySummary: (inventorySummary: IInventorySummary) => void;
  //cashflow summary
  cashflowSummary: ICashflowSummary;
  updateCashflowSummary: (cashflow: ICashflowSummary) => void;
  cashflowSummaryTime: IDateOption;
  updateCashflowSummaryTime: (cashflowSummaryTime: IDateOption) => void;
  //top performers
  topPerformers: ITopPerformers[];
  updateTopPerformers: (topPerformers: ITopPerformers[]) => void;
  topPerfTime: IDateOption;
  updateTopPerfTime: (topPerfTime: IDateOption) => void;
  topPerfCriteria: ITopPerfCriteria;
  updateTopPerfCriteria: (topPerfCriteria: ITopPerfCriteria) => void;
  topPerfOption: IOrgByOption;
  updateTopPerfOption: (topPerfOption: IOrgByOption) => void;
  //bottom performers
  bottomPerformers: IBottomPerformers[];
  updateBottomPerformers: (bottomPerformers: IBottomPerformers[]) => void;
  bottomPerfCriteria: IBottomPerfCriteria;
  updateBottomPerfCriteria: (bottomPerfCriteria: IBottomPerfCriteria) => void;
  bottomPerfOption: IOrgByOption;
  updateBottomPerfOption: (bottomPerfOrganize: IOrgByOption) => void;
  //time series
  timeSeriesTime: IDateOption;
  updatTimeSeriesTime: (KPITime: IDateOption) => void;
  timeSeriesType: IOption;
  updateTimeSeriesType: React.Dispatch<React.SetStateAction<IOption>>;
  timeSeriesFrequency: IOption;
  updateTimeSeriesFrequency: React.Dispatch<React.SetStateAction<IOption>>;
}
export const DashboardContext = React.createContext<IDashboardContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  //KPI
  KPI: {
    Sales: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    TotalOrders: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    AOV: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    TotalVariantsWithLowStockThreshold: {
      value: 0,
    },
    TotalVariants: {
      value: 0,
    },
    OOSStockVariants: {
      value: 0,
    },
    Cost: {
      value: 0,
    },
    Discount: {
      value: 0,
    },
    GrossProfitMargin: {
      value: 0,
    },
    TotalActiveAlerts: {
      value: 0,
    },
  },
  updateKPI: () => {},
  KPITime: DATE_OPTIONS[1],
  updateKPITime: () => {},
  //abc
  abcTime: DATE_OPTIONS[1],
  updateAbcTime: () => {},
  //sales summary
  salesSummary: {
    revenue: 0,
    profit: 0,
    pendingOrders: 0,
    allOrders: 0,
    paidOrders: 0,
    COGs: 0,
    refundedOrders: 0,
  },
  updateSalesSummary: () => {},
  salesTime: DATE_OPTIONS[1],
  updateSalesTime: () => {},
  //inventory status
  inventoryStatus: {
    healthy: 0,
    lowStock: 0,
    outOfStock: 0,
    all: 0,
  },
  updateInventoryStatus: () => {},
  inventoryStatusOption: DASHBOARD_INVENTORY_OPTIONS[0],
  updateInventoryStatusOption: () => {},
  //inventory Summary
  inventorySummary: {
    stockToPurchase: 0,
    lockedCapital: 0,
    stockOnHand: 0,
    onHandRetailValue: 0,
    forecastVariance: 0,
  },
  updateInventorySummary: () => {},
  //cashflow summary
  cashflowSummary: {
    grossProfitMargin: 0,
    workingCapitalRatio: 0,
    operatinCashflow: 0,
    freeCashflow: 0,
    forecastVariance: 0,
  },
  updateCashflowSummary: () => {},
  cashflowSummaryTime: DATE_OPTIONS[1],
  updateCashflowSummaryTime: () => {},
  //top performers
  topPerformers: [],
  updateTopPerformers: () => {},
  topPerfTime: DATE_OPTIONS[1],
  updateTopPerfTime: () => {},
  topPerfCriteria: DASHBOARD_TOP_CRITERIA_OPTIONS[0],
  updateTopPerfCriteria: () => {},
  topPerfOption: DASHBOARD_INVENTORY_OPTIONS[2],
  updateTopPerfOption: () => {},
  //bottom performers
  bottomPerformers: [],
  updateBottomPerformers: () => {},
  bottomPerfCriteria: DASHBOARD_BOTTOM_CRITERIA_OPTIONS[0],
  updateBottomPerfCriteria: () => {},
  bottomPerfOption: DASHBOARD_INVENTORY_OPTIONS[2],
  updateBottomPerfOption: () => {},
  //time series
  timeSeriesTime: DATE_OPTIONS_FORECAST[1],
  updatTimeSeriesTime: () => {},
  timeSeriesType: TIME_SERIES_OPTIONS[0],
  updateTimeSeriesType: () => {},
  timeSeriesFrequency: DATE_FREQUENCY_TIME_SERIES[0],
  updateTimeSeriesFrequency: () => {},
});

interface IDashboardContextProps {
  children: React.ReactNode;
}

export const DashboardProvider = (props: IDashboardContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);
  //KPI
  const [KPI, setKPI] = useState<IKPI>({
    Sales: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    TotalOrders: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    AOV: {
      value: 0,
      change: 0,
      changeType: "up",
    },
    TotalVariantsWithLowStockThreshold: {
      value: 0,
    },
    TotalVariants: {
      value: 0,
    },
    OOSStockVariants: {
      value: 0,
    },
    Cost: {
      value: 0,
    },
    Discount: {
      value: 0,
    },
    GrossProfitMargin: {
      value: 0,
    },
    TotalActiveAlerts: {
      value: 0,
    },
  });
  const [KPITime, setKPITime] = useState(DATE_OPTIONS[2]);
  //abc
  const [abcTime, setAbcTime] = useState(DATE_OPTIONS[2]);
  //sales summary
  const [salesSummary, setSalesSummary] = useState<ISalesSummary>({
    revenue: 0,
    profit: 0,
    pendingOrders: 0,
    allOrders: 0,
    paidOrders: 0,
    COGs: 0,
    refundedOrders: 0,
  });
  const [salesTime, setSalesTime] = useState(DATE_OPTIONS[2]);
  //inventory status
  const [inventoryStatus, setInventoryStatus] = useState<IInventoryStatus>({
    healthy: 0,
    lowStock: 0,
    outOfStock: 0,
    all: 0,
  });
  const [inventoryStatusOption, setInventoryStatusOption] = useState(
    DASHBOARD_INVENTORY_OPTIONS[0]
  );
  //inventory Summary
  const [inventorySummary, setInventorySummary] = useState<IInventorySummary>({
    stockToPurchase: 0,
    lockedCapital: 0,
    stockOnHand: 0,
    onHandRetailValue: 0,
    forecastVariance: 0,
  });
  //cashflow summary
  const [cashflowSummary, setCashflowSummary] = useState<ICashflowSummary>({
    grossProfitMargin: 0,
    workingCapitalRatio: 0,
    operatinCashflow: 0,
    freeCashflow: 0,
    forecastVariance: 0,
  });
  const [cashflowSummaryTime, setCashflowSummaryTime] = useState(DATE_OPTIONS[2]);
  //top performers
  const [topPerformers, setTopPerformers] = useState<ITopPerformers[]>([
    {
      productId: "",
      name: "",
      value: 0,
      averageRevenue: 0,
      unitPrice: 0,
      unitCost: 0,
      SKUs: [],
    },
  ]);
  const [topPerfTime, setTopPerfTime] = useState(DATE_OPTIONS[2]);
  const [topPerfCriteria, setTopPerfCriteria] = useState(DASHBOARD_TOP_CRITERIA_OPTIONS[0]);
  const [topPerfOption, setTopPerfOption] = useState(DASHBOARD_INVENTORY_OPTIONS[2]);
  //bottom performers
  const [bottomPerformers, setBottomPerformers] = useState<IBottomPerformers[]>([
    {
      productId: "",
      name: "",
      value: 0,
      averageRevenue: 0,
      unitPrice: 0,
      unitCost: 0,
      SKUs: [],
    },
  ]);
  const [bottomPerfCriteria, setBottomPerfCriteria] = useState(
    DASHBOARD_BOTTOM_CRITERIA_OPTIONS[0]
  );
  const [bottomPerfOption, setBottomPerfOption] = useState(DASHBOARD_INVENTORY_OPTIONS[2]);
  //time series
  const [timeSeriesTime, setTimeSeriesTime] = useState(DATE_OPTIONS_FORECAST[1]);
  const [timeSeriesType, setTimeSeriesType] = useState(TIME_SERIES_OPTIONS[0]);
  const [timeSeriesFrequency, setTimeSeriesFrequency] = useState(DATE_FREQUENCY_TIME_SERIES[0]);

  useEffect(() => {
    if (!rawState) {
      return;
    }
    const parsed = JSON.parse(rawState);
    if (!parsed) {
      return;
    }
    processAndSetTime(parsed, "kpi_time", setKPITime, DATE_OPTIONS);
    processAndSetTime(parsed, "sales_summary_time", setSalesTime, DATE_OPTIONS);
    processAndSetTime(parsed, "cashflow_summary_time", setCashflowSummaryTime, DATE_OPTIONS);
    processAndSetTime(parsed, "abc_time", setAbcTime, DATE_OPTIONS);
    processAndSetTime(parsed, "top_perf_time", setTopPerfTime, DATE_OPTIONS);
    processAndSetTime(parsed, "dashboard_chart_time", setTimeSeriesTime, DATE_OPTIONS_FORECAST);
    processAndSet(parsed, "top_perf_org", setTopPerfOption);
    processAndSet(parsed, "top_perf_type", setTopPerfCriteria);
    processAndSet(parsed, "dashboard_chart_type", setTimeSeriesType);
    processAndSet(parsed, "dashboard_chart_frequency", setTimeSeriesFrequency);

    setStatesFetched(true);
  }, [rawState]);

  return (
    <DashboardContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        //KPI
        KPI,
        updateKPI: setKPI,
        KPITime,
        //abc
        updateAbcTime: setAbcTime,
        abcTime,
        updateKPITime: setKPITime,
        //sales summary
        salesSummary,
        updateSalesSummary: setSalesSummary,
        salesTime,
        updateSalesTime: setSalesTime,
        //inventory status
        inventoryStatus,
        updateInventoryStatus: setInventoryStatus,
        inventoryStatusOption,
        updateInventoryStatusOption: setInventoryStatusOption,
        //inventory Summary
        inventorySummary,
        updateInventorySummary: setInventorySummary,
        //cashflow summary
        cashflowSummary,
        updateCashflowSummary: setCashflowSummary,
        cashflowSummaryTime,
        updateCashflowSummaryTime: setCashflowSummaryTime,
        //top performers
        topPerformers,
        updateTopPerformers: setTopPerformers,
        topPerfTime,
        updateTopPerfTime: setTopPerfTime,
        topPerfCriteria,
        updateTopPerfCriteria: setTopPerfCriteria,
        topPerfOption,
        updateTopPerfOption: setTopPerfOption,
        //bottom performers
        bottomPerformers,
        updateBottomPerformers: setBottomPerformers,
        bottomPerfCriteria,
        updateBottomPerfCriteria: setBottomPerfCriteria,
        bottomPerfOption,
        updateBottomPerfOption: setBottomPerfOption,
        //time series
        timeSeriesTime,
        updatTimeSeriesTime: setTimeSeriesTime,
        timeSeriesType,
        updateTimeSeriesType: setTimeSeriesType,
        timeSeriesFrequency,
        updateTimeSeriesFrequency: setTimeSeriesFrequency,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
