import { ISBRoute } from "./SideBar";
import { NavLink, useLocation } from "react-router-dom";
import { SimplePillCallout, PillCallout } from "components/Pill";

const SideBarItem = (route: ISBRoute) => {
  const location = useLocation();
  let currentRoute = location.pathname;
  let isCurrentRoute = currentRoute === route.to;

  if (route.to === "/help" && isCurrentRoute) {
    localStorage.setItem("helpClicked", "true");
  }

  let isHelpClicked = !!localStorage.getItem("helpClicked");
  const baseStyle =
    "flex items-center py-2.5 rounded-xl font-medium transition-all border border-transparent hover:border-border-internal group";
  return (
    <li key={route.to} className={`${route.collapsed ? "" : "overflow-hidden"} mb-2 relative`}>
      <NavLink
        to={route.routeUnavailable ? "" : route.to}
        end
        className={({ isActive }) =>
          isActive
            ? `${baseStyle}
              ${route.collapsed ? "hover:w-fit" : ""}
              bg-highlight-gentle 
              text-base-text`
            : `${route.disabled ? "pointer-events-none text-base-light" : "text-base-navlight"} 
                ${baseStyle}
                    ${route.collapsed ? "hover:w-fit" : "hover:border-transparent"}
                    hover:bg-highlight-gentle 
                    hover:text-base-text 
                    text-base-navlight`
        }
      >
        <div className={"relative"}>
          {route.hasNotification && !isCurrentRoute && route.firstLogin && !isHelpClicked ? (
            <div className="bg-pink w-[6px] h-[6px] absolute right-1 rounded-full top-[-6px] animate-ping"></div>
          ) : null}
          <div className={`group-hover:text-base-text`}>{route.icon}</div>
        </div>
        <ItemContent text={route.name} subtext={route.subtext} collapsed={route.collapsed} />
        {route.new ? <PillCallout>new</PillCallout> : null}
        {/* <SimplePillCallout>{route.name}</SimplePillCallout> */}
      </NavLink>

      {/* <p className=" w-full flex justify-center items-center text-center text-[8px]">{route.name}</p> */}
    </li>
  );
};

interface ItemContentProps {
  text: string;
  subtext?: string;
  collapsed?: boolean;
}

const ItemContent = ({ text, subtext, collapsed }: ItemContentProps) => {
  if (collapsed)
    return (
      <div className="group-hover:visible invisible flex flex-col pr-2.5">
        <span className="whitespace-nowrap">{text}</span>
      </div>
    );
  else
    return (
      <div className="flex flex-col pr-2.5 overflow-hidden max-w-full">
        <span>{text}</span>
      </div>
    );
};

export default SideBarItem;
